import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import {AppContext} from "../../App";
import {fetchUserSystemsPage, putNewSystem} from "../../utils/apiCaller";
import AddButton from "../../components/AddButton";
import WizardStep, {WizardAdvStep, WizardEndStep, WizardIntroStep} from "../../components/wizard/WizardStep";
import WizardStepElement from "../../components/wizard/WizardStepElement";
import Tooltip from "@material-ui/core/Tooltip";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Wizard from "../../components/wizard/Wizard";
import AdvancedTable from "../../components/AdvancedTable";
import RatDropDown from "../../components/RatDropDown";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function SystemSelectionDialog({open, onClose, onSelected, creationOnly = false}) {

    const [state] = useContext(AppContext);


    const [newSystem, setNewSystem] = useState(creationOnly)
    const [backVisible, setBackVisible] = useState(false)

    const handleStepChange = (step) => {
        setBackVisible(step === -1)
    }

    const handleNewSystem = (payload) => {

        // procedo alla creazione
        putNewSystem(state.user.jwt, payload)
            .then(result => onSelected(result))
            .catch(result => alert(result))
    }

    const handleReset = () => {
        if (!creationOnly)
            setNewSystem(false)
    }

    return (
        <Dialog disableBackdropClick maxWidth={newSystem ? "sm" : "md"} open={open} onExited={handleReset}
                onClose={onClose}>
            <DialogTitle>
                {newSystem ?
                    <Grid container>
                        <Grid item>
                            <Box hidden={!backVisible || creationOnly} mr={2}>
                                <Button
                                    startIcon={<ArrowBackIcon/>}
                                    hidden={creationOnly}
                                    color="primary"
                                    onClick={() => setNewSystem(prevState => !prevState)}>
                                    Back
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item>
                            Create a new system
                        </Grid>
                    </Grid>
                    :
                    "Select a system"
                }
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>


            {newSystem ?
                <SystemCreation
                    creationOnly={creationOnly}
                    onStepChange={handleStepChange}
                    onSave={handleNewSystem}
                />
                :
                <SystemSelection
                    onNewSystem={() => setNewSystem(prevState => !prevState)}
                    onSelected={onSelected}/>
            }
        </Dialog>
    )
}

function SystemCreation({onSave, onStepChange}) {
    const [step, setStep] = useState(-1)

    const [name, setName] = useState("")
    const [nameError, setNameError] = useState({error: false, text: ""})
    const [installationTime, setInstallationTime] = useState(null)
    const [installationTimeError, setInstallationTimeError] = useState({error: false, text: ""})
    const [type, setType] = useState("")
    const [typeError, setTypeError] = useState({error: false, text: ""})
    const [replaceability, setReplaceability] = useState(-1)
    const [relevance, setRelevance] = useState(-1)
    const [maintainability, setMaintainability] = useState(-1)


    const [nextEnabled, setNextEnabled] = useState(false)
    const [backEnabled, setBackEnabled] = useState(false)
    const [saveEnabled, setSaveEnabled] = useState(false)

    const fieldsValidation = {
        name: () => {
            if (name === "") {
                setNameError({error: true, text: "Please, enter a system name"})
                return true
            } else
                setNameError({error: false, text: ""})
        },
        type: () => {
            if (type === "") {
                setTypeError({error: true, text: "Please, enter a system type"})
                return true
            } else
                setTypeError({error: false, text: ""})
        },
        installationTime: () => {
            if (installationTime == null) {
                setInstallationTimeError({error: true, text: "Please, enter a system installation time"})
                return true
            } else
                setInstallationTimeError({error: false, text: ""})
        }
    }

    const handleNext = () => {
        // controllo per abilitare o disabilitare il pulsante next

        let hasError = false

        switch (step) {
            case 0:
                hasError = fieldsValidation.name()
                break
            case 1:
                hasError = fieldsValidation.type()
                break
            case 2:
                hasError = fieldsValidation.installationTime()
                break
            default:
        }

        if (!hasError)
            setStep(step + 1);


    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = () => {

        // mi assicuro che tutto sia popolato come si deve (nel caso della versione avanzata)
        if (step === -2) {
            // mi assicuro che nessuno di essi ritorni true
            const nameCheck = !!fieldsValidation.name()
            const typeCheck = !!fieldsValidation.type()
            const instCheck = !!fieldsValidation.installationTime()

            if (nameCheck ||
                typeCheck ||
                instCheck
            )
                return

        }

        // costruisco un oggetto rappresentante il failure
        let payload = {
            name: name,
            replaceability: replaceability,
            relevance: relevance,
            maintainability: maintainability,
            type: type
        }

        if (installationTime !== null)
            payload.installationTime = installationTime.getTime() / 1000


        onSave(payload)
        // svuoto tutti i campi
        handleClose()
    }


    const handleClose = () => {
        setType("")
        setName("")
        setInstallationTime(null)
        setRelevance(0)
        setMaintainability(0)
        setReplaceability(0)
        setStep(-1)
    }

    useEffect(() => {

        switch (step) {
            case -2:
                setBackEnabled(false)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            case -1:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(false)
                break
            case 0:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(true)
                break
            case 6:
                setBackEnabled(true)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            default:
                setBackEnabled(true)
                setSaveEnabled(false)
                setNextEnabled(true)

        }
        onStepChange(step)

    }, [step, onStepChange])

    return (
        <DialogContent>
            <Wizard
                backEnabled={backEnabled}
                onBack={handleBack}
                saveEnabled={saveEnabled}
                onSave={handleSave}
                nextEnabled={nextEnabled}
                onNext={handleNext}
                onStepChange={setStep}
                step={step}
            >
                <WizardAdvStep>
                    <WizardStepElement>
                        <TextField
                            error={typeError.error}
                            helperText={typeError.text}
                            placeholder={"Name"} variant={"outlined"} fullWidth label={""}
                            value={name} onChange={e => setName(e.target.value)}/>
                    </WizardStepElement>
                    <WizardStepElement>
                        <TextField
                            error={typeError.error}
                            helperText={typeError.text}
                            placeholder={"Type"} variant={"outlined"} fullWidth label={""}
                            value={type} onChange={e => setType(e.target.value)}/>
                    </WizardStepElement>
                    <WizardStepElement>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                disableFuture
                                inputVariant="outlined"
                                ampm={false}
                                error={installationTimeError.error}
                                placeholder={"Installation time"}
                                helperText={installationTimeError.text}
                                value={installationTime}
                                onChange={setInstallationTime}
                                fullWidth format="dd/MM/yyyy HH:mm:ss"
                            />
                        </MuiPickersUtilsProvider>
                    </WizardStepElement>
                    <WizardStepElement>
                        <RatDropDown
                            values={["Easily replaceable/redundant",
                                "Replaceable with a small expenditure of resources",
                                "Replaceable with a high expenditure of resources",
                                "Irreplaceable"]}
                            onChange={(e, value) => setReplaceability(value)}
                            value={replaceability}
                            label={"Replaceability"}
                        />
                    </WizardStepElement>
                    <WizardStepElement>
                        <RatDropDown
                            onChange={(e, value) => setRelevance(value)}
                            value={relevance}
                            values={["Irrelevant", "Not essential", "Relevant", "Fundamental"]}
                            label={"Relevance"}
                        />
                    </WizardStepElement>
                    <WizardStepElement>
                        <RatDropDown
                            onChange={(e, value) => setMaintainability(value)}
                            value={maintainability}
                            label={"Maintainability"}
                            values={["Excellent maintainability (not relevant costs and MTTR)",
                                "High maintainability (not relevant costs or not relevant MTTR)",
                                "Medium maintainability (high costs or high MTTR)",
                                "Low maintainability (high costs and high MTTR)"]}
                        />
                    </WizardStepElement>
                </WizardAdvStep>
                <WizardIntroStep justify="space-between">
                    <Box mb={2}>
                        <WizardStepElement>
                            Follow the guided procedure to get specific instructions supporting you in adding a new
                            system.
                            If you are an experienced user, guided procedure can be skipped.
                        </WizardStepElement>
                    </Box>
                    <Grid item>
                        <Tooltip placement={"right"} title="A more advanced approach with less help">
                            <Button color={"primary"} onClick={() => setStep(-2)} variant={"outlined"}>
                                skip it
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip placement={"right"}
                                 title="An intuitive step by step guide to the creation">
                            <Button onClick={() => setStep(0)} color={"primary"} variant={"contained"}>
                                Guided procedure
                            </Button>
                        </Tooltip>
                    </Grid>

                </WizardIntroStep>
                <WizardStep>
                    <WizardStepElement>
                        Which is the name of the system?
                    </WizardStepElement>
                    <WizardStepElement>
                        <TextField
                            error={nameError.error}
                            helperText={nameError.text}
                            placeholder={"System name"} variant={"outlined"} fullWidth label={""}
                            value={name} onChange={e => setName(e.target.value)}/>
                    </WizardStepElement>
                </WizardStep>
                <WizardStep>
                    <WizardStepElement>
                        Which type of system is <Typography component={"span"} color={"primary"}>{name}</Typography>? (e.g. CNC machine, HVAC system)
                    </WizardStepElement>
                    <WizardStepElement>
                        <Autocomplete
                            options={['CNC','Pump','Press', 'HVAC']} value={type}
                            onInputChange={(event, t) => setType(t)}
                            freeSolo
                            renderInput={(params) =>
                                <TextField {...params} fullWidth
                                           variant={"outlined"}
                                           error={typeError.error}
                                           helperText={typeError.text}
                                />
                            }
                        />
                        {/*<TextField*/}
                        {/*    error={typeError.error}*/}
                        {/*    helperText={typeError.text}*/}
                        {/*    placeholder={"Type"} variant={"outlined"} fullWidth label={""}*/}
                        {/*    value={type} onChange={e => setType(e.target.value)}/>*/}
                    </WizardStepElement>
                </WizardStep>
                <WizardStep>
                    <WizardStepElement>
                        When this system has been installed for the first time in a production system
                    </WizardStepElement>
                    <WizardStepElement>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                disableFuture
                                inputVariant="outlined"
                                ampm={false}
                                error={installationTimeError.error}
                                placeholder={"Installation time"}
                                helperText={installationTimeError.text}
                                value={installationTime}
                                onChange={setInstallationTime}
                                fullWidth format="dd/MM/yyyy HH:mm:ss"
                            />
                        </MuiPickersUtilsProvider>
                    </WizardStepElement>
                </WizardStep>
                <WizardStep>
                    <WizardStepElement>
                        Which is the replaceability index of this system?
                    </WizardStepElement>
                    <WizardStepElement>
                        <RatDropDown
                            values={["Easily replaceable/redundant",
                                "Replaceable with a small expenditure of resources",
                                "Replaceable with a high expenditure of resources",
                                "Irreplaceable"]}
                            onChange={(e, value) => setReplaceability(value)}
                            value={replaceability}
                        />
                    </WizardStepElement>
                </WizardStep>
                <WizardStep>
                    <WizardStepElement>
                        Which is the relevance index of this system?
                    </WizardStepElement>
                    <WizardStepElement>
                        <RatDropDown
                            values={["Irrelevant", "Not essential", "Relevant", "Fundamental"]}
                            onChange={(e, value) => setRelevance(value)}
                            value={relevance}
                        />
                    </WizardStepElement>
                </WizardStep>
                <WizardStep>
                    <WizardStepElement>
                        Which is the maintainability index of this system?
                    </WizardStepElement>
                    <WizardStepElement>
                        <RatDropDown
                            values={["Excellent maintainability (not relevant costs and MTTR)",
                                "High maintainability (not relevant costs or not relevant MTTR)",
                                "Medium maintainability (high costs or high MTTR)",
                                "Low maintainability (high costs and high MTTR)"]}
                            onChange={(e, value) => setMaintainability(value)}
                            value={maintainability}
                        />
                    </WizardStepElement>
                </WizardStep>
                <WizardEndStep>
                    Please verify that information provided are correct and click on Save to create the new system.
                </WizardEndStep>
            </Wizard>
        </DialogContent>
    )
}

function SystemSelection({onSelected, onNewSystem}) {
    const [state] = useContext(AppContext);

    const [filterName, setFilterName] = useState("");

    const handleFilterChange = (value) => {
        setFilterName(value)
    }


    const tableFields = [
        {key: "name", name: "Name"},
        {key: "type", name: "Type"},
    ]

    return (
        <DialogContent style={{paddingRight: 0, paddingLeft: 0}}>
            <Grid container>
                <Grid item xs={12}
                      style={{paddingRight: 20, paddingLeft: 20}}>
                    <TextField
                        onChange={(e) => handleFilterChange(e.target.value)}
                        size={"small"}
                        label="Search system"
                        variant={"outlined"}
                        fullWidth
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: 20}}>
                    <AddButton
                        style={{
                            position: "absolute",
                            zIndex: 100,
                            bottom: 12, left: 16,

                        }}
                        label={"System"} onClick={() => onNewSystem()}/>
                    <AdvancedTable
                        jwt={state.user.jwt}
                        filterName={filterName}
                        fetchingPageFunction={fetchUserSystemsPage}
                        onSelected={onSelected}
                        fields={tableFields}
                    />
                </Grid>
            </Grid>
        </DialogContent>
    )
}