import React, {useContext, useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import {useHistory, useParams} from "react-router-dom";
import {
    fetchAllSystemComponents,
    fetchAllUserProcesses,
    fetchAllUserSystems,
    fetchFailureModeDetail,
    postFailureModeUpdate
} from "../../utils/apiCaller";
import {AppContext} from "../../App";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailTitle from "../../components/DetailTitle";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Typography} from "@material-ui/core";
import GridItemColumn from "../../components/GridItemColumn";
import RatDropDown from "../../components/RatDropDown";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SaveIcon from "@material-ui/icons/Save";


export default function FailureModeDetailScreen() {


    const [state] = useContext(AppContext);
    const [snackOpen, setSnackOpen] = useState(false);
    const [failureMode, setFailureMode] = useState({loading: true});

    const [processList, setProcessList] = useState([]);
    const [componentList, setComponentList] = useState([]);
    const [systemList, setSystemList] = useState([]);

    // campi per la form sotto
    const [name, setName] = useState("");
    const [causeType, setCauseType] = useState("");

    const [process, setProcess] = useState(null);
    const [component, setComponent] = useState(null);
    const [system, setSystem] = useState(null);

    const [severity, setSeverity] = useState(0);
    const [occurrence, setOccurrence] = useState(0);
    const [detectability, setDetectability] = useState(0);
    const [riskPriorityNumber, setRiskPriorityNumber] = useState(null);
    const [criticalNumber, setCriticalNumber] = useState(null);
    const [sod, setSod] = useState(null);

    const [severityRationale, setSeverityRationale] = useState("");
    const [occurrenceRationale, setOccurrenceRationale] = useState("");
    const [detectabilityRationale, setDetectabilityRationale] = useState("");

    const [potentialCauses, setPotentialCauses] = useState("");
    const [currentControls, setCurrentControls] = useState("");
    const [mitigationActions, setMitigationActions] = useState("");
    const [shortTermActions, setShortTermActions] = useState("");
    const [failureEffect, setFailureEffect] = useState("");


    const {modeId} = useParams();
    const history = useHistory();

    useEffect(() => {
        fetchAllUserSystems(state.user.jwt)
            .then(result => {
                setSystemList(result)
                fetchAllUserProcesses(state.user.jwt)
                    .then(result => {
                        setProcessList(result)
                        // carico tutti il dettaglio
                        fetchFailureModeDetail(state.user.jwt, modeId)
                            .then(detail => setFailureMode(detail))
                            .catch(error => alert(error))
                    })
                    .catch(alert)

            })
            .catch(alert)

    }, [state.user.jwt, modeId])

    useEffect(() => {
        if (!failureMode.loading) {
            setName(failureMode.name)
            setSeverity(failureMode.severity)
            setOccurrence(failureMode.occurrence)
            setDetectability(failureMode.detectability)
            setRiskPriorityNumber(failureMode.riskPriorityNumber)
            setCriticalNumber(failureMode.criticalNumber)
            setSod(failureMode.severityOccurrenceDetectability)
            setPotentialCauses(failureMode.potentialCauses)
            setCurrentControls(failureMode.currentControls)
            setMitigationActions(failureMode.mitigationActions)
            setFailureEffect(failureMode.failureEffect)
            setCauseType(failureMode.causeType)
            setSeverityRationale(failureMode.severityRationale)
            setOccurrenceRationale(failureMode.occurrenceRationale)
            setDetectabilityRationale(failureMode.detectabilityRationale)
            setShortTermActions(failureMode.shortTermActions)
            let comp = {name: failureMode.componentName, id: failureMode.componentId}
            setComponent(comp)
            setComponentList([comp])
            setSystem(systemList.find(o => o.id === failureMode.systemId) || null)
            setProcess(processList.find(o => o.id === failureMode.processId) || null)
        }
    }, [failureMode, systemList, processList])

    const handleSave = (category) => {

        let payload

        switch (category) {
            case 0:
                payload = {
                    id: modeId,
                    name: name,
                    processId: process.id
                }
                break
            case 1:
                payload = {
                    id: modeId,
                    severity: severity,
                    occurrence: occurrence,
                    detectability: detectability,
                    severityRationale: severityRationale,
                    occurrenceRationale: occurrenceRationale,
                    detectabilityRationale: detectabilityRationale,
                }
                break
            case 2:
                payload = {
                    id: modeId,
                    potentialCauses: potentialCauses,
                    currentControls: currentControls,
                    failureEffect: failureEffect,
                    causeType: causeType,
                    processId: process.id
                }
                if (!!component && causeType === "SYSTEM")
                    payload.componentId = component.id
                else
                    payload.componentId = -1
                break
            default:
            case 3:
                payload = {
                    id: modeId,
                    mitigationActions: mitigationActions,
                    shortTermActions: shortTermActions,
                }
        }

        postFailureModeUpdate(state.user.jwt, payload)
            .then((failure) => {
                setRiskPriorityNumber(failure.riskPriorityNumber)
                setSod(failure.severityOccurrenceDetectability)
                setCriticalNumber(failure.criticalNumber)
                setSnackOpen(true)
            })
            .catch(alert)
    }

    const componentSelection = causeType === "SYSTEM" ? <>
        <GridItemColumn label={"System"}>
            <Autocomplete
                options={systemList} value={system}
                onChange={(event, t) => {
                    setSystem(t)
                    setComponent(null)
                    if (!!t) {
                        fetchAllSystemComponents(state.user.jwt, t.id)
                            .then(setComponentList)
                    } else
                        setComponentList([])
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} fullWidth variant="standard"/>}
            />
        </GridItemColumn>
        <GridItemColumn spacing label={"Component"}>
            <Autocomplete
                options={componentList} value={component}
                onChange={(event, t) => setComponent(t)}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} fullWidth variant="standard"/>}
            />
        </GridItemColumn>
    </> : null

    return (
        <Box p={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={() => history.push("/fmea")}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant={"button"} color={"primary"}>
                                FMEA / <u>failure mode</u>
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} container>
                    <Grid item container xs={4} justify={"space-around"}>
                        <Grid item>
                            <Typography variant={"h5"} color={"primary"}>
                                Risk Priority Number
                            </Typography>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item>
                            <Typography variant={"h5"} color={"primary"}>
                                <b>{riskPriorityNumber}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4} justify={"space-around"}>
                        <Grid item>
                            <Typography variant={"h5"} color={"primary"}>
                                Severity x Occurence x Detectability
                            </Typography>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item>
                            <Typography variant={"h5"} color={"primary"}>
                                <b>{sod}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4} justify={"space-around"}>
                        <Grid item>
                            <Typography variant={"h5"} color={"primary"}>
                                Critical Number
                            </Typography>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item>
                            <Typography variant={"h5"} color={"primary"}>
                                <b>{criticalNumber}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/chapter/3-failure-mode-effect-analysis-fmea"}
                        tooltip={"Open help page in new tab"}>
                        General information
                    </DetailTitle>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <GridItemColumn label={"Name"}>
                            <TextField value={name || ""}
                                       onChange={event => setName(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn spacing label={"Process"}>
                            <Autocomplete
                                disableClearable
                                options={processList} value={process}
                                onChange={(event, t) => setProcess(t)}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} fullWidth variant="standard"/>}
                            />
                        </GridItemColumn>
                        <GridItemColumn/>
                        <GridItemColumn spacing align={"right"}>
                            <Button onClick={() => handleSave(0)} color={"primary"}
                                    variant={"contained"} startIcon={<SaveIcon/>}>
                                Save
                            </Button>
                        </GridItemColumn>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle>
                        Scale parameters
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <GridItemColumn label={"Severity"}>
                            <RatDropDown variant={"standard"}
                                         value={severity}
                                         values={['Tolerable', 'Low', 'Medium', 'Relevant', 'High']}
                                         onChange={(e, value) => setSeverity(value)}/>
                        </GridItemColumn>
                        <GridItemColumn spacing label={"Severity rationale"}>
                            <TextField value={severityRationale || ""}
                                       onChange={event => setSeverityRationale(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn label={"Occurrence"}>
                            <RatDropDown variant={"standard"}
                                         value={occurrence}
                                         values={['Improbable', 'Remote', 'Occasional', 'Probable', 'Frequent']}
                                         onChange={(e, value) => setOccurrence(value)}/>
                        </GridItemColumn>
                        <GridItemColumn spacing label={"Occurrence rationale"}>
                            <TextField value={occurrenceRationale || ""}
                                       onChange={event => setOccurrenceRationale(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn label={"Detectability"}>
                            <RatDropDown variant={"standard"}
                                         value={detectability}
                                         values={['Almost certain', 'High', 'Moderate', 'Low', 'Improbable']}
                                         onChange={(e, value) => setDetectability(value)}/>
                        </GridItemColumn>
                        <GridItemColumn spacing label={"Detectability rationale"}>
                            <TextField value={detectabilityRationale || ""}
                                       onChange={event => setDetectabilityRationale(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn/>
                        <GridItemColumn spacing align={"right"}>
                            <Button onClick={() => handleSave(1)} color={"primary"}
                                    variant={"contained"} startIcon={<SaveIcon/>}>
                                Save
                            </Button>
                        </GridItemColumn>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle>
                        Failure
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <GridItemColumn label={"Cause type"}>
                            <FormControl
                                fullWidth variant="standard">
                                <Select
                                    aria-describedby="causeHelper"
                                    fullWidth
                                    placeholder={"Cause type"}
                                    value={causeType || " "}
                                    onChange={e => setCauseType(e.target.value)}
                                >
                                    <MenuItem value={" "}>
                                        <em>Not defined</em>
                                    </MenuItem>
                                    <MenuItem value={"OTHER"}>
                                        Other
                                    </MenuItem>
                                    <MenuItem value={"MANPOWER"}>
                                        Manpower
                                    </MenuItem>
                                    <MenuItem value={"METHOD"}>
                                        Method
                                    </MenuItem>
                                    <MenuItem value={"MATERIAL"}>
                                        Material
                                    </MenuItem>
                                    <MenuItem value={"SYSTEM"}>
                                        System, equipment or machine
                                    </MenuItem>
                                    <MenuItem value={"MEASUREMENT"}>
                                        Measurement
                                    </MenuItem>
                                    <MenuItem value={"ENVIRONMENT"}>
                                        Environment
                                    </MenuItem>

                                </Select>
                            </FormControl>
                        </GridItemColumn>
                        <GridItemColumn spacing label={"Current controls"}>
                            <TextField value={currentControls || ""}
                                       onChange={event => setCurrentControls(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn label={"Failure effect"}>
                            <TextField value={failureEffect || ""}
                                       onChange={event => setFailureEffect(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn spacing label={"Potential causes"}>
                            <TextField value={potentialCauses || ""}
                                       onChange={event => setPotentialCauses(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        {componentSelection}
                        <GridItemColumn/>
                        <GridItemColumn spacing align={"right"}>
                            <Button onClick={() => handleSave(2)} color={"primary"}
                                    variant={"contained"} startIcon={<SaveIcon/>}>
                                Save
                            </Button>
                        </GridItemColumn>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <DetailTitle>
                        Actions
                    </DetailTitle>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <GridItemColumn label={"Short-term actions"}>
                            <TextField value={shortTermActions || ""}
                                       onChange={event => setShortTermActions(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn spacing label={"Mitigation actions"}>
                            <TextField value={mitigationActions || ""}
                                       onChange={event => setMitigationActions(event.target.value)}
                                       fullWidth multiline label=""/>
                        </GridItemColumn>
                        <GridItemColumn/>
                        <GridItemColumn spacing align={"right"}>
                            <Button onClick={() => handleSave(3)} color={"primary"}
                                    variant={"contained"} startIcon={<SaveIcon/>}>
                                Save
                            </Button>
                        </GridItemColumn>
                    </Grid>
                </Grid>

            </Grid>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </Box>

    )
}
