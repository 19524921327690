import React, {createContext, useEffect, useReducer, useRef, useState} from 'react';
import ScreenRouter from "./screens/ScreensRouter";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {loadState, saveState} from "./utils/localStorage";
import Grid from "@material-ui/core/Grid";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from "@material-ui/core/IconButton";

require('dotenv').config();

const appTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#fecc00',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        success: {
            // light: will be calculated from palette.primary.main,
            main: '#6afe00',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        appBar: '#333333'
    },
});

export const AppContext = createContext(undefined);

const ribbonStyle = {
    width: "200px",
    padding: "8px",
    // position: "absolute",
    textAlign: "center",
    color: "#f0f0f0",
    top: "25px",
    left: "-50px",
    transform: "rotate(-45deg)",
    position: "fixed",
    fontSize: "20px",
    zIndex: 10000
}

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return Object.assign({}, state, {
                user: action.payload
            });
        case "LOGOUT":
            return Object.assign({}, state, {
                user: null,
                system: null
            });
        case "SHOW_ALERT":
            return Object.assign({}, state, {
                alert: {
                    message: action.payload.message || "",
                    severity: action.payload.severity || "success",
                    autoHideDuration: action.payload.autoHideDuration === undefined ? 3000 : action.payload.autoHideDuration,
                    display: true
                }
            });
        case "HIDE_ALERT":
            return Object.assign({}, state, {
                alert: {
                    display: false,
                    severity: state.alert.severity,
                    message: state.alert.message,
                    autoHideDuration: state.alert.autoHideDuration,
                }
            });
        case "SYSTEM_CHOSEN":
            return Object.assign({}, state, {
                system: action.payload
            });
        default:
            throw new Error();
    }
};


function App() {
    const [state, dispatch] = useReducer(reducer, loadState(), undefined);

    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }


        saveState(state);
    }, [state, state.user, state.system])

    const [dev, setDev] = useState(window._env_.DEV === "true")
    const [loc, setLocal] = useState(window._env_.LOCAL === "true")

    const handleRibbonHide = () =>{
        if (window.confirm("Are you sure? This remains a dev environment even if you remove the ribbon :)")) {
            setDev(false)
            setLocal(false)
        }
    }

    return (
        <AppContext.Provider value={[state, dispatch]}>
            <ThemeProvider theme={appTheme}>
                <CssBaseline/>

                {dev && !loc &&
                <div style={{backgroundColor: "#e43" ,...ribbonStyle}}>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">
                        <Grid item>
                            <IconButton size={"small"} component="span" onClick={handleRibbonHide}>
                                <VisibilityOffIcon style={{transform: "rotate(45deg)"}} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            DEV
                        </Grid>
                    </Grid>
                </div>}

                {loc &&
                <div style={{backgroundColor: "#337eee" ,...ribbonStyle}}>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center">
                        <Grid item>
                            <IconButton size={"small"} component="span" onClick={handleRibbonHide}>
                                <VisibilityOffIcon style={{transform: "rotate(45deg)"}} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            LOCAL
                        </Grid>
                    </Grid>
                </div>}

                <BrowserRouter>
                    <ScreenRouter/>
                </BrowserRouter>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
