import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {putNewUser} from "../../utils/apiCaller";
import {AppContext} from "../../App";
import {checkToken} from "../../utils/utils";
import {NavLink, Redirect, useHistory} from "react-router-dom";
import Wizard from "../../components/wizard/Wizard";
import WizardStep, {WizardEndStep} from "../../components/wizard/WizardStep";
import WizardStepElement from "../../components/wizard/WizardStepElement";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";


const validateEmail = (mail) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}
const validatePassword = (password) => {
    return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(password);
}



export default function RegisterScreen() {
    const [state] = useContext(AppContext);
    const history = useHistory();

    const [step, setStep] = useState(0);

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState({error: false, text: ""});
    const [surname, setSurname] = useState("");
    const [surnameError, setSurnameError] = useState({error: false, text: ""});
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState({error: false, text: ""});
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState({error: false, text: ""});
    const [rePassword, setRePassword] = useState("");
    const [rePasswordError, setRePasswordError] = useState({error: false, text: ""});

    const [nextEnabled, setNextEnabled] = useState(false);
    const [backEnabled, setBackEnabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const handleNext = () => {
        // controllo per abilitare o disabilitare il pulsante next

        let hasError = false

        switch (step) {
            case 0:
                if (name === "") {
                    hasError = true
                    setNameError({error: true, text: "Please, enter your name"})
                } else
                    setNameError({error: false, text: ""})

                if (surname === "") {
                    hasError = true
                    setSurnameError({error: true, text: "Please, provide your surname"})
                } else
                    setSurnameError({error: false, text: ""})

                break
            case 1:
                if (email === "") {
                    hasError = true
                    setEmailError({error: true, text: "Please, enter your email"})
                } else
                    setEmailError({error: false, text: ""})

                if(!validateEmail(email)){
                    hasError = true
                    setEmailError({error: true, text: "Provide a valid email (name@domain.tld)"})
                } else
                    setEmailError({error: false, text: ""})

                break

            case 2:
            default:
                if (password === "") {
                    hasError = true
                    setPasswordError({error: true, text: "Please, select a password"})
                } else
                    setPasswordError({error: false, text: ""})

                if (!validatePassword(password)) {
                    hasError = true
                    setPasswordError({error: true, text: "The provided password does not satisfy the requirements"})
                } else
                    setPasswordError({error: false, text: ""})

                if (password !== rePassword) {
                    hasError = true
                    setRePasswordError({error: true, text: "The two password do not match"})
                } else
                    setRePasswordError({error: false, text: ""})

        }

        if (!hasError)
            setStep(step + 1);


    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = () => {

        // costruisco un oggetto rappresentante il failure
        let payload = {
            name: name,
            surname: surname,
            email: email,
            password: password
        }

        putNewUser(payload)
            .then((user) =>{history.push("/login?user=" + encodeURI(user.email))})
            .catch(alert)


    }


    useEffect(() => {

        switch (step) {
            case 0:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(true)
                break
            case 3:
                setBackEnabled(true)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            default:
                setBackEnabled(true)
                setSaveEnabled(false)
                setNextEnabled(true)


        }

    }, [step])


    // se autenticato allora rimando alla home
    if (state.user && checkToken(state.user.jwt))
        return <Redirect to={"/"}/>

    return (
        <Grid
            style={{
                position: "absolute",
                top: "0",
                bottom: "0"
            }}
            container
            justify="center"
            alignItems="center"
            spacing={0}
        >
            <Grid item xs={4}>
                <Paper>
                    <Box p={3}>
                        <Wizard
                            backEnabled={backEnabled}
                            onBack={handleBack}
                            saveEnabled={saveEnabled}
                            onSave={handleSave}
                            nextEnabled={nextEnabled}
                            onNext={handleNext}
                            onStepChange={setStep}
                            step={step}
                            saveLabel="register"
                            saveIcon={<ExitToAppIcon/>}
                        >
                            <WizardStep>
                                <WizardStepElement>
                                    Fill the following fields with your name and surname
                                </WizardStepElement>
                                <WizardStepElement>
                                    <TextField
                                        error={nameError.error}
                                        helperText={nameError.text}
                                        placeholder={"Name"} variant={"outlined"} fullWidth label={""}
                                        value={name} onChange={e => setName(e.target.value)}/>
                                </WizardStepElement>
                                <WizardStepElement>
                                    <TextField
                                        error={surnameError.error}
                                        helperText={surnameError.text}
                                        placeholder={"Surname"} variant={"outlined"} fullWidth label={""}
                                        value={surname} onChange={e => setSurname(e.target.value)}/>
                                </WizardStepElement>
                            </WizardStep>
                            <WizardStep>
                                <WizardStepElement>
                                    Please, fill the following field with your email.
                                    The email will be used to access the platform.
                                    Your private address will never be disclosed to third parties and you will never
                                    receive
                                    any commercial email.
                                </WizardStepElement>
                                <WizardStepElement>
                                    <TextField
                                        error={emailError.error}
                                        helperText={emailError.text}
                                        placeholder={"Email"} variant={"outlined"} fullWidth label={""}
                                        value={email} onChange={e => setEmail(e.target.value)}/>
                                </WizardStepElement>
                            </WizardStep>
                            <WizardStep>
                                <WizardStepElement>
                                    Almost done! <br/>
                                    Choose a secure password password between 7 to 15 characters
                                    which contain at least one numeric digit and a special character
                                </WizardStepElement>
                                <WizardStepElement>
                                    <TextField
                                        type={"password"}
                                        error={passwordError.error}
                                        helperText={passwordError.text}
                                        placeholder={"Password"} variant={"outlined"} fullWidth label={""}
                                        value={password} onChange={e => setPassword(e.target.value)}/>
                                </WizardStepElement>
                                <WizardStepElement>
                                    <TextField
                                        type={"password"}
                                        error={rePasswordError.error}
                                        helperText={rePasswordError.text}
                                        placeholder={"Retype password"} variant={"outlined"} fullWidth label={""}
                                        value={rePassword} onChange={e => setRePassword(e.target.value)}/>
                                </WizardStepElement>
                            </WizardStep>
                            <WizardEndStep>
                                By clicking "register" you accept our ....

                            </WizardEndStep>
                        </Wizard>
                    </Box>

                </Paper>
                <Grid container justify={"space-between"}>
                    <Grid item >
                        <Typography variant="body1" color={"primary"}>
                            SUPSI - Reliability Analysis Tool
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Link to={"/login"} component={NavLink}>
                            Back to login
                        </Link>
                    </Grid>

                </Grid>

            </Grid>
        </Grid>

    );
}
