import React from 'react';
import {Route, Switch} from "react-router-dom";
import FailureModeDetailScreen from "./FailureModeDetailScreen";
import FmeaScreen from "./FmeaScreen";


export default function ProcessRouter() {

    return (
        <Switch>
            <Route exact path="/fmea" component={FmeaScreen}/>
            <Route exact path="/fmea/:modeId" component={FailureModeDetailScreen}/>
        </Switch>
    );
}
