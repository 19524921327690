// methodo per generalizzare una richiesta https
const performCall = (path, headers, method, body = null, propertyCheck) => {

    if (body) {
        body = JSON.stringify(body)
    }

    // ritorno una promise
    return new Promise((resolve, reject) => {
        let server = "https://" + window._env_.DRY_ICE_URL

        console.log(method+' @'+server+path)
        console.log(body)

        fetch(server + path, {
            headers: headers,
            body: body,
            method: method
        })
            .then(response => {
                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400)
                        // converto il risultato in oggetto js (json)
                        response.json().then(json => {
                            resolve(json)
                        })
                    // altrimenti passo da qui e ritorno errore
                    else
                        response.json()
                            .then(json => {
                                console.log(json)
                                reject(json)
                            })
                            .catch(() => reject({message: "error unknown"}))
                }
            )
    })
}

export const postData = (dryIceKey, data) => {

    /*`/dryice/data/${dryIceKey}`*/
    return performCall(
        `/rat/output`,
        {
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}