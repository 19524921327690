import * as React from 'react';

import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {ComponentNode} from "./ComponentNode";
import ComponentNodeWidget from "./ComponentNodeWidget";

export class ComponentNodeFactory extends AbstractReactFactory {
	constructor() {
		super('component-node');
	}

	generateModel(event) {
		return new ComponentNode();
	}

	generateReactWidget(event) {
		// eslint-disable-next-line react/jsx-no-undef
		return <ComponentNodeWidget engine={this.engine} node={event.model} />;
	}
}
