import React from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";


export default function RatDropDown({
                                        label,
                                        variant = "outlined",
                                        values = ["Very low", "Low", "Medium", "High", "Very high"],
                                        onChange,
                                        value,
                                        ...props
                                    }) {

    if (!value)
        value = -1

    let labelComponent = label? <InputLabel
        // id={`label-${label}`}
    >{label}</InputLabel> : null

    return (
        <FormControl fullWidth variant={variant} {...props}>
            {labelComponent}
            <Select
                labelId={label ? `label-${label}` : undefined}
                label={label}
                fullWidth
                placeholder={label}
                value={value}
                onChange={(event => onChange(event, event.target.value))}
            >
                <MenuItem value={-1}>
                    <em>Not defined</em>
                </MenuItem>
                {values.map((value) =>
                    <MenuItem key={values.indexOf(value)} value={values.indexOf(value) + 1}>{values.indexOf(value)+ 1}) {value}</MenuItem>
                )}}
            </Select>
        </FormControl>

        // <Grid container>
        //     <Grid item xs={3} style={{textAlign:"right"}}>
        //         <Box mt={1}>
        //             <Typography variant={"body1"}>
        //                 {label}
        //             </Typography>
        //         </Box>
        //     </Grid>
        //     <Grid item xs={9}>
        //         <Box mx={4}>
        //         <Slider
        //             {...props}
        //             onChange={onChange}
        //             step={1} min={0} max={2} marks={marks}
        //             value={value}
        //             valueLabelDisplay="auto"
        //         />
        //         </Box>
        //     </Grid>
        // </Grid>


    );
}
