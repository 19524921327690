import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Wizard from "../../components/wizard/Wizard";
import WizardStep, {WizardAdvStep, WizardEndStep, WizardIntroStep} from "../../components/wizard/WizardStep";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import WizardStepElement from "../../components/wizard/WizardStepElement";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

export default function DistributionCreationDialog({
                                                  open,
                                                  onClose,
                                                  onSave
                                              }) {

    const [step, setStep] = useState(-1);

    const [name, setName] = useState({error: false, text: "", value: ""})
    const [url, setUrl] = useState({error: false, text: "", value: ""})

    const [nextEnabled, setNextEnabled] = useState(false);
    const [backEnabled, setBackEnabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);


    const fieldsValidation = {
        name: () => {
            if (name.value === "") {
                setName({error: true, text: "Please, enter a name", value: name.value})
                return true
            } else
                setName({error: false, text: "", value: name.value})
        },
        url: () => {
            if (url.value === "") {
                setUrl({error: true, text: "Please, enter a service url", value: url.value})
                return true
            } else
                setUrl({error: false, text: "", value: url.value})
        }
    }

    const handleNext = () => {
        // controllo per abilitare o disabilitare il pulsante next

        let hasError

        switch (step) {
            case 0:
                hasError = !!fieldsValidation.name()
                break
            default:
                hasError = !!fieldsValidation.url()

        }

        if (!hasError)
            setStep(step + 1);

    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = () => {

        // mi assicuro che tutto sia popolato come si deve (nel caso della versione avanzata)
        if (step === -2) {
            // mi assicuro che nessuno di essi ritorni true
            const nameCheck = !!fieldsValidation.name()
            const urlCheck = !!fieldsValidation.url()

            if (nameCheck ||
                urlCheck
            )
                return

        }

        // costruisco un oggetto rappresentante l'oggetto
        let payload = {
            name: name.value,
            serviceUrl: url.value,
            disabled: false
        }

        onSave(payload)
    }

    const handleClose = () => {
        setName({error: false, text: "", value: ""})
        setUrl({error: false, text: "", value: ""})
        setStep(-1)
    }

    useEffect(() => {

        switch (step) {
            case -2:
                setBackEnabled(false)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            case -1:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(false)
                break
            case 0:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(true)
                break
            case 2:
                setBackEnabled(true)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            default:
                setBackEnabled(true)
                setSaveEnabled(false)
                setNextEnabled(true)


        }

    }, [step])

    const input = {
        name:
            <TextField error={name.error} helperText={name.text} placeholder={"Service name"}
                       variant={"outlined"} fullWidth label={""} value={name.value}
                       onChange={e => {
                           let val = e.target.value
                           setName({error: name.error, text: name.text, value: val})
                       }}
            />,
        url:
            <TextField
                error={url.error} helperText={url.text} placeholder={"Service url"}
                variant={"outlined"} fullWidth label={""} value={url.value}
                onChange={e => {
                    let val = e.target.value
                    setUrl({error: url.error, text: url.text, value: val})
                }}
            />
    }

    return (
        <Dialog disableBackdropClick open={open} onClose={onClose} onExited={handleClose}>
            <DialogTitle id="form-dialog-title">
                Set-up a new distribution service
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Wizard
                    backEnabled={backEnabled}
                    onBack={handleBack}
                    saveEnabled={saveEnabled}
                    onSave={handleSave}
                    nextEnabled={nextEnabled}
                    onNext={handleNext}
                    onStepChange={setStep}
                    step={step}
                >
                    <WizardAdvStep>
                        <WizardStepElement>
                            {input.name}
                        </WizardStepElement>
                        <WizardStepElement>
                            {input.url}
                        </WizardStepElement>
                    </WizardAdvStep>
                    <WizardIntroStep justify="space-between">
                        <Box mb={2}>
                            <WizardStepElement>
                                Follow the guided procedure to get specific instructions supporting you in adding a new
                                distribution service.
                                If you are an experienced user, guided procedure can be skipped.
                            </WizardStepElement>
                        </Box>
                        <Grid item>
                            <Tooltip placement={"right"} title="A more advanced approach with less help">
                                <Button color={"primary"} onClick={() => setStep(-2)} variant={"outlined"}>
                                    skip it
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip placement={"right"}
                                     title="An intuitive step by step guide to the creation">
                                <Button onClick={() => setStep(0)} color={"primary"} variant={"contained"}>
                                    Guided procedure
                                </Button>
                            </Tooltip>
                        </Grid>
                    </WizardIntroStep>
                    <WizardStep>
                        <WizardStepElement>
                            Which is the name of the process?
                        </WizardStepElement>
                        <WizardStepElement>
                            {input.name}
                        </WizardStepElement>
                    </WizardStep>
                    <WizardStep>
                        <WizardStepElement>
                            Which is the root url of the service?
                        </WizardStepElement>
                        <WizardStepElement>
                            {input.url}
                        </WizardStepElement>
                    </WizardStep>
                    <WizardEndStep>
                        Please verify that information provided are correct and click on Save to create the new distribution.
                    </WizardEndStep>
                </Wizard>
            </DialogContent>
        </Dialog>
    )
}