import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WizardStep, {WizardAdvStep, WizardEndStep, WizardIntroStep} from "./WizardStep";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const processChildren = (childrenList) =>{
    const processedList = []

    childrenList.forEach(children =>{
        // e è una lista li estraggo ognuno
        if(Array.isArray(children)){
            children.forEach(nephew =>{
                processedList.push(nephew)
            })
        }
        else processedList.push(children)
    })

    return processedList

}

export default function Wizard({
                                   children,
                                   backEnabled,
                                   saveEnabled,
                                   nextEnabled,
                                   onNext,
                                   onBack,
                                   step,
                                   onSave,
                                   onStepChange,
                                   saveLabel = "save",
                                   saveIcon = <SaveIcon/>,
                                   ...props
                               }) {

    // maialata paura, ma almeno risolvo un problema.
    const processedChildren = processChildren(children)

    let stepsNumber = 0;

    // faccio il conteggio dei childrens
    processedChildren.forEach(child => {
        if (child.type === WizardStep)
            stepsNumber++
    });

    let steppers = [];
    for (let i = 0; i < stepsNumber; i++)
        steppers.push(
            <Step key={"s-" + i}>
                <StepLabel/>
            </Step>
        );


    useEffect(() => {
        onStepChange(step)
    }, [step, onStepChange])


    // l'advanced step è sempre al -2
    // l'intro step è sempre al -1
    // tutti gli altri vano da 0 a n
    let stepComponent;

    for (let i = 0; i < processedChildren.length; i++)
        if (processedChildren[i].type === WizardAdvStep && step === -2) {
            stepComponent = processedChildren[i]
            break;
        } else if (processedChildren[i].type === WizardIntroStep && step === -1) {
            stepComponent = processedChildren[i]
            break;
        } else if (
            (processedChildren[i].type === WizardStep || processedChildren[i].type === WizardEndStep)
            && processedChildren.length - stepsNumber + step - 1 === i) {
            stepComponent = processedChildren[i]
            break;
        }


    return (
        <Grid container {...props}>
            <Grid hidden={step === -1 || step === -2} item xs={12} style={{paddingBottom: 32}}>
                <Stepper activeStep={step} style={{padding: 0}}>
                    {steppers}
                </Stepper>
            </Grid>

            <Grid item xs={12}>
                <Box mb={2}>
                    {stepComponent}
                </Box>
            </Grid>
            <Grid item xs={4}>

                <Box hidden={!backEnabled}>
                    <IconButton color={"primary"} onClick={onBack}><ArrowBackIcon/></IconButton>
                </Box>
            </Grid>
            <Grid item xs={4} style={{textAlign: "center"}}>


            </Grid>
            <Grid item xs={4} style={{textAlign: "right"}}>
                <Box mt={1} hidden={!saveEnabled}>
                    <Button onClick={onSave} color={"primary"}
                            variant={"contained"} startIcon={saveIcon}>
                        {saveLabel}
                    </Button>
                </Box>
                <Box hidden={!nextEnabled}>
                    <IconButton color={"primary"}
                                onClick={onNext}><ArrowForwardIcon/></IconButton>
                </Box>

            </Grid>
        </Grid>

    );
}
