import React from 'react';
import Grid from "@material-ui/core/Grid";


export default function WizardStep({children, ...props}) {


    return (

            <Grid container spacing={1} {...props}>

                {children}

            </Grid>

    );
}


export function WizardIntroStep({children, ...props}) {


    return (

        <Grid container spacing={1} {...props}>

            {children}

        </Grid>

    );
}


export function WizardAdvStep({children, ...props}) {


    return (

        <Grid container spacing={1} {...props}>

            {children}

        </Grid>

    );
}

export function WizardEndStep({children, ...props}) {


    return (

        <Grid container spacing={1} {...props}>

            {children}

        </Grid>

    );
}
