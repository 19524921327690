import React from 'react';
import {Route, Switch} from "react-router-dom";
import ComponentRouter from "./component/ComponentRouter";
import SystemScreen from "./SystemScreen";
import SystemDetailScreen from "./SystemDetailScreen";


export default function SystemRouter() {

    return (
        <Switch>
            <Route exact path="/system/:systemId" component={SystemDetailScreen}/>
            <Route path="/system/:systemId/c" component={ComponentRouter}/>
            <Route path="/system" component={SystemScreen}/>
        </Switch>
    );
}
