import React, {useContext} from 'react';
import {AppContext} from "../../App";
import Grid from "@material-ui/core/Grid";
import {fetchSystemRBDHistoryPage} from "../../utils/apiCaller";

import {NavLink, useHistory, useParams} from "react-router-dom";


import AdvancedTable from "../../components/AdvancedTable";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Typography} from "@material-ui/core";


export default function Processes() {

    const history = useHistory()
    const [state] = useContext(AppContext)
    const {systemId} = useParams()


    const simulationsColumns = [
        {key: "timeString", name: "Execution date"},
        {key: "reliabilityText", name: "Reliability"},
        {key: "missionReliabilityText", name: "Mission reliability"},
        {key: "mttfText", name: "Mean time to failure"},
    ]

    return (
        <Grid container spacing={4}
              alignItems="flex-end"
              justify="flex-end"
              style={{
                  margin: 0,
                  width: '100%',
              }}>

            <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={1}>
                        <Button variant={"text"}
                                component={NavLink}
                                to={"/rbd/" + systemId}
                                color={"primary"}
                                startIcon={<ArrowBackIcon/>}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant={"button"} color={"primary"}>
                            RBD / <u>History</u>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={12}>
                <AdvancedTable
                    jwt={state.user.jwt}
                    fields={simulationsColumns}
                    requestArgs={{systemId:systemId}}
                    fetchingPageFunction={fetchSystemRBDHistoryPage}
                    onSelected={(sim) => history.push("/rbd/"+ systemId +"/history/" + sim.id)}
                />

            </Grid>
        </Grid>

    );
}
