import React, {useContext, useState} from 'react';
import {AppContext} from "../../App";
import Grid from "@material-ui/core/Grid";
import {fetchAllUserFailures, fetchUserFailuresPage} from "../../utils/apiCaller";

import {useHistory} from "react-router-dom";

import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import AdvancedTable from "../../components/AdvancedTable";
import {objToCsv} from "../../utils/utils";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";


export default function Failures() {

    const history = useHistory();
    const [state] = useContext(AppContext);
    const [searchFilter, setSearchFilter] = useState("");
    const [searchFilterValue, setSearchFilterValue] = useState("");
    const [refreshTable, setRefreshTable] = useState(false);


    const eventsTableColumns = [
        {key: "eventName", name: "Name"},
        {key: "description", name: "Description", maxWidth: "12rem"},
        {key: "timeString", name: "Time", sort: "time", maxWidth: "4rem"},
        {key: "failureModeName", name: "Failure mode", maxWidth: "4rem", sort:"type_Name"},
        {key: "complete", name: "Complete", boolean: true},
    ]

    const handleKeyPress = (event) => {
        if (event.keyCode === 13)
            setSearchFilter(searchFilterValue)
    }

    const handleExport = () => {
        fetchAllUserFailures(state.user.jwt)
            .then(list =>{
                const csv = objToCsv(list)
                let a = document.createElement('a');
                a.textContent = 'download.csv';
                a.download = "failures.csv";
                a.href = 'data:text/csv;charset=utf-8,' + escape(csv);
                document.body.appendChild(a);
                a.click();
            })
            .catch((error) => alert(error.message))

    }

    return (
        <Grid container spacing={3}

              alignItems="center"
              justify="flex-end"
              style={{
                  margin: 0,
                  width: '100%',
              }}>
            <Grid item xs={6} style={{textAlign: "right"}}>
                <FormControl variant={"outlined"}>
                    <OutlinedInput
                        placeholder={"Search"}
                        onKeyUp={handleKeyPress}
                        onChange={(event => setSearchFilterValue(event.target.value))}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton edge="end"
                                            onClick={() => setSearchFilter(searchFilterValue)}>
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <Button onClick={handleExport} color={"primary"}
                        variant={"contained"} startIcon={<GetAppIcon/>}>
                    Export
                </Button>
            </Grid>
            <Grid item xs={12}>
                <AdvancedTable
                    mustRefresh={refreshTable} onRefreshed={() => setRefreshTable(false)}
                    jwt={state.user.jwt}
                    fields={eventsTableColumns}
                    filterName={searchFilter}
                    fetchingPageFunction={fetchUserFailuresPage}
                    onSelected={(failure) => history.push("/failure/" + failure.id)}
                />

            </Grid>
        </Grid>

    );
}
