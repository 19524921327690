import {NodeModel} from '@projectstorm/react-diagrams';
import {ReclaimPortModel} from "./port/ReclaimPortModel";


export class ReclaimNode extends NodeModel {
    constructor(options = {}, serverNode) {
        super({
            ...options,
            id: serverNode.id
        });

        this.parallelKind = serverNode.parallelKind ? serverNode.parallelKind : "TOTAL"
        this.partialK = serverNode.partialK ? serverNode.partialK : 1;

        this.standBy = serverNode.isStandBy ? serverNode.isStandBy : false;
        this.fractionalFactor = serverNode.fractionalFactor ? serverNode.fractionalFactor : 10;

        this.showStandby = false
        this.showFractional = false

        const inPort = new ReclaimPortModel({
            in: true,
            name: "IN",
            label: "IN",
            id: serverNode.inPort.id
        });

        const outPort = new ReclaimPortModel({
            in: false,
            name: "OUT",
            label: "OUT",
            id: serverNode.outPort.id
        });

        this.addPort(inPort)
        this.addPort(outPort)

        outPort.registerListener({
            linksChanged: () => this.onOutLinksChange()
        })
        inPort.registerListener({
            linksChanged: () => this.onInLinksChange()
        })

        this.outLinksCount = 0

    }

    onOutLinksChange() {
        let temp = Object.values(this.getOutPort().links).length;

        // se il numero di link rimane invariato allora non fare nulla
        if (temp === this.outLinksCount)
            return

        this.outLinksCount = temp

        this.updateChildrenFlags()

    }

    onInLinksChange() {
        let inLinks = Object.values(this.getInPort().links).length;

        // se il numero di link in entrata è 0 -> allora nascondi tutta la roba
        // altrimenti dovrebbe essere il parent a dirmi cosa devo far vedere :)
        if(inLinks === 0){
            this.showFractional = false;
            this.showStandby = false;

            // notifico che ci sono cambiamenti
            this.fireEvent(this.serialize(), "paramChange")
        }

        // gli chiedo una notifica
        if(inLinks === 1){
            let parent = Object.values(this.getInPort().links)[0].getSourcePort().getParent()

             parent.updateChildrenFlags()
        }



    }

    updateChildrenFlags(){
        let connectedNodes = Object.values(this.getOutPort().links).filter(l => l.getTargetPort()).map(l => l.getTargetPort().parent)

        // se sono sequenziale mi assicuro che i figli non mostrino cose che non dovrebbero
        // altrimenti li notifico di mostrare le cose giuste
        if (this.outLinksCount <= 1)
            connectedNodes.forEach(node => {
                node.showStandby = false;
                node.showFractional = false
            })
        else{
            // cambio il flag a dipendenza se devono mostrare lo standby o il fractional
            if (this.parallelKind  === "STANDBY")
                connectedNodes.forEach(node => node.showStandby = true)
            else
                connectedNodes.forEach(node => node.showStandby = false)

            if (this.parallelKind  === "FRACTIONAL")
                connectedNodes.forEach(node => node.showFractional = true)
            else
                connectedNodes.forEach(node => node.showFractional = false)
        }

        // notifico che ci sono cambiamenti
        this.fireEvent(this.serialize(), "paramChange")

    }

    setParallelKind(kind) {
        this.parallelKind = kind
        this.updateChildrenFlags()
    }

    isSequential() {
        let links = Object.values(this.getOutPort().links).filter(l => l.getTargetPort())
        return links.length <= 1
    }

    setPartialK(k) {
        this.partialK = k
        this.fireEvent(this.serialize(), "paramChange")
    }

    setStandBy(standBy) {
        this.standBy = standBy
        this.fireEvent(this.serialize(), "paramChange")
    }

    setFractional(frac) {
        this.fractionalFactor = frac
        this.fireEvent(this.serialize(), "paramChange")
    }

    getOutPort() {
        return this.getPort("OUT")
    }

    getInPort() {
        return this.getPort("IN")
    }

    serialize() {
        return {
            ...super.serialize(),
            standBy: this.standBy,
            fractionalFactor: this.fractionalFactor,
            showStandby: this.showStandby,
            parallelKind: this.parallelKind,
            partialK: this.partialK,
            showFractional: this.showFractional,
            outPort: this.getPort("OUT").getID(),
            inPort: this.getPort("IN").getID()
        };
    }
}
