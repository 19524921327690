import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Wizard from "../../components/wizard/Wizard";
import WizardStep, {WizardAdvStep, WizardEndStep, WizardIntroStep} from "../../components/wizard/WizardStep";
import WizardStepElement from "../../components/wizard/WizardStepElement";
import RatDropDown from "../../components/RatDropDown";
import Box from "@material-ui/core/Box";
import {
    fetchAllSystemComponents,
    fetchAllUserProcesses,
    fetchAllUserSystems,
    putNewFailureMode
} from "../../utils/apiCaller";
import {AppContext} from "../../App";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {InputLabel} from "@material-ui/core";

const fieldsValidation = {
    name: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter a name"}
        return {error: false, text: ""}
    },
    process: (value) => {
        if (!value || !value.id)
            return {error: true, text: "Please, select a process"}
        return {error: false, text: ""}
    },
    failureEffect: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter a failure effect"}
        return {error: false, text: ""}
    },
    potentialCauses: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter the potential causes"}
        return {error: false, text: ""}
    },
    currentControls: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter the current controls"}
        return {error: false, text: ""}
    },
    mitigationActions: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter the mitigation actions"}
        return {error: false, text: ""}
    },
    shortTermActions: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter the short term actions"}
        return {error: false, text: ""}
    },
    causeType: (value) => {
        if (value === " ")
            return {error: true, text: "Please, select a cause type"}
        return {error: false, text: ""}
    },
    severityRationale: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter the severity rationale"}
        return {error: false, text: ""}
    },
    detectabilityRationale: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter the occurrence rationale"}
        return {error: false, text: ""}
    },
    occurrenceRationale: (value) => {
        if (value === "")
            return {error: true, text: "Please, enter the occurrence rationale"}
        return {error: false, text: ""}
    }
}

export default function FailureModeWizard({onSave}) {

    const [step, setStep] = useState(-1);


    const [state] = useContext(AppContext);

    const [processList, setProcessList] = useState([]);
    const [componentsList, setComponentsList] = useState([]);
    const [systemsList, setSystemsList] = useState([]);

    const [name, setName] = useState({error: false, text: "", value: ""});
    const [occurrence, setOccurrence] = useState({error: false, text: "", value: -1});
    const [detectability, setDetectability] = useState({error: false, text: "", value: -1});
    const [severity, setSeverity] = useState({error: false, text: "", value: -1});
    const [failureEffect, setFailureEffect] = useState({error: false, text: "", value: ""});
    const [potentialCauses, setPotentialCauses] = useState({error: false, text: "", value: ""});
    const [currentControls, setCurrentControls] = useState({error: false, text: "", value: ""});
    const [mitigationActions, setMitigationActions] = useState({error: false, text: "", value: ""});
    const [shortTermActions, setShortTermActions] = useState({error: false, text: "", value: ""});
    const [causeType, setCauseType] = useState({error: false, text: "", value: " "});
    const [severityRationale, setSeverityRationale] = useState({error: false, text: "", value: ""});
    const [detectabilityRationale, setDetectabilityRationale] = useState({error: false, text: "", value: ""});
    const [occurrenceRationale, setOccurrenceRationale] = useState({error: false, text: "", value: ""});

    const [process, setProcess] = useState({error: false, text: "", value: null});
    const [component, setComponent] = useState({error: false, text: "", value: null});
    const [system, setSystem] = useState({error: false, text: "", value: null});

    const [nextEnabled, setNextEnabled] = useState(false);
    const [backEnabled, setBackEnabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const checkErrors = (step) => {
        let hasError = false

        switch (step) {
            case 0:
                let processError = fieldsValidation.process(process.value)
                let nameError = fieldsValidation.name(name.value)
                setName({error: nameError.error, text: nameError.text, value: name.value})
                setProcess({error: processError.error, text: processError.text, value: process.value})
                hasError = processError.error || nameError.error
                break
            case 1:
                let effectError = fieldsValidation.failureEffect(failureEffect.value)
                setFailureEffect({error: effectError.error, text: effectError.text, value: failureEffect.value})
                hasError = effectError.error
                break
            case 2:
                let severityRationaleError = fieldsValidation.severityRationale(severityRationale.value)
                setSeverityRationale({
                    error: severityRationaleError.error,
                    text: severityRationaleError.text,
                    value: severityRationale.value
                })
                hasError = severityRationaleError.error
                break
            case 3:
                let pcError = fieldsValidation.potentialCauses(potentialCauses.value)
                let ctError = fieldsValidation.causeType(causeType.value)
                setCauseType({error: ctError.error, text: ctError.text, value: causeType.value})
                setPotentialCauses({
                    error: pcError.error,
                    text: pcError.text,
                    value: potentialCauses.value
                })
                hasError = pcError.error || ctError.error
                break
            case 4:
                let ccError = fieldsValidation.currentControls(currentControls.value)
                setCurrentControls({
                    error: ccError.error,
                    text: ccError.text,
                    value: currentControls.value
                })
                hasError = ccError.error
                break
            case 5:
                let occurrErr = fieldsValidation.occurrenceRationale(occurrenceRationale.value)
                setOccurrenceRationale({
                    error: occurrErr.error,
                    text: occurrErr.text,
                    value: occurrenceRationale.value
                })
                hasError = occurrErr.error
                break
            case 6:
                let detecError = fieldsValidation.detectabilityRationale(detectabilityRationale.value)
                setDetectabilityRationale({
                    error: detecError.error,
                    text: detecError.text,
                    value: detectabilityRationale.value
                })
                hasError = detecError.error
                break
            case 7:
                let sta = fieldsValidation.shortTermActions(shortTermActions.value)
                let ma = fieldsValidation.mitigationActions(mitigationActions.value)
                setShortTermActions({error: sta.error, text: sta.text, value: shortTermActions.value})
                setMitigationActions({error: ma.error, text: ma.text, value: mitigationActions.value})
                hasError = sta.error || ma.error
                break
            default:
        }

        return hasError
    }

    const handleNext = () => {
        if (!checkErrors(step))
            setStep(step + 1);

    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = () => {
        // mi assicuro che tutto sia popolato come si deve (nel caso della versione avanzata)
        if (step === -2) {
            // faccio un controllo su tutti i campi
            let hasError = false
            for (let i = 0; i < 8; i++)
                hasError = hasError || checkErrors(step)

            if (hasError)
                return

        }

        // costruisco un oggetto rappresentante il failure mode
        const payload = {
            name: name.value,
            failureEffect: failureEffect.value,
            potentialCauses: potentialCauses.value,
            currentControls: currentControls.value,
            mitigationActions: mitigationActions.value,
            shortTermActions: shortTermActions.value,
            causeType: causeType.value,
            occurrence: occurrence.value,
            detectability: detectability.value,
            severityRationale: severityRationale.value,
            detectabilityRationale: detectabilityRationale.value,
            occurrenceRationale: occurrenceRationale.value,
            severity: severity.value,
            processId: process.value.id
        }

        if (causeType.value === "SYSTEM" && component.value !== null)
            payload.componentId = component.value.id;


        // salvo il failure mode e lo ritorno
        putNewFailureMode(state.user.jwt, payload)
            .then(onSave)
            .catch(console.log)

    }

    useEffect(() => {
        fetchAllUserProcesses(state.user.jwt)
            .then(setProcessList)
            .catch(alert)

        fetchAllUserSystems(state.user.jwt)
            .then(setSystemsList)
            .catch(alert)
    }, [state.user.jwt])

    useEffect(() => {
        if (system.value)
            fetchAllSystemComponents(state.user.jwt, system.value.id)
                .then(setComponentsList)
                .catch(alert)

    }, [state.user.jwt, system.value])

    useEffect(() => {

        switch (step) {
            case -2:
                setBackEnabled(false)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            case -1:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(false)
                break
            case 0:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(true)
                break
            case (7):
                setBackEnabled(true)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            default:
                setBackEnabled(true)
                setSaveEnabled(false)
                setNextEnabled(true)


        }

    }, [step])

    const fields = {
        name: {
            description: "Which is the name of the failure mode?",
            input:
                <TextField error={name.error} helperText={name.text} value={name.value}
                           placeholder={"Name"} variant={"outlined"} fullWidth label={""}
                           onChange={e => {
                               let val = e.target.value
                               setName({error: name.error, text: name.text, value: val})
                           }}/>
        },
        potentialCauses: {
            description: "Which are the potential causes of the failure?",
            input:
                <TextField error={potentialCauses.error} helperText={potentialCauses.text}
                           multiline placeholder={"Potential causes"}
                           variant={"outlined"} fullWidth
                           value={potentialCauses.value}
                           onChange={e => {
                               let val = e.target.value
                               setPotentialCauses({
                                   error: potentialCauses.error,
                                   text: potentialCauses.text,
                                   value: val
                               })
                           }}/>
        },
        currentControls: {
            description: "Which are the current controls on the failure mode?",
            input:
                <TextField error={currentControls.error} helperText={currentControls.text}
                           multiline placeholder={"Current controls"} value={currentControls.value}
                           variant={"outlined"} fullWidth
                           onChange={e => {
                               let val = e.target.value
                               setCurrentControls({
                                   error: currentControls.error,
                                   text: currentControls.text,
                                   value: val
                               })
                           }}/>
        },
        failureEffect: {
            description: "Which is the effect of the failure mode?",
            input:
                <TextField error={failureEffect.error} helperText={failureEffect.text}
                           multiline placeholder={"Failure effect"}
                           variant={"outlined"} fullWidth value={failureEffect.value}
                           onChange={e => {
                               let val = e.target.value
                               setFailureEffect({
                                   error: failureEffect.error,
                                   text: failureEffect.text,
                                   value: val
                               })
                           }}/>
        },
        mitigationActions: {
            description: "Which are the possible mitigation actions to avoid in future the failure mode?",
            input:
                <TextField error={mitigationActions.error} helperText={mitigationActions.text}
                           multiline placeholder={"Mitigation actions"}
                           variant={"outlined"} fullWidth value={mitigationActions.value}
                           onChange={e => {
                               let val = e.target.value
                               setMitigationActions({
                                   error: currentControls.error,
                                   text: currentControls.text,
                                   value: val
                               })
                           }}/>
        },
        occurrence: {
            description: "Which is the occurrence of a " + name.value + "  failure?",
            input:
                <RatDropDown
                    label={"Occurrence"}
                    values={['Improbable', 'Remote', 'Occasional', 'Probable', 'Frequent']}
                    value={occurrence.value}
                    onChange={(event, value) => setOccurrence({value: value})}
                />
        },
        detectability: {
            description: "Which is the detectability of the failure mode?",
            input:
                <RatDropDown
                    label={"Detectability"}
                    values={['Almost certain', 'High', 'Moderate', 'Low', 'Improbable']}
                    value={detectability.value}
                    onChange={(event, value) => setDetectability({value: value})}
                />
        },
        severity: {
            description: "Which is the severity of the failure mode?",
            input:
                <RatDropDown
                    label={"Severity"}
                    values={['Tolerable', 'Low', 'Medium', 'Relevant', 'High']}
                    value={severity.value}
                    onChange={(event, value) => setSeverity({value: value})}
                />
        },
        process: {
            description: "In which process does the failure mode occur?",
            input:
                <Autocomplete
                    options={processList}
                    onChange={(event, e) => setProcess({error: process.error, text: process.text, value: e})}
                    getOptionLabel={(option) => option.name}
                    value={process.value}
                    renderInput={(params) =>
                        <TextField
                            error={process.error}
                            helperText={process.text}
                            placeholder={"Process"}
                            {...params}
                            fullWidth variant="outlined"/>
                    }
                />
        },
        component: {
            description: "Which is the component in which the failure mode is assigned?",
            input:
                <Autocomplete
                    options={componentsList}
                    onChange={(event, e) => setComponent({error: component.error, text: component.text, value: e})}
                    getOptionLabel={(option) => option.name}
                    value={component.value}
                    renderInput={(params) =>
                        <TextField
                            error={component.error}
                            helperText={component.text}
                            placeholder={"Component"}
                            {...params}
                            fullWidth variant="outlined"/>
                    }
                />
        },
        system: {
            description: "Which is the system in which the failure mode is assigned?",
            input:
                <Autocomplete
                    options={systemsList}
                    onChange={(event, e) => {
                        setSystem({error: system.error, text: system.text, value: e})
                        setComponent({error: false, text: "", value: null})
                    }}
                    getOptionLabel={(option) => option.name}
                    value={system.value}
                    renderInput={(params) =>
                        <TextField
                            error={system.error}
                            helperText={system.text}
                            placeholder={"System"}
                            {...params}
                            fullWidth variant="outlined"/>
                    }
                />
        },
        shortTermActions: {
            description: "Which are the short term actions currently implemented to contain the failure mode?",
            input:
                <TextField error={shortTermActions.error} helperText={shortTermActions.text}
                           multiline placeholder={"Short term actions"}
                           variant={"outlined"} fullWidth value={shortTermActions.value}
                           onChange={e => {
                               let val = e.target.value
                               setShortTermActions({
                                   error: shortTermActions.error,
                                   text: shortTermActions.text,
                                   value: val
                               })
                           }}/>
        },
        severityRationale: {
            description: "Severety rationale",
            input:
                <TextField error={severityRationale.error} helperText={severityRationale.text}
                           multiline placeholder={"Severety rationale"}
                           variant={"outlined"} fullWidth value={severityRationale.value}
                           onChange={e => {
                               let val = e.target.value
                               setSeverityRationale({
                                   error: severityRationale.error,
                                   text: severityRationale.text,
                                   value: val
                               })
                           }}/>
        },
        detectabilityRationale: {
            description: "Detectability rationale",
            input:
                <TextField error={detectabilityRationale.error} helperText={detectabilityRationale.text}
                           multiline placeholder={"Detectability rationale"}
                           variant={"outlined"} fullWidth value={detectabilityRationale.value}
                           onChange={e => {
                               let val = e.target.value
                               setDetectabilityRationale({
                                   error: detectabilityRationale.error,
                                   text: detectabilityRationale.text,
                                   value: val
                               })
                           }}/>
        },
        occurrenceRationale: {
            description: "Occurrence rationale",
            input:
                <TextField error={occurrenceRationale.error} helperText={occurrenceRationale.text}
                           multiline placeholder={"Occurrence rationale"}
                           variant={"outlined"} fullWidth value={occurrenceRationale.value}
                           onChange={e => {
                               let val = e.target.value
                               setOccurrenceRationale({
                                   error: occurrenceRationale.error,
                                   text: occurrenceRationale.text,
                                   value: val
                               })
                           }}/>
        },
        causeType: {
            description: "Cause type",
            input:
                <FormControl

                    error={causeType.error}
                    fullWidth variant="outlined">
                    <InputLabel shrink id="cause-type-label">
                        Cause type
                    </InputLabel>
                    <Select
                        labelId="cause-type-label"
                        aria-describedby="causeHelper"
                        fullWidth
                        placeholder={"Cause type"}
                        label={"Cause type"}
                        value={causeType.value}
                        onChange={e => {
                            let val = e.target.value
                            setCauseType({
                                error: causeType.error,
                                text: causeType.text,
                                value: val
                            })
                        }}
                    >
                        <MenuItem value={" "}>
                            <em>Not defined</em>
                        </MenuItem>
                        <MenuItem value={"OTHER"}>
                            Other
                        </MenuItem>
                        <MenuItem value={"MANPOWER"}>
                            Manpower
                        </MenuItem>
                        <MenuItem value={"METHOD"}>
                            Method
                        </MenuItem>
                        <MenuItem value={"MATERIAL"}>
                            Material
                        </MenuItem>
                        <MenuItem value={"SYSTEM"}>
                            System, equipment or machine
                        </MenuItem>
                        <MenuItem value={"MEASUREMENT"}>
                            Measurement
                        </MenuItem>
                        <MenuItem value={"ENVIRONMENT"}>
                            Environment
                        </MenuItem>

                    </Select>
                    <FormHelperText id="causeHelper">{causeType.text}</FormHelperText>
                </FormControl>
        },
    }

    console.log(causeType)

    return (
        <Wizard backEnabled={backEnabled}
                onBack={handleBack}
                saveEnabled={saveEnabled}
                onSave={handleSave}
                nextEnabled={nextEnabled}
                onNext={handleNext}
                onStepChange={setStep}
                step={step}
        >
            <WizardAdvStep>
                <WizardStepElement>{fields.process.input}</WizardStepElement>
                <WizardStepElement>{fields.name.input}</WizardStepElement>
                <WizardStepElement>{fields.failureEffect.input}</WizardStepElement>
                <WizardStepElement>{fields.severity.input}</WizardStepElement>
                <WizardStepElement>{fields.severityRationale.input}</WizardStepElement>
                <WizardStepElement>{fields.causeType.input}</WizardStepElement>
                <WizardStepElement
                    style={{display: causeType.value === "SYSTEM" ? "" : "none"}}
                >{fields.system.input}</WizardStepElement>
                <WizardStepElement
                    style={{display: causeType.value === "SYSTEM" && !!system.value ? "" : "none"}}
                >{fields.component.input}</WizardStepElement>
                <WizardStepElement>{fields.potentialCauses.input}</WizardStepElement>
                <WizardStepElement>{fields.currentControls.input}</WizardStepElement>
                <WizardStepElement>{fields.occurrence.input}</WizardStepElement>
                <WizardStepElement>{fields.occurrenceRationale.input}</WizardStepElement>

                <WizardStepElement>{fields.detectability.input}</WizardStepElement>
                <WizardStepElement>{fields.detectabilityRationale.input}</WizardStepElement>

                <WizardStepElement>{fields.shortTermActions.input}</WizardStepElement>
                <WizardStepElement>{fields.mitigationActions.input}</WizardStepElement>
            </WizardAdvStep>
            <WizardIntroStep justify="space-between">
                <Box mb={2}>
                    <WizardStepElement>
                        Follow the guided procedure to get specific instructions supporting you in adding a new
                        failure type.
                        If you are an experienced user, guided procedure can be skipped.
                    </WizardStepElement>
                </Box>
                <Grid item>
                    <Tooltip placement={"right"} title="A more advanced approach with less help">
                        <Button color={"primary"} onClick={() => setStep(-2)} variant={"outlined"}>
                            skip it
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"right"}
                             title="An intuitive step by step guide to the creation">
                        <Button onClick={() => setStep(0)} color={"primary"} variant={"contained"}>
                            Guided procedure
                        </Button>
                    </Tooltip>
                </Grid>
            </WizardIntroStep>

            <WizardStep>
                <WizardStepCouple e={fields.process}/>
                <WizardStepCouple e={fields.name}/>
            </WizardStep>

            <WizardStep>
                <WizardStepCouple e={fields.failureEffect}/>
            </WizardStep>

            <WizardStep>
                <WizardStepCouple e={fields.severity}/>
                <WizardStepCouple e={fields.severityRationale}/>
            </WizardStep>

            <WizardStep>
                <WizardStepCouple e={fields.causeType}/>
                <WizardStepCouple e={fields.potentialCauses}/>
                <WizardStepCouple style={{display: causeType.value === "SYSTEM" ? "" : "none"}} e={fields.system}/>
                <WizardStepCouple style={{display: causeType.value === "SYSTEM" && !!system.value ? "" : "none"}}
                                  e={fields.component}/>
            </WizardStep>

            <WizardStep>
                <WizardStepCouple e={fields.currentControls}/>
                <WizardStepCouple e={fields.occurrence}/>
                <WizardStepCouple e={fields.occurrenceRationale}/>
            </WizardStep>

            <WizardStep>
                <WizardStepCouple e={fields.detectability}/>
                <WizardStepCouple e={fields.detectabilityRationale}/>
            </WizardStep>

            <WizardStep>
                <WizardStepCouple e={fields.shortTermActions}/>
                <WizardStepCouple e={fields.mitigationActions}/>
            </WizardStep>

            <WizardEndStep>
                Please verify that information provided are correct and click on Save to create the new
                failure mode.
            </WizardEndStep>

        </Wizard>

    )
}

const WizardStepCouple = ({e, ...props}) => {
    return <>
        <WizardStepElement {...props}>
            {e.description}
        </WizardStepElement>
        <WizardStepElement {...props}>
            {e.input}
        </WizardStepElement>
    </>
}