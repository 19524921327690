import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {fetchAllProcessFailureModes, fetchAllUserProcesses} from "../../utils/apiCaller";
import {AppContext} from "../../App";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Wizard from "../../components/wizard/Wizard";
import WizardStep, {WizardAdvStep, WizardEndStep, WizardIntroStep} from "../../components/wizard/WizardStep";
import WizardStepElement from "../../components/wizard/WizardStepElement";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {parseDuration} from "../../utils/utils";

export default function FailureEventCreationDialog({
                                                       open,
                                                       onClose,
                                                       onSave
                                                   }) {

    const [step, setStep] = useState(-1);

    const [state] = useContext(AppContext);

    const [processes, setProcesses] = useState([]);
    const [failureModes, setFailureModes] = useState([]);

    const [eventName, setEventName] = useState("");
    const [eventNameError, setEventNameError] = useState({error: false, text: ""});
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState({error: false, text: ""});
    const [shortTermActions, setShortTermActions] = useState("");
    const [staError, setStaError] = useState({error: false, text: ""});
    const [componentReplaced, setComponentReplaced] = useState(false);
    const [timeToRepair, setTimeToRepair] = useState("");
    // const [ttrError, setTtrError] = useState({error: false, text: ""});
    const [process, setProcess] = useState(null);
    // const [processError, setProcessError] = useState({error: false, text: ""});
    const [time, setTime] = useState(null);
    const [timeError, setTimeError] = useState({error: false, text: ""});
    const [failureMode, setFailureMode] = useState(null);
    // const [failureModeError, setFailureModeError] = useState({error: false, text: ""});


    const [nextEnabled, setNextEnabled] = useState(false);
    const [backEnabled, setBackEnabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const fieldsValidation = {
        eventName: () => {
            if (eventName === "")
                return ({error: true, text: "Please, enter an event name"})
            else
                return ({error: false, text: ""})
        },
        description: () => {
            if (description === "")
                return ({error: true, text: "Please, enter an event description"})
            else
                return ({error: false, text: ""})
        },
        sta: () => {
            if (shortTermActions === "")
                return ({error: true, text: "Please, specify the short term actions implemented"})
            else
                return ({error: false, text: ""})
        },
        ttr: () => {
            if (timeToRepair === "")
                return {error: true, text: "Please, specify the time to repair"}
            else
                return {error: false, text: ""}
        },
        process: () => {
            if (!process || !process.id)
                return {error: true, text: "Please, select a process from the list"}
            else
                return {error: false, text: ""}
        },
        failureMode: () => {
            if (!failureMode || !failureMode.id)
                return {error: true, text: "Please, select a failure type from the list"}
            else
                return {error: false, text: ""}
        },
        time: () => {
            if (!time)
                return {error: true, text: "Please, select the failure time"}
            else
                return {error: false, text: ""}
        }
    }

    const handleNext = () => {
        let hasError = false

        switch (step) {
            case 0:
                const nameError = fieldsValidation.eventName()
                const descError = fieldsValidation.description()
                hasError = nameError.error || descError.error
                setEventNameError(nameError)
                setDescriptionError(descError)
                break
            case 1:
                const sta = fieldsValidation.sta()
                hasError = sta.error
                setStaError(sta)
                break
            case 6:
                const timeVal = fieldsValidation.time()
                hasError = timeVal.error
                setTimeError(timeVal)
                break
            default:
        }

        if (!hasError)
            setStep(step + 1);

    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = () => {
        // mi assicuro che tutto sia popolato come si deve (nel caso della versione avanzata)
        if (step === -2) {
            const nameVal = fieldsValidation.eventName()
            const descVal = fieldsValidation.description()
            const staVal = fieldsValidation.sta()
            const timeVal = fieldsValidation.time()
            setEventNameError(nameVal)
            setDescriptionError(descVal)
            setStaError(staVal)
            setTimeError(timeVal)

            // mi assicuro che nessuno di essi sia error:true
            if (nameVal.error ||
                descVal.error ||
                staVal.error ||
                timeVal.error
            )
                return

        }


        // costruisco un oggetto rappresentante il failure
        let payload = {
            eventName: eventName,
            description: description,
            shortTermActions: shortTermActions,
            componentReplaced: componentReplaced,
        }

        if (!!timeToRepair)
            payload.timeToRepair = parseDuration(timeToRepair)

        if (!!failureMode)
            payload.failureModeId = failureMode.id

        if (time !== null)
            payload.time = time.getTime() / 1000

        onSave(payload)
    }

    const handleClose = () => {
        setStep(-1)
    }

    useEffect(() => {

        switch (step) {
            case -2:
                setBackEnabled(false)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            case -1:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(false)
                break
            case 0:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(true)
                break
            case 7:
                setBackEnabled(true)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            default:
                setBackEnabled(true)
                setSaveEnabled(false)
                setNextEnabled(true)


        }

    }, [step])

    // carico i processi dell'utente
    useEffect(() => {
        if (open) {
            // carico tutti i componenti del sistema
            fetchAllUserProcesses(state.user.jwt)
                .then(processes => setProcesses(processes))
                .catch(error => alert(error))
        }
    }, [state.user.jwt, state.system, open])


    // carico i failure type del componente
    useEffect(() => {
        if (!process || !process.id)
            return

        // carico tutti i componenti del sistema
        fetchAllProcessFailureModes(state.user.jwt, process.id)
            .then(result => setFailureModes(result))
            .catch(error => alert(error))

    }, [state.user.jwt, process])


    return (
        <Dialog disableBackdropClick open={open} onClose={onClose} onExited={handleClose}>
            <DialogTitle id="form-dialog-title">
                Add a new failure event
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Wizard
                    backEnabled={backEnabled}
                    onBack={handleBack}
                    saveEnabled={saveEnabled}
                    onSave={handleSave}
                    nextEnabled={nextEnabled}
                    onNext={handleNext}
                    onStepChange={setStep}
                    step={step}
                >
                    <WizardAdvStep>
                        <WizardStepElement>
                            <TextField
                                error={eventNameError.error}
                                helperText={eventNameError.text}
                                placeholder={"Event name"} variant={"outlined"} fullWidth label={""}
                                value={eventName} onChange={e => setEventName(e.target.value)}/>
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={descriptionError.error}
                                helperText={descriptionError.text}
                                placeholder={"Description"} multiline variant={"outlined"} fullWidth
                                label={""}
                                value={description} onChange={e => setDescription(e.target.value)}/>
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={staError.error}
                                helperText={staError.text}
                                multiline placeholder={"Short term actions"}
                                variant={"outlined"} fullWidth
                                value={shortTermActions}
                                onChange={e => setShortTermActions(e.target.value)}/>
                        </WizardStepElement>
                        <WizardStepElement>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch checked={componentReplaced}
                                            onChange={((event, checked) => setComponentReplaced(checked))}
                                            color="primary"/>
                                }
                                label="Component replaced"
                                labelPlacement="start"
                            />
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField placeholder={"Eg. 1d 3h 20m"} value={timeToRepair}
                                       label="Time to repair" onChange={(event => setTimeToRepair(event.target.value))}
                                       variant="outlined" fullWidth/>
                        </WizardStepElement>
                        <WizardStepElement>
                            <Autocomplete
                                options={processes}
                                onChange={(event, c) => setProcess(c)}
                                getOptionLabel={(option) => option.name}
                                value={process}
                                renderInput={(params) =>
                                    <TextField
                                        // error={processError.error}
                                        // helperText={processError.text}
                                        placeholder={"Process"}
                                        {...params}
                                        fullWidth variant="outlined"/>
                                }
                            />
                        </WizardStepElement>
                        <WizardStepElement>
                            <Autocomplete
                                options={failureModes}
                                onChange={(event, t) => {
                                    setTime(null)
                                    setFailureMode(t)
                                }}
                                getOptionLabel={(option) => option.name}
                                value={failureMode}
                                renderInput={(params) =>
                                    <TextField
                                        // error={failureModeError.error}
                                        // helperText={failureModeError.text}
                                        placeholder={"Failure type"}
                                        {...params}
                                        fullWidth variant="outlined"/>
                                }
                            />
                        </WizardStepElement>
                        <WizardStepElement>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    disableFuture
                                    minDate={failureMode ? new Date(failureMode.componentInstallationDate * 1000) : undefined}
                                    inputVariant="outlined"
                                    error={timeError.error}
                                    placeholder={"Event time"}
                                    helperText={timeError.text}
                                    ampm={false} fullWidth format="dd/MM/yyyy hh:mm:ss"
                                    value={time} onChange={setTime}
                                />
                            </MuiPickersUtilsProvider>
                        </WizardStepElement>
                    </WizardAdvStep>

                    <WizardIntroStep justify="space-between">
                        <Box mb={2}>
                            <WizardStepElement>
                                Follow the guided procedure to get specific instructions supporting you in adding a new
                                failure event.
                                If you are an experienced user, guided procedure can be skipped.
                            </WizardStepElement>
                        </Box>
                        <Grid item>
                            <Tooltip placement={"right"} title="A more advanced approach with less help">
                                <Button color={"primary"} onClick={() => setStep(-2)} variant={"outlined"}>
                                    skip it
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip placement={"right"}
                                     title="An intuitive step by step guide to the creation">
                                <Button onClick={() => setStep(0)} color={"primary"} variant={"contained"}>
                                    Guided procedure
                                </Button>
                            </Tooltip>
                        </Grid>
                    </WizardIntroStep>

                    <WizardStep>
                        <WizardStepElement>
                            Which is the name and description of the event?
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={eventNameError.error}
                                helperText={eventNameError.text}
                                placeholder={"Event name"} variant={"outlined"} fullWidth label={""}
                                value={eventName} onChange={e => setEventName(e.target.value)}/>
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={descriptionError.error}
                                helperText={descriptionError.text}
                                placeholder={"Description"} multiline variant={"outlined"} fullWidth
                                label={""}
                                value={description} onChange={e => setDescription(e.target.value)}/>
                        </WizardStepElement>
                    </WizardStep>

                    <WizardStep>
                        <WizardStepElement>
                            Which short term actions have been done upon the event?
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={staError.error}
                                helperText={staError.text}
                                multiline placeholder={"Short term actions"}
                                variant={"outlined"} fullWidth
                                value={shortTermActions}
                                onChange={e => setShortTermActions(e.target.value)}/>
                        </WizardStepElement>
                    </WizardStep>

                    <WizardStep>
                        <WizardStepElement>
                            The component has been replaced?
                        </WizardStepElement>
                        <WizardStepElement>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch checked={componentReplaced}
                                            onChange={((event, checked) => setComponentReplaced(checked))}
                                            color="primary"/>
                                }
                                label="Component replaced"
                                labelPlacement="start"
                            />
                        </WizardStepElement>
                    </WizardStep>

                    <WizardStep>
                        <WizardStepElement>
                            How much time has been taken the repair/replace process
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField placeholder={"Eg. 1d 3h 20m"} value={timeToRepair}
                                       label="Time to repair" onChange={(event => setTimeToRepair(event.target.value))}
                                       variant="outlined" fullWidth/>
                            Seconds: {parseDuration(timeToRepair)}
                        </WizardStepElement>
                    </WizardStep>
                    <WizardStep>
                        <WizardStepElement>
                            Select the process related to the event
                        </WizardStepElement>
                        <WizardStepElement>
                            <Autocomplete
                                options={processes}
                                onChange={(event, c) => setProcess(c)}
                                getOptionLabel={(option) => option.name}
                                value={process}
                                renderInput={(params) =>
                                    <TextField
                                        // error={processError.error}
                                        // helperText={processError.text}
                                        placeholder={"Process"}
                                        {...params}
                                        fullWidth variant="outlined"/>
                                }
                            />
                        </WizardStepElement>
                    </WizardStep>
                    <WizardStep>
                        <WizardStepElement>
                            Select the type of event
                        </WizardStepElement>
                        <WizardStepElement>
                            <Autocomplete
                                options={failureModes}
                                onChange={(event, t) => {
                                    setTime(null)
                                    setFailureMode(t)
                                }}
                                getOptionLabel={(option) => option.name}
                                value={failureMode}
                                renderInput={(params) =>
                                    <TextField
                                        // error={failureModeError.error}
                                        // helperText={failureModeError.text}
                                        placeholder={"Failure type"}
                                        {...params}
                                        fullWidth variant="outlined"/>
                                }
                            />
                        </WizardStepElement>
                    </WizardStep>
                    <WizardStep>
                        <WizardStepElement>
                            When the failure has occurred?
                        </WizardStepElement>
                        <WizardStepElement>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    disableFuture
                                    minDate={failureMode ? new Date(failureMode.componentInstallationDate * 1000) : undefined}
                                    inputVariant="outlined"
                                    error={timeError.error}
                                    placeholder={"Event time"}
                                    helperText={timeError.text}
                                    ampm={false} fullWidth format="dd/MM/yyyy hh:mm:ss"
                                    value={time} onChange={setTime}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </WizardStepElement>
                    </WizardStep>
                    <WizardEndStep>
                        Please verify that information provided are correct and click on Save to create the new failure
                        event.
                    </WizardEndStep>
                </Wizard>

            </DialogContent>
        </Dialog>
    )
}