import React, {useContext, useState} from 'react';
import {AppContext} from "../../App";
import Grid from "@material-ui/core/Grid";
import {fetchUserSystemsPage,} from "../../utils/apiCaller";

import {useHistory} from "react-router-dom";

import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import AdvancedTable from "../../components/AdvancedTable";
import Box from "@material-ui/core/Box";
import AddButton from "../../components/AddButton";
import SystemSelectionDialog from "../../dialogs/system/SystemSelectionDialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export default function SystemScreen() {

    const history = useHistory();
    const [state] = useContext(AppContext);
    const [searchFilter, setSearchFilter] = useState("");
    const [searchFilterValue, setSearchFilterValue] = useState("");
    const [snackOpen, setSnackOpen] = useState(false);

    const [refreshTable, setRefreshTable] = useState(false);
    const [systemSelectionDialogOpen, setSystemSelectionDialogOpen] = useState(false);

    const systemTableColumns = [
        {key: "name", name: "Name"},
        {key: "type", name: "Type"},
    ]

    const handleKeyPress = (event) => {
        if (event.keyCode === 13)
            setSearchFilter(searchFilterValue)
    }

    const handleNewSystem = () =>{
        setRefreshTable(true)
        setSystemSelectionDialogOpen(false)
        setSnackOpen(true)
    }
    return (
        <Grid container spacing={3}

              alignItems="flex-end"
              justify="flex-end"
              style={{
                  margin: 0,
                  width: '100%',
              }}>
            <Grid item xs={6} style={{textAlign: "left"}}>
                <Box ml={3} mb={1}>
                    <SystemSelectionDialog
                        onClose={() => setSystemSelectionDialogOpen(false)}
                        onSelected={handleNewSystem}
                        creationOnly
                        open={systemSelectionDialogOpen}
                    />
                    <AddButton onClick={() => setSystemSelectionDialogOpen(true)}>
                        System
                    </AddButton>
                </Box>
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}}>
                <FormControl variant={"outlined"}>
                    <OutlinedInput
                        placeholder={"Search"}
                        onKeyUp={handleKeyPress}
                        onChange={(event => setSearchFilterValue(event.target.value))}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton edge="end"
                                            onClick={() => setSearchFilter(searchFilterValue)}>
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <AdvancedTable
                    jwt={state.user.jwt}
                    fields={systemTableColumns}
                    mustRefresh={refreshTable}
                    onRefreshed={() => setRefreshTable(false)}
                    filterName={searchFilter}
                    fetchingPageFunction={fetchUserSystemsPage}
                    onSelected={(system) => history.push("/system/" + system.id)}
                />

            </Grid>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </Grid>

    );
}
