import React, {useContext, useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import {useHistory, useParams} from "react-router-dom";
import {fetchDistributionDetail, postDistributionUpdate,} from "../../../../utils/apiCaller";
import {AppContext} from "../../../../App";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailTitle from "../../../../components/DetailTitle";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import GridItemTitle from "../../../../components/GridItemTitle";
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Typography} from "@material-ui/core";


export default function DistributionDetailScreen() {


    const [state] = useContext(AppContext);
    const [snackOpen, setSnackOpen] = useState(false);
    const [distribution, setDistribution] = useState({loading: true});

    // campi per la form sotto
    const [name, setName] = useState(null);
    const [serviceUrl, setServiceUrl] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    const {distId} = useParams();
    const history = useHistory();


    useEffect(() => {
        fetchDistributionDetail(state.user.jwt, distId)
            .then(result => setDistribution(result))
            .catch(alert)

    }, [state.user.jwt, distId])

    useEffect(() => {
        if (!distribution.loading)
            initForm(distribution)
    }, [distribution])

    const initForm = (dto) => {
        setName(dto.name)
        setServiceUrl(dto.serviceUrl)
        setIsDisabled(dto.disabled)

    }

    const handleBasicSave = () => {
        let payload = {
            id: distId,
            name: name,
            serviceUrl: serviceUrl,
            disabled: isDisabled
        }

        postDistributionUpdate(state.user.jwt, payload)
            .then(() => {
                setSnackOpen(true)
            })
            .catch(alert)
    }

    return (
        <Box p={2} className={"content"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={() => history.push("/admin/distribs")}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant={"button"} color={"primary"}>
                                <u>Distribution</u>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <DetailTitle>
                        Distribution information
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} ml={5}>
                        <Grid container spacing={3}>
                            <GridItemTitle>
                                Name
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={name || ''} onChange={e => setName(e.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Service URL
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={serviceUrl || ''} onChange={e => setServiceUrl(e.target.value)}
                                           fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Disabled
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <Switch
                                    checked={isDisabled}
                                    onChange={(event, checked) => setIsDisabled(checked)}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={6} style={{textAlign: "right"}}>
                                <Button onClick={handleBasicSave} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                          }}>
                    <Alert severity="success"> Saved successfully </Alert>
                </Snackbar>
            </Grid>
        </Box>

    )
}
