import React from 'react';
import {Route, Switch} from "react-router-dom";
import ProcessDetailScreen from "./ProcessDetailScreen";
import Processes from "./Processes";


export default function ProcessRouter() {

    return (
        <Switch>
            <Route exact path="/process/:processId" component={ProcessDetailScreen}/>
            <Route path="/process" component={Processes}/>
        </Switch>
    );
}
