import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import './Style.css'
import Button from "@material-ui/core/Button";

import DateFnsUtils from '@date-io/date-fns';
import {DateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {fetchSystemComponentsPage, fetchSystemDetail, postSystemUpdate, putNewComponent} from "../../utils/apiCaller";
import {AppContext} from "../../App";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import DetailTitle from "../../components/DetailTitle";
import Box from "@material-ui/core/Box";
import GridItemTitle from "../../components/GridItemTitle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Typography} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import GridItemColumn from "../../components/GridItemColumn";
import RatDropDown from "../../components/RatDropDown";
import AddButton from "../../components/AddButton";
import ComponentCreationDialog from "../../dialogs/component/ComponentCreationDialog";
import AdvancedTable from "../../components/AdvancedTable";


export default function SystemDetailScreen() {

    const [state] = useContext(AppContext);

    const history = useHistory();
    const [snackOpen, setSnackOpen] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);

    const {systemId} = useParams();


    const [selectedDate, handleDateChange] = useState(null);
    const [systemName, setSystemName] = useState("");
    const [systemType, setSystemType] = useState("");
    const [systemMaint, setSystemMaint] = useState(0);
    const [systemRepla, setSystemRepla] = useState(0);
    const [systemRele, setSystemRele] = useState(0);
    const [criticality, setCriticality] = useState(null);

    const [newComponentDialogOpen, setNewComponentDialogOpen] = useState(false);

    // carico tutte le info del sistema in modo da popolare i campi
    useEffect(() => {
        // carico tutti i componenti del sistema
        fetchSystemDetail(state.user.jwt, systemId)
            .then(initForm)
            .catch(alert)
    }, [state.user.jwt, systemId])

    const componentsTableFields = [
        {key: "name", name: "Name"},
        {key: "type", name: "Type"},
        {key: "repairable", name: "Repairable", boolean: true},
        {key: "installationTimeString", name: "Installation time"},

    ]

    const initForm = (dto) => {
        setSystemName(dto.name)
        setSystemType(dto.type)
        setSystemRepla(dto.replaceability)
        setSystemMaint(dto.maintainability)
        setSystemRele(dto.relevance)
        setCriticality(dto.criticality)

        if (dto.installationTime)
            handleDateChange(new Date(dto.installationTime * 1000))
    }

    const handleSubmit = (event) => {
        event.preventDefault() // evito il comportamento standard di un submit in una form (refresh)

        // costruisco un oggetto contenente i dati della form
        const payload = {
            id: systemId,
            name: systemName,
            type: systemType,
            replaceability: systemRepla,
            maintainability: systemMaint,
            relevance: systemRele,
            installationTime: selectedDate.getTime() / 1000

        }


        postSystemUpdate(state.user.jwt, payload)
            .then((system) => {
                setCriticality(system.criticality)
                setSnackOpen(true)
            })
            .catch(alert)
            .catch(error => alert(error))

    }


    const handleNewComponent = (component) => {

        // assegno l'id del sistema
        component.systemId = systemId

        // lo salvo e ricarico la lista dei componenti
        putNewComponent(state.user.jwt, component)
            .then(() => {
                setSnackOpen(true)
                setRefreshTable(true)
                setNewComponentDialogOpen(false)
            })
            .catch(console.log)

    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}

                  alignItems="flex-end"
                  justify="flex-end"
                  style={{
                      margin: 0,
                      width: '100%',
                  }}>

                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={() => history.push("/system")}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant={"button"} color={"primary"}>
                                <u>System</u>
                            </Typography>
                        </Grid>

                        {criticality !== null && <Grid item xs={8} style={{textAlign: "right"}}>
                            <Typography variant={"h5"} color={"primary"}>
                                Criticality:
                                <b> {criticality}</b>
                            </Typography>

                        </Grid>
                        }

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle>
                        General information
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <GridItemColumn label={"Name"}>
                                <TextField name={"name"}
                                           value={systemName || ""}
                                           onChange={event => setSystemName(event.target.value)}
                                           fullWidth label=""/>
                            </GridItemColumn>
                            <GridItemColumn spacing label={"Relevance"}>
                                <RatDropDown
                                    variant={"standard"}
                                    values={["Irrelevant", "Not essential", "Relevant", "Fundamental"]}
                                    value={systemRele}
                                    onChange={(e, value) => setSystemRele(value)}
                                />
                            </GridItemColumn>
                            <GridItemColumn label={"Type"}>
                                <TextField name={"type"} value={systemType || ""}
                                           onChange={event => setSystemType(event.target.value)}
                                           fullWidth label=""/>
                            </GridItemColumn>
                            <GridItemColumn spacing label={"Replaceability"}>
                                <RatDropDown
                                    variant={"standard"}
                                    values={["Easily replaceable/redundant",
                                        "Replaceable with a small expenditure of resources",
                                        "Replaceable with a high expenditure of resources",
                                        "Irreplaceable"]}
                                    value={systemRepla}
                                    onChange={(e, value) => setSystemRepla(value)}
                                />
                            </GridItemColumn>
                            <GridItemColumn label={"Installation time"}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker required
                                                    name="installationTime"
                                                    fullWidth
                                                    format="dd/MM/yyyy HH:mm:ss"
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    ampm={false}
                                    />
                                </MuiPickersUtilsProvider>
                            </GridItemColumn>
                            <GridItemColumn spacing label={"Maintainability"}>
                                <RatDropDown
                                    variant={"standard"}
                                    values={["Excellent maintainability (not relevant costs and MTTR)",
                                        "High maintainability (not relevant costs or not relevant MTTR)",
                                        "Medium maintainability (high costs or high MTTR)",
                                        "Low maintainability (high costs and high MTTR)"]}
                                    value={systemMaint}
                                    onChange={(e, value) => setSystemMaint(value)}
                                />
                            </GridItemColumn>
                            <Grid item xs={9}/>
                            <GridItemTitle>
                                <Button type={"submit"} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </GridItemTitle>
                        </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/page/systems-components"}
                    >
                        Components
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box ml={5}>
                        <AdvancedTable
                            style={{padding: 8}}
                            jwt={state.user.jwt}
                            filterName={""}
                            fields={componentsTableFields}
                            fetchingPageFunction={fetchSystemComponentsPage}
                            onSelected={(component) => history.push("/system/" + systemId + "/c/" + component.id)}
                            mustRefresh={refreshTable}
                            onRefreshed={() => setRefreshTable(false)}
                            requestArgs={{systemId: systemId}}
                        />

                        {selectedDate && <ComponentCreationDialog
                            systemInstallationDate={selectedDate}
                            onSave={handleNewComponent}
                            open={newComponentDialogOpen}
                            onClose={() => setNewComponentDialogOpen(false)}/>
                        }

                        <AddButton onClick={() => setNewComponentDialogOpen(true)}>
                            Component
                        </AddButton>
                    </Box>
                </Grid>

            </Grid>
            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </form>

    );
}
