import {ReclaimNode} from "../ReclaimNode";


export class ComponentNode extends ReclaimNode {
    constructor(serverNode) {
        super({
            type: 'component-node'
        }, serverNode);

        this.name = serverNode.componentName
    }

    serialize() {
        return {
            ...super.serialize(),
            type: 'component-node'
        };
    }
}
