export const checkToken = jwt => {
    const date = new Date();

    // mi accerto che non sia nullo
    if(jwt == null)
        return false

    // controllo che non sia scaduto
    if(jwt.hasOwnProperty("expire") && jwt.expire<date.getTime()/1000) {
        return false
    }

    // se tutti i controlli passano
    return true

}

export function objToCsv(json){
    const fields = Object.keys(json[0])
    const replacer = function(key, value) { return value === null ? '' : value }
    const csv = json.map(function(row){
        return fields.map(function(fieldName){
            return JSON.stringify(row[fieldName], replacer)
        }).join(',')
    })
    csv.unshift(fields.join(','))
    return csv.join('\r\n')
}

// https://stackoverflow.com/questions/14743118/how-can-i-get-a-user-to-input-a-duration-into-an-html5-web-form-without-annoying

export function to_seconds(dd,hh,mm) {
    let d = parseInt(dd);
    let h = parseInt(hh);
    let m = parseInt(mm);
    if (isNaN(d)) d = 0;
    if (isNaN(h)) h = 0;
    if (isNaN(m)) m = 0;

    return d * 24 * 60 * 60 +
        h * 60 * 60 +
        m * 60;
}

// expects 1d 11h 11m, or 1d 11h,
// or 11h 11m, or 11h, or 11m, or 1d
// returns a number of seconds.
export function parseDuration(sDuration) {
    if (sDuration == null || sDuration === '') return 0;
    let srx = new RegExp(/([0-9]+)[ ]?s/);
    let mrx = new RegExp(/([0-9]+)[ ]?m/);
    let hrx = new RegExp(/([0-9]+)[ ]?h/);
    let drx = new RegExp(/([0-9]+)[ ]?d/);
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (srx.test(sDuration)) {
        seconds = srx.exec(sDuration)[1];
    }
    if (mrx.test(sDuration)) {
        minutes = mrx.exec(sDuration)[1];
    }
    if (hrx.test(sDuration)) {
        hours = hrx.exec(sDuration)[1];
    }
    if (drx.test(sDuration)) {
        days = drx.exec(sDuration)[1];
    }

    return to_seconds(days, hours, minutes) + parseInt(seconds);
}