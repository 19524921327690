import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {fetchUsersPage} from "../../../utils/apiCaller";
import {AppContext} from "../../../App";
import SearchIcon from '@material-ui/icons/Search';
import AdvancedTable from "../../../components/AdvancedTable";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useHistory} from "react-router-dom";


export default function UserAdminScreen() {

    const history = useHistory();
    const [state] = useContext(AppContext);
    const [searchFilter, setSearchFilter] = useState("");
    const [searchFilterValue, setSearchFilterValue] = useState("");


    const usersTableFields = [
        {key: "email", name: "Email"},
        {key: "name", name: "Name"},
        {key: "surname", name: "Surname"},
        {key: "isAdmin", name: "Administrator", boolean:true},
    ]

    const handleKeyPress = (event) =>{
        if(event.keyCode === 13)
            setSearchFilter(searchFilterValue)
    }

    return (

            <Grid container spacing={3}

                  alignItems="flex-end"
                  justify="flex-end"
                  style={{
                      margin: 0,
                      width: '100%',
                  }}>
                <Grid item xs={12} style={{textAlign:"right"}}>
                    <FormControl variant={"outlined"}>
                        <OutlinedInput
                            placeholder={"Search"}
                            onKeyUp={handleKeyPress}
                            onChange={(event => setSearchFilterValue(event.target.value))}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton edge="end"
                                        onClick={() => setSearchFilter(searchFilterValue)}>
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <AdvancedTable
                    jwt={state.user.jwt}
                    fields={usersTableFields}
                    filterName={searchFilter}
                    fetchingPageFunction={fetchUsersPage}
                    onSelected={(user) => history.push("/admin/u/" + user.id)}
                    />

                </Grid>
            </Grid>

    );
}
