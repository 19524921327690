import React from 'react';
import Grid from "@material-ui/core/Grid";


export default function GridItemTitle({children, ...props}) {

    return (

        <Grid  {...props} item xs={2} style={{textAlign: "right", paddingTop: 16}}>
            {children}
        </Grid>

    );
}
