import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../App";
import Grid from "@material-ui/core/Grid";
import {
    addDummy,
    fetchAllSystemComponents,
    fetchAllSystemDiagramNodes,
    performSimulation,
    putComponentToDiagram,
    putNewComponent,
    updateAllSystemDiagramNodes
} from "../../utils/apiCaller";
import CompListItem from "../../components/CompListItem";
import List from "@material-ui/core/List";
import './Style.css'
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ListSubheader from "@material-ui/core/ListSubheader";
import RatDiagram from "../../components/RatDiagram";
import {useHistory, useParams} from "react-router-dom";
import ComponentCreationDialog from "../../dialogs/component/ComponentCreationDialog";
import {Divider} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import html2canvas from "html2canvas";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import {parseDuration} from "../../utils/utils";
import HelpIcon from '@material-ui/icons/Help';
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

export default function RBDScreen({location}) {

    const [state] = useContext(AppContext);
    const [snackOpen, setSnackOpen] = useState(false);
    const [showNowSelection, setShowNowSelection] = useState(false);
    const [customNowDate, setCustomNowDate] = useState(null);


    const [diagramResetToggle, setDiagramResetToggle] = useState(false);
    const [components, setComponents] = useState([]);
    const [diagramNodes, setDiagramNodes] = useState([]);
    const [newComponentDialogOpen, setNewComponentDialogOpen] = useState(false);
    const [simulationStartDialog, setSimulationStartDialog] = useState(false);
    const [simulationImage, setSimulationImage] = useState("");
    const [simulationDuration, setSimulationDuration] = useState("");
    const [selectedComponentId, setSelectedComponentId] = useState(undefined);

    const history = useHistory();

    const {systemId} = useParams();

    if (state.system && !systemId)
        history.push("/rbd/" + state.system.id)

    useEffect(() => {
        if (systemId) {
            setDiagramResetToggle(true)
            // carico tutti i componenti del sistema
            fetchAllSystemComponents(state.user.jwt, systemId)
                .then(components => setComponents(components))
                .catch(console.log)
            // carico i nodi del diagramma
            fetchAllSystemDiagramNodes(state.user.jwt, systemId)
                .then(nodes => setDiagramNodes(nodes))
                .catch(alert)
        }

    }, [state.user.jwt, systemId])

    const handleDiagramUpdate = (diagram, sysId, sizeDiff) => {
        // mi assicuro che ci sia il sistema
        if (sysId)
            updateAllSystemDiagramNodes(state.user.jwt, sysId, diagram)
                .then(() => {
                    setSnackOpen(true)
                    // se c'è una differenza in nel numero dei nodi allora aggiorno anche la lista dei componenti
                    if (sizeDiff)
                        fetchAllSystemComponents(state.user.jwt, sysId)
                            .then(components => setComponents(components))
                            .catch(console.log)
                })
        else {
            console.log("update failed, no system selected")
        }
    }

    const handleAddDummy = () => {
        addDummy(state.user.jwt, systemId)
            .then(result => {
                fetchAllSystemComponents(state.user.jwt, systemId)
                    .then(components => setComponents(components))
                    .catch(console.log)
                setDiagramNodes(result)
            })
    }

    const handleSimulationStartRequest = () => {

        // faccio lo screen del diagramma
        html2canvas(document.querySelector(".diagramContainer")).then(canvas => {
            const image = canvas.toDataURL('image/png', 0.5)
            setSimulationImage(image)
            setSimulationStartDialog(true)
        });

    }

    const handleShowNowSelection = (e) => {
        e.preventDefault();
        setShowNowSelection(prevState => !prevState)
    }

    const handleSimulationStart = () => {
        let duration = parseDuration(simulationDuration)
        let customNow = customNowDate ? customNowDate.getTime() : "";
        performSimulation(state.user.jwt, systemId, duration, simulationImage, customNow)
            .then(result => {
                history.push("/rbd/" + systemId + "/history/" + result.id)
            })
            .catch((result) => alert(result.message))
    }

    const handleDiagramAdd = (component) => {
        putComponentToDiagram(state.user.jwt, component.id)
            .then(result => {
                fetchAllSystemComponents(state.user.jwt, systemId)
                    .then(components => setComponents(components))
                    .catch(console.log)
                setDiagramNodes(result)
            })
    }

    // creo un nuovo componente, poi questo sarà sicuramente da abbellire e magari chiedere qualche parametro in più
    const handleNewComponent = (component) => {
        if (systemId) {
            // assegno l'id del sistema
            component.systemId = systemId

            // lo salvo e ricarico la lista dei componenti
            putNewComponent(state.user.jwt, component)
                .then(() => {
                    fetchAllSystemComponents(state.user.jwt, systemId)
                        .then(components => setComponents(components))
                        .catch(alert)
                    setSnackOpen(true)
                    setNewComponentDialogOpen(false)
                })
                .catch(console.log)
        }
    }


    return (
        <div className={"content"}>

            <Grid container style={{height: "100%"}}>
                <Grid item xs={9} className={"diagramContainer"}>
                    <RatDiagram
                        onComponentSelected={setSelectedComponentId}
                        diagramNodes={diagramNodes}
                        resetToggle={diagramResetToggle}
                        onReset={() => {
                            setDiagramResetToggle(false)
                        }}
                        onUpdate={handleDiagramUpdate}/>

                    <Grid data-html2canvas-ignore container className={"fab-container"}>
                        <Grid item xs={9} style={{textAlign: "right", paddingRight: 16, paddingBottom: 16}}>
                            <a href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/page/introduction-to-rbd-analysis"}
                               target={"_blank"} rel="noopener noreferrer">
                                <Fab size={"small"} style={{marginRight: 8}} onClick={handleAddDummy}>
                                    <HelpIcon/>
                                </Fab>
                            </a>
                        </Grid>
                        <Grid item xs={9} style={{textAlign: "right", paddingRight: 16}}>
                            <Fab data-html2canvas-ignore color={"primary"}
                                 onClick={() => handleSimulationStartRequest()}>
                                <PlayArrowIcon/>
                            </Fab>
                        </Grid>
                    </Grid>
                    <Dialog

                        open={simulationStartDialog}
                        onClose={() => setSimulationStartDialog(false)}>
                        <DialogTitle id="form-dialog-title">
                            Start simulation
                            <IconButton aria-label="close" style={{
                                position: "absolute",
                                top: 8, right: 8,
                            }}
                                        onContextMenu={handleShowNowSelection}
                                        onClick={() => setSimulationStartDialog(false)}>
                                <CloseIcon/>
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box pb={2}>
                                Please specify the mission duration, if not provided it will be set to 0 seconds.
                            </Box>
                            <TextField placeholder={"Eg. 1d 3h 20m"} value={simulationDuration}
                                       label="Duration" onChange={(event => setSimulationDuration(event.target.value))}
                                       variant="outlined" fullWidth/>
                            Hours: {Math.round((parseDuration(simulationDuration) / 3600) * 100) / 100}

                            {showNowSelection && <Box mt={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker ampm={false}
                                                    label="Computation 'now' date and time"
                                                    inputVariant={"outlined"}
                                                    fullWidth format="dd/MM/yyyy hh:mm:ss"
                                                    value={customNowDate} onChange={setCustomNowDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>}
                        </DialogContent>
                        <DialogActions>
                            <Box mb={2} mr={2}>
                                <Button onClick={handleSimulationStart} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </Box>
                        </DialogActions>
                    </Dialog>
                </Grid>
                <Grid item xs={3} className={"componentsContainer"}>
                    <List
                        subheader={
                            <ListSubheader component="div">
                                Components
                            </ListSubheader>
                        }
                    >
                        {components.map(c =>
                            <CompListItem
                                selected={c.id === selectedComponentId}
                                key={c.id}
                                component={c}
                                onDiagramAdd={handleDiagramAdd}
                                onSelected={() => history.push("/system/" + systemId + "/c/" + c.id + "?from=" + encodeURI(location.pathname))}
                            />
                        )}
                        <Divider/>
                    </List>
                    {state.system && <Box>
                        <Fab variant="extended" className={"fab"}
                             onClick={() => setNewComponentDialogOpen(true)}>
                            <AddIcon/>
                            Component
                        </Fab>
                    </Box>}
                </Grid>

                <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                          }}>
                    <Alert severity="success"> Saved successfully </Alert>
                </Snackbar>

                {state.system && <ComponentCreationDialog
                    systemInstallationDate={new Date(state.system.installationTime * 1000)}
                    onSave={handleNewComponent}
                    open={newComponentDialogOpen}
                    onClose={() => setNewComponentDialogOpen(false)}/>
                }
            </Grid>
        </div>
    );
}
