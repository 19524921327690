import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Divider} from "@material-ui/core";
import Box from "@material-ui/core/Box";


export default function CompListItem({component, onSelected, onDiagramAdd, selected}) {

    return (
        <>
            <Divider/>
            <ListItem button onClick={onSelected} selected={selected}
                      draggable>
                <ListItemIcon>
                    <SettingsInputComponentIcon/>
                </ListItemIcon>
                <ListItemText
                    primary={component.name}
                    secondary={component.type}
                />
                <ListItemSecondaryAction>
                    <Box hidden={component.isInDiagram}>
                    <IconButton
                                edge="end"
                                aria-label="Add to diagram"
                                onClick={() => onDiagramAdd(component)}
                    >
                        <AddIcon/>
                    </IconButton>
                    </Box>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
}
