import React from 'react';
import {Route, Switch} from "react-router-dom";
import RBDScreen from "./RBDScreen";
import HistoryScreen from "./HistoryScreen";
import HistoryDetailScreen from "./HistoryDetailScreen";


export default function RBDRouter() {

    return (
        <Switch>
            <Route exact path="/rbd" component={RBDScreen}/>
            <Route exact path="/rbd/:systemId" component={RBDScreen}/>
            <Route exact path="/rbd/:systemId/history" component={HistoryScreen}/>
            <Route exact path="/rbd/:systemId/history/:simId" component={HistoryDetailScreen}/>
        </Switch>
    );
}
