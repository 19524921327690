import React from 'react';

import {Route, Switch} from "react-router-dom";
import Failures from "./Failures";
import FailureDetailScreen from "./FailureDetailScreen";


export default function ComponentRouter() {
    return (
        <Switch>
            <Route exact path="/failure/:failId" component={FailureDetailScreen}/>
            <Route exact path="/failure" component={Failures}/>
        </Switch>
    );
}
