import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {postPasswordReset} from "../../utils/apiCaller";
import {AppContext} from "../../App";
import {checkToken} from "../../utils/utils";
import {NavLink, Redirect, useHistory} from "react-router-dom";
import {parse} from "qs";

import {Card, CardContent, CardHeader} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const validatePassword = (password) => {
    return /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(password);
}

export default function PasswordRecoveryScreen({location}) {

    const [state] = useContext(AppContext);

    const history = useHistory()

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState({error: false, text: ""});
    const [rePassword, setRePassword] = useState("");
    const [rePasswordError, setRePasswordError] = useState({error: false, text: ""});

    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const [alert, setAlert] = useState();

    const token = parse(location.search, {ignoreQueryPrefix: true}).token

    const handleReset = () => {

        let hasError = false;

        if (!password) {
            setPasswordError({error: true, text: "Password should not be empty"})
            hasError = true
        } else if (!validatePassword(password)) {
            setPasswordError({error: true, text: "The password does not satisfy the requirements"})
            hasError = true
        } else if (password !== rePassword) {
            setPasswordError({error: true, text: "Passwords are different"})
            setRePasswordError({error: true, text: "Passwords are different"})
            hasError = true
        }
        if (!rePassword) {
            setRePasswordError({error: true, text: "Please retype the password"})
            hasError = true
        }

        if (!hasError) {
            let payload = {
                token: token,
                password: password
            }

            setPasswordError({error: false, text: ""})
            setRePasswordError({error: false, text: ""})
            setLoading(true)
            postPasswordReset(payload)
                .then((user) => {
                        setAlert({message: "Password resetted", severity: "success"})
                        setDone(true)
                        setLoading(false)
                        setTimeout(() =>{history.push("/login?user=" + encodeURI(user.email))}, 3000)
                    }
                )
                .catch((error) => {
                    setLoading(false)
                    setAlert({message: error.message, severity: "error"})
                })

        }
    }

    // se autenticato allora rimando alla home
    if (state.user && checkToken(state.user.jwt))
        return <Redirect to={"/"}/>
    else if (!token)
        return <Redirect to={"/"}/>
    return (
        <Grid
            style={{
                position: "absolute",
                top: "0",
                bottom: "0"
            }}
            container
            justify="center"
            alignItems="center"
            spacing={0}
        >
            <Grid item xs={6}>
                <Card>
                    <CardHeader title={"Reset password"}/>
                    <CardContent>
                        <Grid container
                              justify="center"
                              spacing={2}
                              alignItems="center">
                            <Grid item xs={12}>
                                Choose a secure password password between 7 to 15 characters
                                which contain at least one numeric digit and a special character
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={loading || done}
                                    type={"password"}
                                    error={passwordError.error}
                                    helperText={passwordError.text}
                                    placeholder={"Type the new password"} variant={"outlined"} fullWidth label={""}
                                    value={password} onChange={e => setPassword(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type={"password"}
                                    disabled={loading || done}
                                    error={rePasswordError.error}
                                    helperText={rePasswordError.text}
                                    placeholder={"Retype password"} variant={"outlined"} fullWidth label={""}
                                    value={rePassword} onChange={e => setRePassword(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "right"}}>
                                <Button
                                    disabled={loading || done}
                                    onClick={handleReset} variant={"contained"} color={"primary"}>
                                    {loading && <CircularProgress size={25} style={{marginRight: 16}}/>}
                                    Reset password
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Grid container justify={"space-between"}>
                    <Grid item>
                        <Typography variant="body1" color={"primary"}>
                            SUPSI - Reliability Analysis Tool
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link to={"/login"} component={NavLink}>
                            Back to login
                        </Link>
                    </Grid>

                </Grid>
            </Grid>

            <Snackbar open={!!alert}
                      autoHideDuration={5000}
                      onClose={() => {
                          setAlert(null)
                      }}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                {alert && <Alert severity={alert.severity ? alert.severity : ""}>
                    <Grid container>
                        <Grid item xs={state.alert.autoHideDuration === null ? 11 : 12}>
                            {alert.message}
                        </Grid>

                    </Grid>

                </Alert>}
            </Snackbar>

        </Grid>

    );
}
