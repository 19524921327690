import {DefaultPortModel} from "@projectstorm/react-diagrams";

export class ReclaimPortModel extends DefaultPortModel {
    constructor(options = {}) {
        super({
            ...options,
        });
    }


    removeLink(link) {
        super.removeLink(link);
        this.fireEvent(this.links, "linksChanged")
    }


    reportPosition() {
        super.reportPosition();
        this.fireEvent(this, "linksChanged")
    }

    canLinkToPort(port) {
        if(this.options.in)
            return false;

        if (port instanceof DefaultPortModel) {
            return port.getOptions().in;
        }

        return true;
        // return super.canLinkToPort(port);
    }


    isLocked() {
        return this.options.in;
    }
}