import React from 'react';

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {Divider, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";


export default function DetailTitle({
                                        variant = "h5",
                                        children,
                                        tooltip,
                                        href,
                                        ...props
                                    }) {


    const handleClick = () => {
        if (href)
            window.open(href, '_blank').focus();
    }

    return (
        <>
            <Box mt={2} pb={!tooltip ? 1.5 : 0}>
                <Typography variant={variant} {...props} >
                    {children}
                    <Tooltip placement={"right"} title={!tooltip? (href? "Open help page in new tab" : "") : tooltip}>
                        <IconButton onClick={handleClick} style={{display: (!tooltip && !href) ? "none" : ""}} color="primary"
                                    component="span">
                            <HelpOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Box>

            <Divider/>
        </>
    );
}
