import React, {useContext, useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import {useHistory, useParams} from "react-router-dom";
import {fetchUserIdDetail, fetchUserSpecificSystemsPage, postUserUpdate} from "../../../../utils/apiCaller";
import {AppContext} from "../../../../App";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailTitle from "../../../../components/DetailTitle";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import GridItemTitle from "../../../../components/GridItemTitle";
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AdvancedTable from "../../../../components/AdvancedTable";
import {Typography} from "@material-ui/core";


export default function UserDetailScreen() {


    const [state] = useContext(AppContext);
    const [snackOpen, setSnackOpen] = useState(false);
    const [user, setUser] = useState({loading: true});
    // campi per la form sotto
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [email, setEmail] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const {userId} = useParams();
    const history = useHistory();


    useEffect(() => {
        // carico tutti i componenti del sistema
        fetchUserIdDetail(state.user.jwt, userId)
            .then(result => setUser(result))
            .catch(alert)

    }, [state.user.jwt, userId])

    useEffect(() => {
        if (!user.loading)
            initForm(user)
    }, [user])

    const initForm = (dto) => {
        setName(dto.name)
        setSurname(dto.surname)
        setEmail(dto.email)
        setIsAdmin(dto.isAdmin)

    }

    const handleBasicSave = () => {
        let payload = {
            id: userId,
            name: name,
            surname: surname,
            email: email,
            isAdmin: isAdmin
        }

        postUserUpdate(state.user.jwt, payload)
            .then(() => {
                setSnackOpen(true)
            })
            .catch(alert)
    }

    const tableFields = [
        {key: "name", name: "Name"},
        {key: "type", name: "Type"},
    ]


    return (
        <Box p={2} className={"content"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={() => history.push("/admin")}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant={"button"} color={"primary"}>
                                <u>User</u>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <DetailTitle>
                        User information
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} ml={5}>
                        <Grid container spacing={3}>
                            <GridItemTitle>
                                Name
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={name || ''} onChange={e => setName(e.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Surname
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={surname || ''} onChange={e => setSurname(e.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Email
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={email || ''} onChange={e => setEmail(e.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Administrator rights
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <Switch
                                    checked={isAdmin}
                                    onChange={(event, checked) => setIsAdmin(checked)}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={11} style={{textAlign: "right"}}>
                                <Button onClick={handleBasicSave} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle>
                        User systems
                    </DetailTitle>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={5}>
                        <AdvancedTable
                            jwt={state.user.jwt}
                            filterName={""}
                            fetchingPageFunction={fetchUserSpecificSystemsPage}
                            requestArgs={{
                                userId: userId
                            }}
                            onSelected={() => {
                            }}
                            fields={tableFields}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </Box>

    )
}
