import React, {useContext, useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {
    fetchAllDistributionParams,
    fetchAllDistributions,
    fetchComponentDetail,
    fetchComponentFailureModePage,
    fetchFailureEventPage, fetchSuggestedDistribution,
    postComponentUpdate
} from "../../../../utils/apiCaller";
import {AppContext} from "../../../../App";
import {parse} from "qs";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailTitle from "../../../../components/DetailTitle";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import GridItemTitle from "../../../../components/GridItemTitle";
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AdvancedTable from "../../../../components/AdvancedTable";
import {IconButton, Tooltip, Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GridItemColumn from "../../../../components/GridItemColumn";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';


export default function ComponentDetailScreen({location}) {


    const [state, dispatch] = useContext(AppContext);
    const [snackOpen, setSnackOpen] = useState(false);
    const [component, setComponent] = useState({loading: true});

    // campi per la distribuzione e i parametri
    const [distributions, setDistributions] = useState([]);
    const [distributionRequiredParams, setDistributionRequiredParams] = useState([]);
    const [distribution, setDistribution] = useState(null);
    const [componentDistParams, setComponentDistParams] = useState([]);
    const [useVendorParams, setUseVendorParams] = useState(null);


    // campi per la form sotto
    const [selectedDate, handleDateChange] = useState(null);
    const [name, setName] = useState(null);
    const [type, setType] = useState(null);
    const [reliability, setReliability] = useState(null);
    const [repairable, setRepairable] = useState(false);

    // campi per la categoria failure types
    const [refreshTable, setRefreshTable] = useState(false);

    const {compId, systemId} = useParams();
    const history = useHistory();

    const from = parse(location.search, {ignoreQueryPrefix: true}).from

    useEffect(() => {
        // carico tutte le distribuzioni
        fetchAllDistributions(state.user.jwt)
            .then(distributions => {
                // ora carico il componente
                fetchComponentDetail(state.user.jwt, compId)
                    .then(component => {
                        setComponent(component)
                        setDistributions(distributions);

                        setDistribution(distributions.find(o => o.id === component.distributionId) || null)
                        setComponentDistParams(component.distParams)

                    })
                    .catch(error => console.log(error.message))
            })
            .catch(error => console.log(error.message))

    }, [state.user.jwt, compId])

    const handleHintFunction = () => {
        fetchSuggestedDistribution(state.user.jwt, compId)
            .then(result => {
                dispatch({type: "SHOW_ALERT", payload: {message: "Distribution selected: " + result.name}})
                setDistribution(distributions.find(d=> d.id === result.id))
            })
            .catch((error) =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }

    useEffect(() => {
        if (!!distribution)
            // carico tutti i componenti del sistema
            fetchAllDistributionParams(state.user.jwt, distribution.id)
                .then(params => setDistributionRequiredParams(params))
                .catch(error => alert(error.message))
        else
            setDistributionRequiredParams([])
    }, [state.user.jwt, distribution])

    useEffect(() => {
        if (!component.loading)
            initForm(component)
    }, [component])

    const initForm = (dto) => {
        setName(dto.name)
        setType(dto.type)
        setReliability(dto.tempReliability)
        setRepairable(dto.repairable)
        setUseVendorParams(dto.useVendorParams)

        if (dto.installationTime)
            handleDateChange(new Date(dto.installationTime * 1000))
    }

    const handleBasicSave = () => {
        let payload = {
            id: compId,
            name: name,
            type: type,
            tempReliability: reliability,
            repairable: repairable
        }

        if (selectedDate !== null)
            payload.installationTime = selectedDate.getTime() / 1000


        postComponentUpdate(state.user.jwt, payload)
            .then(component => {
                setDistribution(distributions.find(o => o.id === component.distributionId) || null)
                setComponentDistParams(component.distParams)

                setSnackOpen(true)
            })
            .catch(alert)
    }

    const handleParamsSave = () => {
        let payload = {
            id: compId,
            distParams: componentDistParams,
            distributionId: distribution ? distribution.id : null,
            useVendorParams: useVendorParams
        }

        postComponentUpdate(state.user.jwt, payload)
            .then(() => {
                setSnackOpen(true)
            })
            .catch(alert)
    }

    const handleParamChange = (key, value) => {
        const list = componentDistParams.slice()
        let param = list.find(o => o.key === key)
        if (!param) {
            param = {key: key}
            list.push(param)
        }
        param.value = value


        setComponentDistParams(list)

    }

    const tableModeFields = [
        {key: "name", name: "Name"},
        {key: "severity", name: "Severity", maxWidth: "4rem"},
        {key: "occurrence", name: "Occurrence", maxWidth: "4rem"},
        {key: "detectability", name: "Detectability", maxWidth: "5rem"},
        {key: "riskPriorityNumber", name: "Risk Priority Number", maxWidth: "6.5rem"},
    ]

    const tableEventFields = [
        {key: "eventName", name: "Name"},
        {key: "description", name: "Description", maxWidth: "12rem"},
        {key: "timeString", name: "Time", sort: "time", maxWidth: "4rem"},
        {key: "failureModeName", name: "Failure mode", maxWidth: "4rem"},
    ]


    return (
        <Box p={2}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    component={NavLink}
                                    to={from ? from : "/system/" + systemId}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant={"button"} color={"primary"}>
                                System / <u>Component</u>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/link/7#bkmrk-%C2%A0"}
                    >
                        General information
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} ml={5}>
                        <Grid container spacing={3}>
                            <GridItemTitle>
                                Name
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={name || ''} onChange={e => setName(e.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Installation time
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker ampm={false} fullWidth format="dd/MM/yyyy hh:mm:ss"
                                                            value={selectedDate} onChange={handleDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <GridItemTitle>
                                Type
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={type || ''} onChange={e => setType(e.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Repairable
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <Switch
                                    checked={repairable}
                                    onChange={(event, checked) => setRepairable(checked)}
                                    color="primary"
                                />
                            </Grid>
                            <GridItemTitle>
                                Temp reliability
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={reliability || ''} onChange={e => setReliability(e.target.value)}
                                           fullWidth/>
                            </Grid>
                            <Grid item xs={4}/>
                            <GridItemTitle>
                                <Button onClick={handleBasicSave} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </GridItemTitle>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/link/17#bkmrk-failures-occurrence-"}>
                        Failure occurrence estimation function (RBD analysis)
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} ml={5}>
                        <Grid container spacing={3}>
                            <GridItemColumn label={"Distribution"}>
                                <Grid container spacing={1} alignItems={"center"}>
                                    <Grid item xs={10}>
                                        <Autocomplete
                                            options={distributions}
                                            onChange={(event, t) => setDistribution(t)}
                                            getOptionLabel={(option) => option.name}
                                            value={distribution}
                                            renderInput={(params) =>
                                                <TextField
                                                    placeholder={"Distribution"}
                                                    {...params}
                                                    fullWidth variant="standard"/>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Tooltip
                                            title={"Try to find the best fitting function, the one with the R2 value closest to 1.0 will be selected"}>
                                            <IconButton onClick={handleHintFunction}>
                                                <EmojiObjectsIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>


                            </GridItemColumn>

                            {distribution && <GridItemColumn spacing={true} label={"Use vendor params"}>
                                <Switch checked={useVendorParams || false}
                                        onChange={((event, checked) => setUseVendorParams(checked))}
                                        color="primary"/>
                            </GridItemColumn>}

                            {distributionRequiredParams.map(param =>
                                <GridItemColumn label={param.name} key={param.key}
                                                spacing={distributionRequiredParams.indexOf(param) % 2 === 1}>
                                    <TextField
                                        disabled={!useVendorParams}
                                        onChange={event => handleParamChange(param.key, event.target.value)}
                                        name={param.key}
                                        value={componentDistParams.find(o => o.key === param.key) ?
                                            componentDistParams.find(o => o.key === param.key).value : ""}
                                        fullWidth/>
                                </GridItemColumn>
                            )}
                            <Grid item xs={12}/>
                            <Grid item xs={9}/>
                            <GridItemTitle>
                                <Button onClick={() => handleParamsSave()} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </GridItemTitle>
                        </Grid>
                    </Box>

                </Grid>

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/chapter/3-failure-mode-effect-analysis-fmea"}
                    >
                        Failure modes
                    </DetailTitle>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={5}>
                        <AdvancedTable
                            style={{padding: 8}}
                            jwt={state.user.jwt}
                            filterName={""}
                            fields={tableModeFields}
                            fetchingPageFunction={fetchComponentFailureModePage}
                            onSelected={(type) => history.push("/fmea/" + type.id)}
                            mustRefresh={refreshTable}
                            onRefreshed={() => setRefreshTable(false)}
                            requestArgs={{componentId: compId}}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/page/failure-events"}
                    >
                        Failure events
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box ml={5}>
                        <AdvancedTable
                            style={{padding: 8}}
                            jwt={state.user.jwt}
                            filterName={""}
                            fields={tableEventFields}
                            fetchingPageFunction={fetchFailureEventPage}
                            onSelected={(type) => history.push("/failure/" + type.id)}
                            mustRefresh={refreshTable}
                            onRefreshed={() => setRefreshTable(false)}
                            requestArgs={{componentId: compId}}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </Box>

    )
}
