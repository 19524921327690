import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Wizard from "../../components/wizard/Wizard";
import WizardStep, {WizardAdvStep, WizardEndStep, WizardIntroStep} from "../../components/wizard/WizardStep";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import WizardStepElement from "../../components/wizard/WizardStepElement";
import TextField from "@material-ui/core/TextField";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";

export default function ComponentCreationDialog({
                                                    open,
                                                    onClose,
                                                    onSave,
                                                    systemInstallationDate
                                                }) {

    const [step, setStep] = useState(-1);

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState({error: false, text: ""});
    const [installationTime, setInstallationTime] = useState(null);
    const [dateSameAsSystem, setDateSameAsSystem] = useState(false);
    const [installationTimeError, setInstallationTimeError] = useState({error: false, text: ""});
    const [repairable, setRepairable] = useState(false);
    const [type, setType] = useState("");
    const [typeError, setTypeError] = useState({error: false, text: ""});


    const [nextEnabled, setNextEnabled] = useState(false);
    const [backEnabled, setBackEnabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);


    const fieldsValidation = {
        name: () => {
            if (name === "") {
                setNameError({error: true, text: "Please, enter a name"})
                return true
            } else
                setNameError({error: false, text: ""})
        },
        type: () => {
            if (type === "") {
                setTypeError({error: true, text: "Please, enter a component type"})
                return true
            } else
                setTypeError({error: false, text: ""})
        },
        installationTime: () => {
            if (installationTime == null) {
                setInstallationTimeError({error: true, text: "Please, enter a component installation time"})
                return true
            } else
                setInstallationTimeError({error: false, text: ""})
        }
    }


    const handleNext = () => {
        // controllo per abilitare o disabilitare il pulsante next

        let hasError

        switch (step) {
            case 0:
                hasError = !!fieldsValidation.name()
                break
            case 1:
                hasError = !!fieldsValidation.type()
                break
            case 2:
            default:
                hasError = !dateSameAsSystem && !!fieldsValidation.installationTime()

        }

        if (!hasError)
            setStep(step + 1);

    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = () => {

        // mi assicuro che tutto sia popolato come si deve (nel caso della versione avanzata)
        if (step === -2) {
            // mi assicuro che nessuno di essi ritorni true
            const nameCheck = !!fieldsValidation.name()
            const typeCheck = !!fieldsValidation.type()

            const instCheck = !dateSameAsSystem && !!fieldsValidation.installationTime()

            if (nameCheck ||
                typeCheck ||
                instCheck
            )
                return

        }


        // costruisco un oggetto rappresentante il componente
        let payload = {
            name: name,
            repairable: repairable,
            dateSameAsSystem: dateSameAsSystem,
            type: type
        }

        if (installationTime !== null && !dateSameAsSystem)
            payload.installationTime = installationTime.getTime() / 1000

        onSave(payload)
    }

    const handleClose = () => {
        setType("")
        setName("")
        setInstallationTime(null)
        setRepairable(false)
        setStep(-1)
    }

    useEffect(() => {

        switch (step) {
            case -2:
                setBackEnabled(false)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            case -1:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(false)
                break
            case 0:
                setBackEnabled(false)
                setSaveEnabled(false)
                setNextEnabled(true)
                break
            case 4:
                setBackEnabled(true)
                setSaveEnabled(true)
                setNextEnabled(false)
                break
            default:
                setBackEnabled(true)
                setSaveEnabled(false)
                setNextEnabled(true)


        }

    }, [step])

    return (
        <Dialog disableBackdropClick open={open} onClose={onClose} onExited={handleClose}>
            <DialogTitle id="form-dialog-title">
                Create a new component
                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Wizard
                    backEnabled={backEnabled}
                    onBack={handleBack}
                    saveEnabled={saveEnabled}
                    onSave={handleSave}
                    nextEnabled={nextEnabled}
                    onNext={handleNext}
                    onStepChange={setStep}
                    step={step}
                >
                    <WizardAdvStep>
                        <WizardStepElement>
                            <TextField
                                error={nameError.error}
                                helperText={nameError.text}
                                placeholder={"Component name"} variant={"outlined"} fullWidth label={""}
                                value={name} onChange={e => setName(e.target.value)}/>
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={typeError.error}
                                helperText={typeError.text}
                                placeholder={"Type"} variant={"outlined"} fullWidth label={""}
                                value={type} onChange={e => setType(e.target.value)}/>
                        </WizardStepElement>
                        <WizardStepElement>
                            <FormControlLabel
                                value="start"
                                control={<Switch color="primary" value={dateSameAsSystem}
                                                 onChange={((event, checked) => setDateSameAsSystem(checked))} />}
                                label="Copy the installation time of the system"
                                labelPlacement="end"
                            />
                        </WizardStepElement>
                        <WizardStepElement>

                                    {!dateSameAsSystem && <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            minDate={systemInstallationDate}
                                            disableFuture
                                            inputVariant="outlined"
                                            ampm={false}
                                            error={installationTimeError.error}
                                            placeholder={"Installation time"}
                                            helperText={installationTimeError.text}
                                            value={installationTime}
                                            onChange={setInstallationTime}
                                            fullWidth format="dd/MM/yyyy hh:mm:ss"
                                        />
                                    </MuiPickersUtilsProvider>}

                        </WizardStepElement>
                        <WizardStepElement>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch checked={repairable}
                                            onChange={((event, checked) => setRepairable(checked))}
                                            color="primary"/>
                                }
                                label="Component repairable"
                                labelPlacement="end"
                            />
                        </WizardStepElement>
                    </WizardAdvStep>
                    <WizardIntroStep justify="space-between">
                        <Box mb={2}>
                            <WizardStepElement>
                                Follow the guided procedure to get specific instructions supporting you in adding a new
                                component.
                                If you are an experienced user, guided procedure can be skipped.
                            </WizardStepElement>
                        </Box>
                        <Grid item>
                            <Tooltip placement={"right"} title="A more advanced approach with less help">
                                <Button color={"primary"} onClick={() => setStep(-2)} variant={"outlined"}>
                                    skip it
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip placement={"right"}
                                     title="An intuitive step by step guide to the creation">
                                <Button onClick={() => setStep(0)} color={"primary"} variant={"contained"}>
                                    Guided procedure
                                </Button>
                            </Tooltip>
                        </Grid>
                    </WizardIntroStep>
                    <WizardStep>
                        <WizardStepElement>
                            Which is the name of the component?
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={nameError.error}
                                helperText={nameError.text}
                                placeholder={"Component name"} variant={"outlined"} fullWidth label={""}
                                value={name} onChange={e => setName(e.target.value)}/>
                        </WizardStepElement>
                    </WizardStep>
                    <WizardStep>
                        <WizardStepElement>
                            Which type of component is
                            <Typography component={"span"} color={"primary"}> {name}
                            </Typography>? (e.g. bearing, pump)
                        </WizardStepElement>
                        <WizardStepElement>
                            <TextField
                                error={typeError.error}
                                helperText={typeError.text}
                                placeholder={"Type"} variant={"outlined"} fullWidth label={""}
                                value={type} onChange={e => setType(e.target.value)}/>
                        </WizardStepElement>
                    </WizardStep>
                    <WizardStep>
                        <WizardStepElement>
                            When this component has been installed in the system?
                        </WizardStepElement>
                        <WizardStepElement>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        value="start"
                                        control={<Switch color="primary" value={dateSameAsSystem}
                                                         onChange={((event, checked) => setDateSameAsSystem(checked))} />}
                                        label="Copy the installation time of the system"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!dateSameAsSystem && <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            disableFuture
                                            inputVariant="outlined"
                                            ampm={false}
                                            error={installationTimeError.error}
                                            placeholder={"Installation time"}
                                            helperText={installationTimeError.text}
                                            value={installationTime}
                                            onChange={setInstallationTime}
                                            fullWidth format="dd/MM/yyyy hh:mm:ss"
                                        />
                                    </MuiPickersUtilsProvider>}
                                </Grid>
                            </Grid>
                        </WizardStepElement>
                    </WizardStep>
                    <WizardStep>
                        <WizardStepElement>
                            Is this component repairable or in case of failures it has to be necessarily replaced?
                        </WizardStepElement>
                        <WizardStepElement>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch checked={repairable}
                                            onChange={((event, checked) => setRepairable(checked))}
                                            color="primary"/>
                                }
                                label="Component repairable"
                                labelPlacement="start"
                            />
                        </WizardStepElement>
                    </WizardStep>
                    <WizardEndStep>
                        Please verify that information provided are correct and click on Save to create the new
                        component.
                    </WizardEndStep>
                </Wizard>
            </DialogContent>
        </Dialog>
    )
}