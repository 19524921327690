import React, {useContext, useEffect, useRef, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import {useHistory, useParams} from "react-router-dom";
import {
    fetchAllProcessFailureModes,
    fetchAllUserProcesses,
    fetchFailureDetail,
    postFailureUpdate
} from "../../utils/apiCaller";
import {AppContext} from "../../App";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailTitle from "../../components/DetailTitle";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import GridItemTitle from "../../components/GridItemTitle";
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Typography} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {parseDuration} from "../../utils/utils";


export default function FailureDetailScreen() {


    const [state] = useContext(AppContext);
    const [snackOpen, setSnackOpen] = useState(false);
    const [failure, setFailure] = useState({loading: true});

    // campi per la form sotto
    const [time, setTime] = useState(null);
    const [eventName, setEventName] = useState(null);
    const [description, setDescription] = useState(null);
    const [shortTermActions, setShortTermActions] = useState(null);
    const [componentReplaced, setComponentReplaced] = useState(false)
    const [timeToRepair, setTimeToRepair] = useState(false)
    const [failureMode, setFailureMode] = useState(null)
    const [process, setProcess] = useState(null)

    const [failureModes, setFailureModes] = useState([]);
    const [processes, setProcesses] = useState([]);

    const {failId} = useParams();
    const history = useHistory();

    const isFirstRun = useRef(true);

    useEffect(() => {
        fetchAllUserProcesses(state.user.jwt)
            .then(processList => {
                setProcesses(processList)
                // carico tutti i componenti del sistema
                fetchFailureDetail(state.user.jwt, failId)
                    .then(component => setFailure(component))
                    .catch(error => alert(error))
            })
            .catch(alert)

    }, [state.user.jwt, failId])

    useEffect(() => {
        if (!failure.loading) {
            setEventName(failure.eventName)
            setDescription(failure.description)
            setShortTermActions(failure.shortTermActions)
            setComponentReplaced(failure.componentReplaced)
            setTimeToRepair(failure.timeToRepairText)

            if (!!failure.failureModeName) {
                let mode = {name: failure.failureModeName, id: failure.failureModeId}
                setFailureMode(mode)
                setFailureModes([mode])
            } else
                isFirstRun.current = false


            setProcess(processes.find(o => o.id === failure.processId) || null)

            if (failure.time)
                setTime(new Date(failure.time * 1000))
        }
    }, [failure, processes])


    useEffect(() => {

        if (!!process) {

            if (isFirstRun.current) {
                isFirstRun.current = false;
                return;
            }

            setFailureMode(null)

            fetchAllProcessFailureModes(state.user.jwt, process.id)
                .then(result => setFailureModes(result))
                .catch(error => alert(error))
        }

    }, [state.user.jwt, process])


    const handleBasicSave = () => {
        let payload = {
            id: failId,
            // componentId: compId,
            eventName: eventName,
            description: description,
            shortTermActions: shortTermActions,
            componentReplaced: componentReplaced,
            timeToRepair: parseDuration(timeToRepair),
            failureModeId: failureMode.id
        }

        if (time !== null)
            payload.time = time.getTime() / 1000


        postFailureUpdate(state.user.jwt, payload)
            .then(() => {
                setSnackOpen(true)
            })
            .catch(alert)
    }

    return (
        <Box p={2}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={() => history.push("/failure")}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant={"button"} color={"primary"}>
                                System / Component / <u>failure</u>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/page/failure-events"}
                    >
                        General information
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} ml={5}>
                        <Grid container spacing={3}>
                            <GridItemTitle>
                                Event name
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField value={eventName || ''} onChange={e => setEventName(e.target.value)}
                                           fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Event time
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker ampm={false} fullWidth format="dd/MM/yyyy hh:mm:ss"
                                                            value={time} onChange={setTime}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <GridItemTitle>
                                Description
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField multiline
                                           value={description || ''}
                                           onChange={e => setDescription(e.target.value)} fullWidth/>
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Short term actions
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField multiline
                                           value={shortTermActions || ''}
                                           onChange={e => setShortTermActions(e.target.value)} fullWidth/>

                            </Grid>
                            <GridItemTitle>
                                Component replaced
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <Switch
                                    checked={componentReplaced}
                                    onChange={(event, checked) => setComponentReplaced(checked)}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Time to repair
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <TextField placeholder={"Eg. 1d 3h 20m"}
                                           value={timeToRepair || ''}
                                           onChange={e => setTimeToRepair(e.target.value)} fullWidth/>

                                Seconds: {parseDuration(timeToRepair)}
                            </Grid>
                            <GridItemTitle>
                                Process
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <Autocomplete
                                    options={processes}
                                    value={process}
                                    onChange={(event, t) => setProcess(t)}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth/>
                                    }
                                />
                            </Grid>
                            <Grid item xs={1}/>
                            <GridItemTitle>
                                Failure mode
                            </GridItemTitle>
                            <Grid item xs={3}>
                                <Autocomplete
                                    options={failureModes}
                                    value={failureMode}
                                    onChange={(event, t) => setFailureMode(t)}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth/>
                                    }
                                />
                            </Grid>
                            <Grid item xs={9}/>
                            <GridItemTitle>
                                <Button onClick={handleBasicSave} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </GridItemTitle>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </Box>

    )
}
