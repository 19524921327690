import React, {useContext, useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import {useHistory, useParams} from "react-router-dom";


import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from "@material-ui/core/TextField";
import SaveIcon from '@material-ui/icons/Save';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Typography} from "@material-ui/core";
import {AppContext} from "../../App";
import {fetchProcessDetail, fetchProcessFailureModePage, postProcessUpdate} from "../../utils/apiCaller";
import DetailTitle from "../../components/DetailTitle";
import GridItemColumn from "../../components/GridItemColumn";
import AdvancedTable from "../../components/AdvancedTable";

const tableModeFields = [
    {key: "name", name: "Name"},
    {key: "severity", name: "Severity", maxWidth: "4rem"},
    {key: "occurrence", name: "Occurrence", maxWidth: "4rem"},
    {key: "detectability", name: "Detectability", maxWidth: "5rem"},
    {key: "riskPriorityNumber", name: "Risk Priority Number", maxWidth: "6.5rem"},
]

// const systemTableFields = [
//     {key: "name", name: "Name"},
//     {key: "type", name: "Type"},
//     {key: "installationTimeString", name: "Installation time"}
// ]

export default function ProcessDetailScreen() {

    const [state] = useContext(AppContext);
    const [snackOpen, setSnackOpen] = useState(false);
    const [process, setProcess] = useState({loading: true});

    const [refreshModesTable, setRefreshModesTable] = useState(false);

    // campi per la form sotto
    const [description, setDescription] = useState(null);
    const [name, setName] = useState(null);

    // const [refreshSystemTable, setRefreshSystemTable] = useState(false);
    // const [systemSelectionDialogOpen, setSystemSelectionDialogOpen] = useState(false);

    const {processId} = useParams();
    const history = useHistory();


    useEffect(() => {
        fetchProcessDetail(state.user.jwt, processId)
            .then(process => setProcess(process))

    }, [state.user.jwt, processId])

    useEffect(() => {
        if (!process.loading)
            initForm(process)
    }, [process])

    const initForm = (dto) => {
        setName(dto.name)
        setDescription(dto.description)

    }

    const handleBasicSave = () => {
        let payload = {
            id: processId,
            name: name,
            description: description
        }

        postProcessUpdate(state.user.jwt, payload)
            .then(process => {
                // setSystemSelectionDialogOpen(false)
                setProcess(process)
                setSnackOpen(true)
            })
            .catch(alert)
    }

    // const handleNewSystemSelected = (system) => {
    //     putSystemToProcess(state.user.jwt, processId, system.id)
    //         .then(() => {
    //             setRefreshSystemTable(true)
    //             setSystemSelectionDialogOpen(false)
    //         })
    // }

    return (
        <Box p={2}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={() => history.push("/process")}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant={"button"} color={"primary"}>
                                <u>Process</u>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/page/processes"}
                    >
                        General information
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box p={2} ml={5}>
                        <Grid container spacing={3}>
                            <GridItemColumn label={"Name"}>
                                <TextField name={"name"}
                                           value={name || ""}
                                           onChange={event => setName(event.target.value)}
                                           fullWidth label=""/>
                            </GridItemColumn>
                            <GridItemColumn spacing label={"Description"}>
                                <TextField name={"description"}
                                           multiline
                                           value={description || ""}
                                           onChange={event => setDescription(event.target.value)}
                                           fullWidth label=""/>
                            </GridItemColumn>
                            <GridItemColumn>
                            </GridItemColumn>

                            <GridItemColumn spacing align={"right"}>
                                <Button onClick={handleBasicSave} color={"primary"}
                                        variant={"contained"} startIcon={<SaveIcon/>}>
                                    Save
                                </Button>
                            </GridItemColumn>
                        </Grid>
                    </Box>
                </Grid>

                {/*<Grid item xs={12}>*/}
                {/*    <DetailTitle*/}
                {/*        tooltip={" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed dui pulvinar, porttitor libero id, vestibulum enim."}*/}
                {/*    >*/}
                {/*        Systems*/}
                {/*    </DetailTitle>*/}
                {/*</Grid>*/}

                {/*<Grid item xs={12}>*/}
                {/*    <Box ml={5}>*/}
                {/*        <AdvancedTable*/}
                {/*            style={{padding: 8}}*/}
                {/*            jwt={state.user.jwt}*/}
                {/*            filterName={""}*/}
                {/*            fields={systemTableFields}*/}
                {/*            fetchingPageFunction={fetchProcessSystemsPage}*/}
                {/*            onSelected={(system) => history.push("/system/"+ system.id)}*/}
                {/*            mustRefresh={refreshSystemTable}*/}
                {/*            onRefreshed={() => setRefreshSystemTable(false)}*/}
                {/*            requestArgs={{processId: processId}}*/}
                {/*        />*/}

                {/*        <SystemSelectionDialog*/}
                {/*            onClose={() => setSystemSelectionDialogOpen(false)}*/}
                {/*            onSelected={handleNewSystemSelected}*/}
                {/*            open={systemSelectionDialogOpen}*/}
                {/*        />*/}
                {/*        <AddButton onClick={() => setSystemSelectionDialogOpen(true)}>*/}
                {/*            System*/}
                {/*        </AddButton>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <DetailTitle
                        href={"https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/chapter/3-failure-mode-effect-analysis-fmea"}
                    >
                        Failure modes
                    </DetailTitle>
                </Grid>
                <Grid item xs={12}>
                    <Box ml={5}>
                        <AdvancedTable
                            style={{padding: 8}}
                            jwt={state.user.jwt}
                            filterName={""}
                            fields={tableModeFields}
                            fetchingPageFunction={fetchProcessFailureModePage}
                            onSelected={(type) => history.push("/fmea/" + type.id)}
                            mustRefresh={refreshModesTable}
                            onRefreshed={() => setRefreshModesTable(false)}
                            requestArgs={{processId: processId}}
                        />
                    </Box>
                </Grid>

            </Grid>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </Box>

    )
}
