import React, {useContext, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FailureModeWizard from "./FailureModeWizard";
import FailureModeSelection from "./FailureModeSelection";
import {
    fetchProcessFailureEligiblePage,
    fetchProcessFailureModePage,
    postFailureModeUpdate
} from "../../utils/apiCaller";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {AppContext} from "../../App";

export default function FailureModeDialog({
                                              open,
                                              onClose,
                                              onSave,
                                              creationOnly = false,
                                              creationModeAtStart = false,
                                              componentId,
                                              processId
                                          }) {


    const [creationMode, setCreationMode] = useState(creationOnly || creationModeAtStart)

    const [state] = useContext(AppContext);

    const handleSelectionModeSwitch = () => {
        if (!creationOnly)
            setCreationMode(!creationMode)
    }

    const handleOnSelected = (failureMode) =>{
        // ricevo una failure mode, ora la devo associare al componente o al processo

        if(!!componentId)
            failureMode.componentId = componentId

        if(!!processId)
            failureMode.processId = processId

        postFailureModeUpdate(state.user.jwt, failureMode)
            .then(onSave)
            .catch(console.log)

    }

    return (
        <Dialog disableBackdropClick open={open} onClose={onClose}>
            <DialogTitle id="form-dialog-title">

                {creationMode ?
                    <Grid container>
                        <Grid item>
                            <Box hidden={creationOnly} mr={2}>
                                <Button
                                    startIcon={<ArrowBackIcon/>}
                                    hidden={creationOnly}
                                    color="primary"
                                    onClick={handleSelectionModeSwitch}>
                                    Back
                                </Button>
                            </Box>
                        </Grid>

                        <Grid item>
                            Create a new failure mode
                        </Grid>
                    </Grid>
                    :
                    "Select a failure mode"
                }


                <IconButton aria-label="close" style={{
                    position: "absolute",
                    top: 8, right: 8,
                }}
                            onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {
                    creationMode ?
                        <FailureModeWizard
                            processId={processId}
                            onSave={onSave}
                            componentId={componentId}
                        />
                        :
                        <FailureModeSelection
                            onNewFailureMode={handleSelectionModeSwitch}
                            onSelected={handleOnSelected}
                            fetchingFunction={
                                processId?
                                fetchProcessFailureModePage: fetchProcessFailureEligiblePage
                            }
                            fetchingParams={{processId: processId, componentId: componentId}}
                        />
                }

            </DialogContent>
        </Dialog>
    )
}