import React, {useContext, useEffect, useState} from 'react';

import './Style.css'
import {AppContext} from "../../App";
import {fetchAllFailureModes} from "../../utils/apiCaller";
import Box from "@material-ui/core/Box";
import EditIcon from '@material-ui/icons/Edit';
import {IconButton} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {Grid as DxGrid, Table, TableColumnResizing, TableHeaderRow,} from '@devexpress/dx-react-grid-material-ui';
import Button from "@material-ui/core/Button";
import {objToCsv} from "../../utils/utils";
import Grid from "@material-ui/core/Grid";
import GetAppIcon from '@material-ui/icons/GetApp';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const columns = [
    {name: "button", title: " "},
    {name: "id", title: "Failure ID"},
    {name: "processName", title: "Process"},
    {name: "name", title: "Failure mode"},
    {name: "failureEffect", title: "Potential effect of the failure"},
    {name: "severity", title: "Severity"},
    {name: "severityRationale", title: "Rationale for the choice of the severity value"},
    {name: "potentialCauses", title: "Potential causes of the failure type"},
    {name: "causeType", title: "Cause type selection"},
    {name: "systemName", title: "System"},
    {name: "componentName", title: "Component"},
    {name: "currentControls", title: "Current controls"},
    {name: "occurrence", title: "Occurrence before mitigation actions"},
    {name: "occurrenceRationale", title: "Rationale for the choice of the occurrence value"},
    {name: "detectability", title: "Detectability before mitigation actions"},
    {name: "detectabilityRationale", title: "Rationale for the choice of the detectability value"},
    {name: "riskPriorityNumber", title: "Risk Priority Number"},
    {name: "criticalNumber", title: "Critical Number"},
    {name: "severityOccurrenceDetectability", title: "SOD"},
    {name: "shortTermActions", title: "Short-term actions"},
    {name: "mitigationActions", title: "Mitigation actions"},
]

const df = [
    {columnName: 'button', width: 100},
    {columnName: 'id', width: 120},
    {columnName: 'processName', width: 150},
    {columnName: 'name', width: 240},
    {columnName: 'failureEffect', width: 200},
    {columnName: 'severity', width: 80},
    {columnName: 'severityRationale', width: 240},
    {columnName: 'potentialCauses', width: 240},
    {columnName: 'causeType', width: 200},
    {columnName: 'systemName', width: 200},
    {columnName: 'componentName', width: 200},
    {columnName: 'currentControls', width: 240},
    {columnName: 'occurrence', width: 150},
    {columnName: 'occurrenceRationale', width: 240},
    {columnName: 'detectability', width: 150},
    {columnName: 'detectabilityRationale', width: 240},
    {columnName: 'riskPriorityNumber', width: 150},
    {columnName: 'severityOccurrenceDetectability', width: 150},
    {columnName: 'criticalNumber', width: 150},
    {columnName: 'shortTermActions', width: 240},
    {columnName: 'mitigationActions', width: 240},
]

const ex = [
    {columnName: 'button', minWidth: 100, maxWidth: 100}
]

const tableColumnExtensions = [
    {columnName: 'failureEffect', wordWrapEnabled: true},
    {columnName: 'severityRationale', wordWrapEnabled: true},
    {columnName: 'potentialCauses', wordWrapEnabled: true},
    {columnName: 'currentControls', wordWrapEnabled: true},
    {columnName: 'occurrenceRationale', wordWrapEnabled: true},
    {columnName: 'occurrence', wordWrapEnabled: true},
    {columnName: 'detectabilityRationale', wordWrapEnabled: true},
    {columnName: 'detectability', wordWrapEnabled: true},
    {columnName: 'shortTermActions', wordWrapEnabled: true},
    {columnName: 'mitigationActions', wordWrapEnabled: true},
]

export default function FmeaScreen() {

    const [state] = useContext(AppContext);

    const [failureModes, setFailureModes] = useState([]);

    const handleExport = () => {
        failureModes.forEach(f => delete f["button"])
        const csv = objToCsv(failureModes)
        console.log(csv)
        let a = document.createElement('a');
        a.textContent = 'download.csv';
        a.download = "fmea.csv";
        a.href = 'data:text/csv;charset=utf-8,' + escape(csv);
        document.body.appendChild(a);
        a.click();
    }

    const handleHelpClick = () => {
        window.open("https://wiki-rat.spslab.ch/books/reclaim-reliability-analysis-tool/chapter/3-failure-mode-effect-analysis-fmea",
            '_blank').focus();
    }

    useEffect(() => {
        fetchAllFailureModes(state.user.jwt)
            .then(result => {
                result.forEach(r => {
                    r.button =
                        <Box style={{width: "100%", textAlign: "center"}}>
                            <IconButton component={NavLink} to={"/fmea/" + r.id}>
                                <EditIcon/>
                            </IconButton>
                        </Box>
                })
                setFailureModes(result)
            })
            .catch(alert)
    }, [state.user.jwt])

    return (
        <Box p={2}>
            <Grid container spacing={3} justify={"flex-end"} alignItems={"center"}>
                <Grid item>
                    <Button onClick={handleExport} color={"primary"}
                            variant={"contained"} startIcon={<GetAppIcon/>}>
                        Export
                    </Button>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleHelpClick} color="primary" component="span">
                        <HelpOutlineIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <DxGrid
                        rows={failureModes}
                        columns={columns}
                    >
                        <Table columnExtensions={tableColumnExtensions}/>
                        <TableColumnResizing defaultColumnWidths={df} columnExtensions={ex}/>
                        <TableHeaderRow/>
                    </DxGrid>
                </Grid>
            </Grid>


        </Box>
    );
}
