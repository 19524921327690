// si occupa solo di salvare e caricare lo stato dalla memoria

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('rat_state');
        if (serializedState === null) {
            // se non c'è niente allora ritorno lo stato di default
            return {
                user: null,
                system: null
            };
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};


export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('rat_state', serializedState);
    } catch {
        // ignore write errors
    }
};