import React from 'react';

import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import './Style.css'
import Link from "@material-ui/core/Link";


export default function LandingScreen() {

    return (
        <Box p={2}>
            <Grid
                spacing={2}
                container
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={2}>
                    <img style={{width: "80%"}} src={"/logo.png"} alt={"logo"}/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant={"h3"} color={"primary"}>
                        Welcome on the<br/>
                        RECLAIM <b>Reliability Analysis Tool</b>
                    </Typography>
                </Grid>
                <Grid item style={{height: 26}}/>
                <Grid item xs={12} className={"landing-title"}>
                    <Typography variant={"h5"} color={"primary"}>
                        The tool and its functions
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"h6"}>
                        The RECLAIM <Typography variant={"h6"} component={"span"} color={"primary"}>
                        Reliability Analysis
                        Tool
                    </Typography> aims to support companies,
                        in particular SMEs, which have lack of competences and no or weak
                        approaches to maintenance and failure management, in starting their
                        journey toward the digitalisation of assets health and maintenance management.
                    </Typography>
                    <Typography variant={"h6"}>
                        The main functionalities that it provides are:
                    </Typography>
                    <Typography variant={"h6"}>
                        <ul className={"landing-ul"}>
                            <li>
                                <Typography variant={"h6"} component={"span"} color={"primary"}>
                                    Collecting structured failure data: </Typography>
                                by defining processes, failure modes,
                                machines and components, RAT tool gives the possibility to add failure
                                events to create a failures history.
                            </li>
                            <li>
                                <Typography variant={"h6"} component={"span"} color={"primary"}>
                                    Applying Failure Mode and Effects Analysis (FMEA): </Typography>
                                it is a systematic approach to
                                assess potential system failures and the resulting consequences of those failures.
                                The main objectives of a FMEA are to evaluate the risk associated with the identified
                                failure effects and to identify actions to detect, prevent,
                                or mitigate those deemed most critical.
                            </li>
                            <li>
                                <Typography variant={"h6"} component={"span"} color={"primary"}>
                                    Applying Reliability Block Diagram (RBD) Analysis: </Typography>
                                it is a method to calculate the reliability of a system based
                                on the modelling of the relationship among components and their current state.
                            </li>
                        </ul>
                    </Typography>
                </Grid>

                <Grid item xs={12} className={"landing-title"}>
                    <Typography variant={"h5"} color={"primary"}>
                        The RECLAIM project
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={"h6"}>
                        The RECLAIM Reliability Analysis Tool has been developed in the RECLAIM project.
                        RECLAIM stands for RE-manufaCturing and Refurbishment LArge Industrial equipMent.
                        This EU funded project intends to demonstrate strategies and technologies that
                        enable the re-use of industrial equipment in old, renewed and new factories alike.
                        The project will focus on harnessing digital analytics, the Internet of Things and
                        circular economy strategies to improve predictive maintenance and upgrades.
                    </Typography>
                    <Typography variant={"h6"}>
                    <Link href={"https://www.reclaim-project.eu/"} target={"_blank"}>
                            More info...
                    </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{height: 26}}/>
                <Grid item xs={6}>
                    SUPSI ownership/team/lab
                </Grid>
                <Grid item xs={6}>
                    <Grid
                        spacing={1}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <Grid item style={{width: 80}}>
                            <img style={{width: "100%", marginTop:5}} src={"/eu_flag.svg"} alt={"logo"}/>
                        </Grid>
                        <Grid item style={{width: 400}}>
                            This project has received funding from the European <br/> Union’s Horizon 2020
                            research and innovation programme<br/> under grant agreement N°869884.
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
