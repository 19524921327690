import {ReclaimNode} from "../ReclaimNode";

export class DummyNode extends ReclaimNode {
    constructor(serverNode) {
        super({
            type: 'dummy-node'
        }, serverNode);

    }

    serialize() {
        return {
            ...super.serialize(),
        };
    }
}
