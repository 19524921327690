import * as React from 'react';
import {useState} from 'react';
import {PortWidget} from '@projectstorm/react-diagrams';
import './main.css'
import {Menu, MenuItem} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

export default function ComponentNodeWidget({node, engine, dummy}) {
    const [isStandby, setIsStandBy] = useState(node.standBy)
    const [selectedFractional, setSelectedFractional] = useState(node.fractionalFactor)
    const [selectedParallelization, setSelectedParallelization] = useState(node.parallelKind)
    const [selectedK, setSelectedK] = useState(node.partialK)

    const [fractionalAnchor, setFractionalAnchor] = React.useState(null);
    const [standbyAnchor, setStandbyAnchor] = React.useState(null);
    const [parallelAnchor, setParallelAnchor] = React.useState(null);
    const [kAnchor, setKAnchor] = React.useState(null);

    const outLinksCount = node.outLinksCount

    if (outLinksCount < selectedK)
        setSelectedK(outLinksCount)

    const isSequential = node.outLinksCount <= 1

    const handleParallelMenuOpen = (event) => {
        setParallelAnchor(event.currentTarget);
    };

    const handleKMenuOpen = (event) => {
        setKAnchor(event.currentTarget);
    };

    const handleParallelMenuClose = () => {
        setParallelAnchor(null);
    };

    const handleKMenuClose = () => {
        setKAnchor(null);
    };

    const handleStandByOpen = (event) => {
        setStandbyAnchor(event.currentTarget);
    };

    const handleStandByClose = () => {
        setStandbyAnchor(null);
    };

    const handleStandByChange = (value) => {
        setIsStandBy(value)
        node.setStandBy(value)
        setStandbyAnchor(null)
    }

    const handleFractionalChange = (value) => {
        setSelectedFractional(value)
        node.setFractional(value)
        setFractionalAnchor(null)
    }

    const handleKindChange = (value) => {
        node.setParallelKind(value)
        setSelectedParallelization(value)
        setParallelAnchor(null)
        engine.repaintCanvas()
    }

    const handleKChanged = (value) => {
        node.setPartialK(value)
        setSelectedK(value)
        setKAnchor(null)
    }

    return (
        <div className="component-node"

             style={{
                 backgroundColor: dummy ? "#66bb66" : "#6688bb",
                 border: node.isSelected() ? "solid 2px rgb(0,192,255)" : "solid 2px black"
             }}>
            {!dummy && <div className={"dummy-header"}>
                {node.name}

            </div>}

            <div className={"dummy-ports"}>
                <div className={"port-container"}>
                    {node.getInPort() && <PortWidget engine={engine} port={node.getInPort()}>
                        <div className="dummy-port"/>
                    </PortWidget>}
                    <div style={{padding: "0 5px"}}>IN</div>
                </div>
                <div className={"port-container"}>
                    <div style={{padding: "0 5px"}}>OUT</div>
                    {node.getOutPort() && <PortWidget engine={engine} port={node.getOutPort()}>
                        <div className="dummy-port"/>
                    </PortWidget>}
                </div>
            </div>
            <Grid container justify={"space-between"} alignItems={"stretch"}>
                <Grid item xs={5}>
                    {!isSequential && !(node.showStandby || node.showFractional) &&
                    <div className={"dummy-header"} style={{height: "100%"}}/>}
                    {node.showStandby && <div style={{height: "100%"}} className={"dummy-header"}>
                        <div>
                            <Box component={"div"} onClick={handleStandByOpen} className={"dummy-parallel-selector"}>
                                <div>{isStandby ? "StandBy" : "Active"}</div>
                                <div>▼</div>
                            </Box>

                            {standbyAnchor && <Menu
                                anchorEl={standbyAnchor}
                                keepMounted
                                open={Boolean(standbyAnchor)}
                                onClose={handleStandByClose}
                            >
                                <MenuItem onClick={() => handleStandByChange(false)}>Active</MenuItem>
                                <MenuItem onClick={() => handleStandByChange(true)}>StandBy</MenuItem>
                            </Menu>}
                        </div>
                    </div>}

                    {node.showFractional && <div style={{height: "100%"}} className={"dummy-header"}>
                        <div>
                            <Box component={"div"} onClick={(evt) => setFractionalAnchor(evt.currentTarget)}
                                 className={"dummy-parallel-selector"}>
                                <div>{selectedFractional} %</div>
                                <div>▼</div>
                            </Box>

                            {fractionalAnchor && <Menu
                                anchorEl={fractionalAnchor}
                                keepMounted
                                open={Boolean(fractionalAnchor)}
                                onClose={() => setFractionalAnchor(null)}
                            >
                                <MenuItem onClick={() => handleFractionalChange(10)}>10 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(20)}>20 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(30)}>30 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(40)}>40 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(50)}>50 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(60)}>60 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(70)}>70 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(80)}>80 %</MenuItem>
                                <MenuItem onClick={() => handleFractionalChange(90)}>90 %</MenuItem>
                            </Menu>}
                        </div>
                    </div>}
                </Grid>
                <Grid item xs={5}>
                    {isSequential && (node.showStandby || node.showFractional) &&
                    <div className={"dummy-header"} style={{height: "100%"}}/>}

                    {!isSequential && <div style={{justifyContent: "flex-end"}} className={"dummy-header"}>
                        <Box component={"div"} onClick={handleParallelMenuOpen} className={"dummy-parallel-selector"}>
                            <div>{selectedParallelization.substring(0, 3)}</div>
                            <div>▼</div>
                        </Box>

                        {parallelAnchor && <Menu
                            anchorEl={parallelAnchor}
                            keepMounted
                            open={Boolean(parallelAnchor)}
                            onClose={handleParallelMenuClose}
                        >
                            <MenuItem onClick={() => handleKindChange("TOTAL")}>Total</MenuItem>
                            <MenuItem onClick={() => handleKindChange("PARTIAL")}>Partial</MenuItem>
                            <MenuItem onClick={() => handleKindChange("STANDBY")}>StandBy</MenuItem>
                            <MenuItem onClick={() => handleKindChange("FRACTIONAL")}>Fractional</MenuItem>
                        </Menu>}

                    </div>}

                    {!isSequential && selectedParallelization === "PARTIAL" &&
                    <div style={{justifyContent: "flex-end"}} className={"dummy-header"}>
                        <div>
                            <Box component={"div"} onClick={handleKMenuOpen} className={"dummy-parallel-selector"}>
                                <div>K: {selectedK}</div>
                                <div>▼</div>
                            </Box>

                            {kAnchor && <Menu
                                anchorEl={kAnchor}
                                keepMounted
                                open={Boolean(kAnchor)}
                                onClose={handleKMenuClose}
                            >
                                {[...Array(outLinksCount).keys()].map(item =>
                                    <MenuItem onClick={() => handleKChanged(item + 1)} key={item}
                                              value={item + 1}>{item + 1}</MenuItem>
                                )}
                            </Menu>}
                        </div>
                    </div>}
                </Grid>
            </Grid>

        </div>
    );
}

