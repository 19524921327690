import * as React from 'react';
import {DummyNode} from './DummyNode';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import ComponentNodeWidget from "../component/ComponentNodeWidget";

export class DummyNodeFactory extends AbstractReactFactory {
	constructor() {
		super('dummy-node');
	}

	generateModel(event) {
		return new DummyNode();
	}

	generateReactWidget(event) {
		return <ComponentNodeWidget dummy engine={this.engine} node={event.model}/>;
	}
}
