import React, {useContext, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import {AppContext} from "../../App";
import AddButton from "../../components/AddButton";
import AdvancedTable from "../../components/AdvancedTable";

const tableModeFields = [
    {key: "name", name: "Name"},
    {key: "description", name: "Description"}
]
export default function FailureModeSelection({
                                                 onSelected,
                                                 onNewFailureMode,
                                                 fetchingFunction,
                                                 fetchingParams
                                             }) {

    const [state] = useContext(AppContext);

    const [filterName, setFilterName] = useState("");

    const handleFilterChange = (value) => {
        setFilterName(value)
    }


    return (
        <Grid container>
            <Grid item xs={12}
                  style={{paddingRight: 20, paddingLeft: 20}}>
                <TextField
                    onChange={(e) => handleFilterChange(e.target.value)}
                    size={"small"}
                    label="Search failure mode"
                    variant={"outlined"}
                    fullWidth
                    InputProps={{
                        startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{marginTop: 20}}>
                <AddButton
                    style={{
                        position: "absolute",
                        zIndex: 100,
                        bottom: 12, left: 16,

                    }}
                    label={"failure mode"} onClick={() => onNewFailureMode()}/>
                <AdvancedTable
                    jwt={state.user.jwt}
                    filterName={filterName}
                    fetchingPageFunction={fetchingFunction}
                    requestArgs={fetchingParams}
                    onSelected={onSelected}
                    fields={tableModeFields}
                />
            </Grid>
        </Grid>
    )
}