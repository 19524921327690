import React from 'react';
import {Route, Switch} from "react-router-dom";
import LoginScreen from "./login/LoginScreen";
import AppMainContent from "../layouts/AppMainContent";
import RegisterScreen from "./register/RegisterScreen";
import PasswordRecoveryScreen from "./recovery/PasswordRecoveryScreen";


export default function ScreenRouter() {
    return (
        <Switch>
            <Route path="/login" component={LoginScreen}/>
            <Route path="/recover" component={PasswordRecoveryScreen}/>
            <Route path="/register" component={RegisterScreen}/>
            <Route path="/" component={AppMainContent}/>
        </Switch>


    );
}
