import React, {useCallback, useEffect, useRef} from 'react';


import createEngine, {DefaultDiagramState, DiagramModel,} from '@projectstorm/react-diagrams';
import {CanvasWidget} from '@projectstorm/react-canvas-core';
import './Style.css'
import {debounce} from "lodash";
import {useParams} from "react-router-dom";
import {DummyNode} from "./diagram/dummy/DummyNode";
import {DummyNodeFactory} from "./diagram/dummy/DummyNodeFactory";
import {ComponentNodeFactory} from "./diagram/component/ComponentNodeFactory";
import {ComponentNode} from "./diagram/component/ComponentNode";

const engine = createEngine()
engine.setMaxNumberPointsPerLink(0)
engine.getNodeFactories().registerFactory(new DummyNodeFactory());
engine.getNodeFactories().registerFactory(new ComponentNodeFactory());
engine.setModel(new DiagramModel())
const state = engine.getStateMachine().getCurrentState();
if (state instanceof DefaultDiagramState) {
    state.dragNewLink.config.allowLooseLinks = false;
}

const checkDifference = (current, last) => {
    if (current.length !== last.length)
        return true

    for (let i = 0; i < current.length; i++) {
        let node = current[i]

        let filter = last.filter(n => n.id === node.id)
        let lastNode = filter[0]

        // se non c'è una corrispondenza allora c'è una differenza
        if (!lastNode)
            return true

        // controllo sulla posizione
        if (node.xPos !== lastNode.xPos || node.yPos !== lastNode.yPos)
            return true

        // ora devo controllare le porte
        // prima mi assicuro che abbiano lo stesso numero di destinazioni
        if (node.inPort.destinationPorts.length !== lastNode.inPort.destinationPorts.length ||
            node.outPort.destinationPorts.length !== lastNode.outPort.destinationPorts.length
        )
            return true

        // mi assicuro che le liste di destinazione contengano gli stessi valori, anche in ordine differente
        if (!node.inPort.destinationPorts.every(dest => lastNode.inPort.destinationPorts.includes(dest)))
            return true

        if (!node.outPort.destinationPorts.every(dest => lastNode.outPort.destinationPorts.includes(dest)))
            return true

        if (node.parallelKind !== lastNode.parallelKind)
            return true

        if (node.isStandBy !== lastNode.isStandBy)
            return true

        if (node.partialK !== lastNode.partialK)
            return true

        if (node.fractionalFactor !== lastNode.fractionalFactor)
            return true

    }

    return false
}

export default function RatDiagram({
                                       diagramNodes,
                                       onUpdate = () => {
                                       },
                                       onComponentSelected = () => {
                                       }
                                   }) {


    // const [model, setModel] = useState(new DiagramModel())

    const lastSaved = useRef(diagramNodes);
    const {systemId} = useParams();

    if (lastSaved.current.length === 0 && diagramNodes.length !== 0)
        lastSaved.current = diagramNodes

    const handleModelChange = useCallback(debounce((serialized) => {
        const nodes = []
        const updated = serialized


        // ora oraganizzo il tutto per averlo più conforme e rimuovendo ogni roba inutile

        // prima estraggo tutti i nodi dal modello serializzato
        updated.layers.forEach(layer => {
            if (layer.type === "diagram-nodes") {
                Object.values(layer.models).forEach(node => {
                    let isDummy = node.type === "dummy-node"

                    let payload = {
                        isComponent: !isDummy,
                        parallelKind: node.parallelKind,
                        isStandBy: node.standBy,
                        partialK: node.partialK,
                        fractionalFactor: node.fractionalFactor,
                        id: node.id,
                        xPos: node.x,
                        yPos: node.y,
                        inPort: {
                            id: node.inPort,
                            destinationPorts: []
                        },
                        outPort: {
                            id: node.outPort,
                            destinationPorts: []
                        }
                    }

                    nodes.push(payload)
                })
            }
        })


        // ora devo aggiornare i links (destinations)
        updated.layers.forEach(layer => {
            if (layer.type === "diagram-links") {
                Object.values(layer.models).forEach(link => {
                    const source = link.sourcePort
                    const target = link.targetPort

                    // mi assicuro che la relazione sia connessa in entrambe le porte
                    if (source && target)
                        // ora cerco il nodo con la porta source e aggiungo una destination target
                        nodes.forEach(node => {
                            // mappo il source con la sua porta di destinazione target
                            if (node.inPort.id === source)
                                node.inPort.destinationPorts.push(target)
                            else if (node.outPort.id === source)
                                node.outPort.destinationPorts.push(target)
                            // mappo il target con la sua porta di destinazione source
                            if (node.inPort.id === target)
                                node.inPort.destinationPorts.push(source)
                            else if (node.outPort.id === target)
                                node.outPort.destinationPorts.push(source)

                        })

                })
            }
        })

        // ora controllo se c'è qualcosa di nuovo...

        // console.log(nodes)
        // console.log(lastSaved.current)
        let diff = checkDifference(nodes, lastSaved.current)

        if (diff) {
            console.log("EVENTO")
            let sizeDiff = lastSaved.current.length !== nodes.length
            lastSaved.current = nodes
            onUpdate(nodes, systemId, sizeDiff)
            engine.repaintCanvas()
        } else
            console.log("event but no changes")

    }, 1000), [systemId])

    useEffect(() => {

        const model = new DiagramModel()

        // dizionario che contiene tutte le porte
        const inPorts = []
        const outPorts = []

        // console.log(diagramNodes)

        // aggiungo i nodi
        diagramNodes.forEach(serverNode => {
            let node
            if (serverNode.isComponent)
                // dichiaro il nodo
                node = new ComponentNode(serverNode);
            else {
                node = new DummyNode(serverNode);
                // ora guardo il tipo di porta
            }

            if (serverNode.parallelKind)
                node.setParallelKind(serverNode.parallelKind)

            node.setPosition(serverNode.xPos, serverNode.yPos)

            // creo le porte in e out

            // const inPort = node.addInPort("IN")
            // const outPort = node.addOutPort("OUT")

            const inPort = node.getInPort()
            const outPort = node.getOutPort()

            // salvo le porte nel dizionario
            outPorts[serverNode.outPort.id] = {ref: outPort, obj: serverNode.outPort}
            inPorts[serverNode.inPort.id] = {ref: inPort, obj: serverNode.inPort}

            // aggiungo il nodo
            model.addNode(node)

            node.registerListener({
                paramChange: () => handleModelChange(model.serialize()),
                positionChanged: () => handleModelChange(model.serialize()),
                entityRemoved: () => handleModelChange(model.serialize()),

                selectionChanged: (element) => {
                    if (element.isSelected)
                        onComponentSelected(element.entity.options.componentId)
                    else
                        onComponentSelected(undefined)
                }
            })

            outPort.registerListener({
                reportInitialPosition: () => handleModelChange(model.serialize())
            });

            inPort.registerListener({
                reportInitialPosition: () => handleModelChange(model.serialize()),
            });


        })

        // ora creo i link, mi smazzo tutte le porte in out e cerco i link nelle porte in in
        outPorts.forEach(port => {

            // per ogni destinazione ne creo un link
            port.obj.destinationPorts.forEach(id => {
                // cerco il link nella lista delle porte in
                let inPort = inPorts[id];

                if (!inPort)
                    console.log("missing destination port, caos rising! Port: " + port.obj.id + " search " + id)
                else {
                    // creo il link
                    let link = port.ref.link(inPort.ref);
                    model.addLink(link)
                }


            })

        })

        model.registerListener({
            linksUpdated: () => handleModelChange(model.serialize()),
        });
        engine.setModel(model);
        engine.repaintCanvas()


    }, [diagramNodes, handleModelChange, onComponentSelected])


    return (

        <CanvasWidget allowLooseLinks={false}
                      className={"diagramContainer"} engine={engine}/>

    );
}
