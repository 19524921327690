import React from 'react';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";


export default function AddButton({label, children, hidden, ...props}) {

    const CustomButton = withStyles((theme) => ({
        root: {
            color: theme.palette.getContrastText("#fff"),
            backgroundColor: "#fff",
            borderRadius: 100,
            display: hidden ? "none" : "",
            '&:hover': {
                backgroundColor: "#fff",
            },
        },
    }))(Button);

    return (
        <Box hidden={hidden}>
            <CustomButton
                startIcon={<AddIcon/>}
                variant="contained"
                {...props} >
                {label}{children}
            </CustomButton>
        </Box>
    );
}
