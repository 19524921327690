import React from 'react';
import Grid from "@material-ui/core/Grid";
import GridItemTitle from "./GridItemTitle";


export default function GridItemColumn({label, spacing = false, align="left", children}) {

    return (

        <>
            {spacing ? <Grid item xs={1}/> : null}
            <GridItemTitle>
                {label}
            </GridItemTitle>
            <Grid item xs={3} style={{textAlign:align}}>
                {children}
            </Grid>
        </>

    );
}
