import React from 'react';

import {Route, Switch} from "react-router-dom";
import ComponentDetailScreen from "./detail/ComponentDetailScreen";


export default function ComponentRouter() {
    return (
        <Switch>
            <Route exact path="/system/:systemId/c/:compId" component={ComponentDetailScreen}/>
        </Switch>
    );
}
