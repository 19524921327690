import React, {useContext, useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import {useHistory, useParams} from "react-router-dom";


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select, {SelectChangeEvent} from '@material-ui/core/Select';

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {DialogActions, Divider, Typography} from "@material-ui/core";
import {AppContext} from "../../App";
import {
    fetchBlob,
    fetchSimulationComponentParams,
    fetchSimulationComponents,
    fetchSimulationDetail
} from "../../utils/apiCaller";
import DetailTitle from "../../components/DetailTitle";
import AdvancedTable from "../../components/AdvancedTable";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import PublishIcon from '@material-ui/icons/Publish';
import {postData} from "../../utils/dryICE";

export default function HistoryDetailScreen() {

    const componentsFields = [
        {key: "componentName", name: "Component"},
        {key: "distribution", name: "Distribution"},
        {key: "usedVendorParams", name: "Used vendor params?", boolean: true},
        {key: "reliabilityText", name: "Reliability"},
        {key: "mttfText", name: "Mean time to failure"}
    ]

    const [state, dispatch] = useContext(AppContext);
    const [simExecution, setSimExecution] = useState({loading: true});
    const [image, setImage] = useState(null);
    const [imageZoom, setImageZoom] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [selectedComponentPlot, setSelectedComponentPlot] = useState(null);
    const [selectedComponentEstimation, setSelectedComponentEstimation] = useState(null);

    const {systemId, simId} = useParams();
    const history = useHistory();

    if (simExecution.missionDurationText) {
        componentsFields.push({key: "missionReliabilityText", name: "Reliability during mission"})
    }

    const noDuration = simExecution.missionDuration === 0

    useEffect(() => {
        fetchSimulationDetail(state.user.jwt, simId)
            .then(r => {
                setSimExecution(r)
            })

        fetchBlob(state.user.jwt, "/data/simulation/" + simId + "/snap.png")
            // metto l'immagine nello stato
            .then(image => setImage(image))
    }, [state.user.jwt, simId])

    const handleDebugDownload = () => {
        fetchBlob(state.user.jwt, "/data/simulation/" + simId + "/" + selectedComponent.componentId + "/debug.json")
            .then(blob => {
                // const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = blob;
                // the filename you want
                a.download = simId + "-" + selectedComponent.componentId + '-debug.json';
                document.body.appendChild(a);
                a.click();
            })
            .catch(() =>
                dispatch({type: "SHOW_ALERT", payload: {message: "No file available", severity: "error"}})
            )
    }

    useEffect(() => {
        if (!!selectedComponent) {
            fetchBlob(state.user.jwt, "/data/simulation/" + simId + "/" + selectedComponent.componentId + "/plot.png")
                // metto l'immagine nello stato
                .then(image => setSelectedComponentPlot(image))

            fetchSimulationComponentParams(state.user.jwt, simId, selectedComponent.componentId)
                .then(r => setSelectedComponentEstimation(r))
        }
    }, [selectedComponent, simId, state.user.jwt])

    const round = (val) => {
        return Math.round((val + Number.EPSILON) * 1000) / 1000
    }

    const handleOnPublish = () => {

        if (company && company !==""){
            let timestamp = new Date(simExecution.time * 1000).toISOString();

            /*
            data: {
                reclaimTenant: String, “Gorenje”
                reclaimComponentId: String, “Cell A_rotating table”
                timestamp: “2022-07-14T13:32:25.936000”
                failureProbability: 0.856,
                simulationId: 26,
                missionDurationSeconds: 86400,
                missionFailureProbability: 0.856,
                missionReliability: 0.1833,
                mttfHours: 479.3436,
                mttfText: “19d 23h 20m 36s”,
                reliability: 0.1893,
                systemId: 3,
            }
            */

            const data = {
                    reclaimComponentId: simExecution.systemName,
                    reclaimTenant: company,
                    timestamp: timestamp,
                    failureProbability: simExecution.failureProbability,
                    simulationId: simExecution.id,
                    missionDurationSeconds: simExecution.missionDuration,
                    missionFailureProbability: simExecution.missionFailureProbability,
                    missionReliability: simExecution.missionReliability,
                    mttfHours: simExecution.mttf,
                    mttfText: simExecution.mttfText,
                    reliability: simExecution.reliability,
                    systemId: simExecution.systemId,
            }

            postData("supsirat", data)
                .then(r =>
                    dispatch({type: "SHOW_ALERT", payload: {message: "Data published successfully"}})
                )
                .catch(() =>
                    dispatch({type: "SHOW_ALERT", payload: {message: "Unable to upload data to dryIce", severity: "error"}})
                )
        }else{
            dispatch({type: "SHOW_ALERT", payload: {message: "Unable to upload data to dryIce: company must be selected!", severity: "error"}})
        }
    }

    const [company, setCompany] = React.useState('');

    const handleOnCompanyChange = (event: SelectChangeEvent) => {
        setCompany(event.target.value);
    };

    return (
        <Box p={2}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Grid item xs={1}>
                            <Button variant={"text"}
                                    color={"primary"}
                                    onClick={() => history.push("/rbd/" + systemId + "/history")}
                                    startIcon={<ArrowBackIcon/>}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant={"button"} color={"primary"}>
                                RBD / History / <u>Simulation</u>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid item></Grid>
                    <DetailTitle tooltip={" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed dui pulvinar, porttitor libero id, vestibulum enim."}>
                        Details
                    </DetailTitle>
                </Grid>
                <Grid item container justify={"flex-end"} xs={12}>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Company</InputLabel>
                            <Select labelId="demo-simple-select-label" value={company} label="Company" onChange={handleOnCompanyChange}>
                                <MenuItem value={"Gorenje"}>Gorenje</MenuItem>
                                <MenuItem value={"Podium"}>Podium</MenuItem>
                                <MenuItem value={"Zorluteks"}>Zorluteks</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Box m={2}>
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                onClick={handleOnPublish}
                                startIcon={<PublishIcon/>}>
                                publish to dryice
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <img
                                    onClick={() => setImageZoom(true)}
                                    style={{width: "100%", cursor: "pointer"}}
                                    alt={"diagram snapshot"}
                                    src={image}
                                />

                                <Dialog maxWidth={"lg"} onClose={() => setImageZoom(false)} open={imageZoom && !!image}>
                                    <img
                                        style={{width: "100%"}}
                                        alt={"diagram snapshot"}
                                        src={image}
                                    />
                                </Dialog>

                            </Grid>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    {!noDuration && <>
                                        <Grid item xs={6}>
                                            <Typography variant={"h6"} color={"primary"}>
                                                Mission failure probability
                                            </Typography></Grid>
                                        <Grid item xs={6}><Typography
                                            variant={"h6"}>{simExecution.missionFailureProbabilityText}</Typography></Grid>
                                    </>}
                                    <Grid item xs={6}><Typography variant={"h6"} color={"primary"}>
                                        Failure probability
                                    </Typography></Grid>
                                    <Grid item xs={6}><Typography
                                        variant={"h6"}>{simExecution.failureProbabilityText}</Typography></Grid>
                                    <Grid item xs={6}><Typography variant={"h6"} color={"primary"}>Mean time to
                                        failure</Typography></Grid>
                                    <Grid item xs={6}><Typography
                                        variant={"h6"}>{simExecution.mttfText}</Typography></Grid>
                                    {!noDuration &&
                                        <Grid item xs={6}><Typography variant={"h6"} color={"primary"}>Mission
                                            duration</Typography></Grid>}
                                    {!noDuration && <Grid item xs={6}><Typography
                                        variant={"h6"}>{simExecution.missionDurationText}</Typography></Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>


                <Grid item xs={12}>
                    <DetailTitle
                        tooltip={" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sed dui pulvinar, porttitor libero id, vestibulum enim."}
                    >
                        Components
                    </DetailTitle>
                </Grid>

                <Grid item xs={12}>
                    <Box ml={5}>
                        <AdvancedTable
                            onSelected={(c) => {
                                if (!c.usedVendorParams)
                                    setSelectedComponent(c)
                            }}
                            style={{padding: 8}}
                            jwt={state.user.jwt}
                            filterName={""}
                            fields={componentsFields}
                            fetchingPageFunction={fetchSimulationComponents}
                            requestArgs={{simId: simId}}
                        />
                    </Box>
                </Grid>

            </Grid>

            <Dialog open={!!selectedComponent} onClose={() => {
                setSelectedComponent(null)
                setSelectedComponentPlot(null)
                setSelectedComponentEstimation(null)
            }}>
                {selectedComponent &&
                    <DialogTitle>
                        {selectedComponent.componentName}
                        <Divider/>
                    </DialogTitle>
                }
                {selectedComponent &&
                    <DialogContent>
                        {selectedComponentEstimation &&
                            <Grid container spacing={2}>
                                <Grid item xs={6}><Typography color={"primary"} variant={"h6"}>Mean absolute
                                    error</Typography></Grid>
                                <Grid item xs={6}><Typography color={"primary"} variant={"h6"}>R
                                    Squared</Typography></Grid>
                                <Grid item xs={6}><Typography
                                    variant={"h6"}>{round(selectedComponentEstimation.mae)}</Typography></Grid>
                                <Grid item xs={6}><Typography
                                    variant={"h6"}>{round(selectedComponentEstimation.r2Score)}</Typography></Grid>
                                <Grid item xs={12}>
                                    <img src={selectedComponentPlot} alt={"plot"} style={{width: "100%"}}/>
                                </Grid>

                                {Object.keys(selectedComponentEstimation.estimated).map(key =>
                                    <Grid item key={key} xs={4}>
                                        {key}: {selectedComponentEstimation.estimated[key]}
                                    </Grid>
                                )}
                            </Grid>
                        }
                    </DialogContent>
                }
                <DialogActions>
                    <Button onClick={handleDebugDownload}>Export</Button>
                </DialogActions>
            </Dialog>
        </Box>

    )
}
