import React, {useContext, useEffect, useState} from 'react';
import {NavLink, Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {AppContext} from "../App";
import {checkToken} from "../utils/utils";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import UserEntry from "./UserEntry";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
import SystemSelectionDialog from "../dialogs/system/SystemSelectionDialog";
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import Grid from "@material-ui/core/Grid";
import AddButton from "../components/AddButton";
import FailureEventCreationDialog from "../dialogs/failure/FailureEventCreationDialog";
import {putNewFailure} from "../utils/apiCaller";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AdministrationRouter from "../screens/administration/AdministrationRouter";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import SystemRouter from "../screens/system/SystemRouter";
import Typography from "@material-ui/core/Typography";
import RBDRouter from "../screens/rbd/RBDRouter";
import ProcessRouter from "../screens/process/ProcessRouter";
import FailureModeDialog from "../dialogs/failureMode/FailureModeDialog";
import LandingScreen from "../screens/landing/LandingScreen";
import FmeaRouter from "../screens/fmea/FmeaRouter";
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from '@material-ui/icons/History';
import Link from "@material-ui/core/Link";
import FailureRouter from "../screens/failure/FailureRouter";
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
        },
        content: {
            marginTop: 64,
            marginLeft: drawerWidth
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper
        },
        fullHeight: {
            ...theme.mixins.toolbar,
            marginTop: 1
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        bottomList: {
            position: "absolute",
            width: "100%",
            bottom: 0,

        }
    }))
;

const getOpenMenu = (pathName) => {
    if (
        pathName.startsWith("/rbd") ||
        pathName.startsWith("/fmea")
    ) return {analysis: true}
    else if (
        pathName.startsWith("/system") ||
        pathName.startsWith("/process") ||
        pathName.startsWith("/failure")
    ) return {prodSys: true}
    else if (
        pathName.startsWith("/admin")
    ) return {admin: true}
    else if (
        pathName.startsWith("/help")
    ) return {help: true}
    else
        return {}
}

export function AppMainContent({location}) {
    // dal contesto dell'applicazione ottengo lo stato
    const [state, dispatch] = useContext(AppContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const uDetailOpen = Boolean(anchorEl);

    const [showSelectionDialog, setShowSelectionDialog] = React.useState(false);
    const [showFailureDialog, setShowFailureDialog] = React.useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const history = useHistory();
    const [newFailureTypDialogOpen, setNewFailureTypDialogOpen] = useState(false);

    const [menuOpen, setMenuOpen] = React.useState({});

    useEffect(() => {
        setMenuOpen(getOpenMenu(location.pathname))
    }, [location])

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSystemSelected = (system) => {
        dispatch({
            type: "SYSTEM_CHOSEN",
            payload: system
        })
        setShowSelectionDialog(false)
        history.push("/rbd/" + system.id)
    }

    const handleNewFailureEvent = (failure) => {
        setShowFailureDialog(false)
        putNewFailure(state.user.jwt, failure)
            .catch(alert)
            .then(() => setSnackOpen(true))
    }

    const classes = useStyles();

    // se non autenticato allora rimando al login
    if (!state.user || !checkToken(state.user.jwt))
        return <Redirect to={"/login?expired=true"}/>


    // const isAdmin = state.user.isAdmin;
    return (
        <div>

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Box ml={4} hidden={!(location.pathname==="/fmea")}>
                        <AddButton onClick={() => setNewFailureTypDialogOpen(true)}>
                            Failure mode
                        </AddButton>
                    </Box>
                    <Box ml={4} hidden={!location.pathname.startsWith("/rbd")}>
                        <Typography variant={"h6"} component={"span"} color={"primary"}>
                            Selected system:
                        </Typography>
                        {state.system ?
                            <Button variant={"outlined"} onClick={() => setShowSelectionDialog(true)}
                                    style={{marginLeft: 16, marginTop:-5}}>
                                {(state.system.name.length > 25) ?
                                    state.system.name.substr(0, 25) + '…' :
                                    state.system.name
                                }
                                <ExpandMoreIcon/></Button>
                            :
                            <Button variant={"outlined"} onClick={() => setShowSelectionDialog(true)}
                                    style={{marginLeft: 16, marginTop:-5}}>
                                <Typography variant={"body2"}>
                                    <i>None</i>
                                </Typography>
                            </Button>
                        }
                    </Box>


                    <Grid container alignItems="center"
                          justify="flex-end"
                          style={{marginLeft: "auto", width: "50%", color: "white"}}>
                        <Grid item>
                            <Box mr={4} hidden={!location.pathname.startsWith("/rbd/")}>
                                <IconButton component={NavLink} to={!state.system ? "": "/rbd/" + state.system.id + "/history"}>
                                    <HistoryIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Avatar onClick={handleMenu} style={{cursor: "pointer"}}>
                                {state.user.name.substr(0, 1).toUpperCase()}
                            </Avatar>
                        </Grid>
                    </Grid>


                </Toolbar>


            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >

                <img alt={"logo"} onClick={() => history.push("/")}
                     src={"/banner.png"} style={{width: "100%", cursor:"pointer"}}/>
                <Divider/>
                <div className={classes.drawerContainer}>
                    <List>
                        <ListItem button onClick={() => setMenuOpen({prodSys: !menuOpen.prodSys})}>
                            <ListItemText primary={"Production system"}/>
                            {menuOpen.prodSys ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={menuOpen.prodSys} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginLeft: 10}}>
                                <ListItem button to="/process" component={NavLink} activeClassName="Mui-selected">
                                    <ListItemIcon> <AccountTreeIcon/> </ListItemIcon>
                                    <ListItemText primary={"Processes"}/>
                                </ListItem>
                                <ListItem button to="/system" component={NavLink} activeClassName="Mui-selected">
                                    <ListItemIcon><AccountTreeIcon/></ListItemIcon>
                                    <ListItemText primary={"Systems"}/>
                                </ListItem>
                                <ListItem button to="/failure" component={NavLink} activeClassName="Mui-selected">
                                    <ListItemIcon><AccountTreeIcon/></ListItemIcon>
                                    <ListItemText primary={"Failures"}/>
                                </ListItem>
                            </List>
                        </Collapse>

                        <ListItem button onClick={() => setMenuOpen({analysis: !menuOpen.analysis})}>
                            <ListItemText primary={"Analysis"}/>
                            {menuOpen.analysis ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={menuOpen.analysis} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginLeft: 10}}>
                                <ListItem button to="/fmea" component={NavLink} activeClassName="Mui-selected">
                                    <ListItemIcon> <AccountTreeIcon/> </ListItemIcon>
                                    <ListItemText primary={"FMEA"}/>
                                </ListItem>
                                <ListItem button
                                          to={state.system ? `/rbd/${state.system.id}` : "/rbd"}
                                          component={NavLink} activeClassName="Mui-selected">
                                    <ListItemIcon><AccountTreeIcon/></ListItemIcon>
                                    <ListItemText primary={"RBD"}/>
                                </ListItem>
                            </List>
                        </Collapse>

                    </List>

                    <Box m={2}>
                        <AddButton style={{width: "100%"}} onClick={() => setShowFailureDialog(true)}>
                            Failure event
                        </AddButton>
                    </Box>
                    <List className={classes.bottomList}>

                        <Divider/>
                        <ListItem button component={Link} target={"_blank"}
                                  href={"https://wiki-rat.spslab.ch/books/fmea"}>
                            <ListItemText primary={"Help"}/>
                            <LaunchIcon/>
                        </ListItem>

                        <ListItem style={{display:state.user.isAdmin?"":"none"}}
                                  button onClick={() => setMenuOpen({admin: !menuOpen.admin})}>
                            <ListItemText primary={"Administration"}/>
                            {menuOpen.admin ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>

                        <Collapse in={menuOpen.admin} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginLeft: 10}}>
                                <ListItem button exact to="/admin" component={NavLink} activeClassName="Mui-selected">
                                    <ListItemIcon><AccountTreeIcon/></ListItemIcon>
                                    <ListItemText primary={"Users"}/>
                                </ListItem>
                                <ListItem
                                    button to="/admin/distribs" component={NavLink} activeClassName="Mui-selected">
                                    <ListItemIcon><AccountTreeIcon/></ListItemIcon>
                                    <ListItemText primary={"Distributions"}/>
                                </ListItem>
                            </List>
                        </Collapse>
                    </List>
                </div>
            </Drawer>

            {/* popover per il menu utente in alto a destra*/}
            <Popover
                style={{zIndex: 1400 + 2} /*to compensate the height of the AppBar*/}
                open={uDetailOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <UserEntry userDetails={state.user} dispatch={dispatch}/>
            </Popover>

            <SystemSelectionDialog onSelected={handleSystemSelected}
                                   open={showSelectionDialog}
                                   onClose={() => setShowSelectionDialog(false)}
            />

            <FailureEventCreationDialog onSave={handleNewFailureEvent}
                                        open={showFailureDialog}
                                        onClose={() => setShowFailureDialog(false)}/>

            <main className={classes.content}>
                <Switch>
                    <Route path="/rbd" component={RBDRouter}/>
                    <Route path="/system" component={SystemRouter}/>
                    <Route path="/failure" component={FailureRouter}/>
                    <Route path="/fmea" component={FmeaRouter}/>
                    <Route exact path="/" component={LandingScreen}/>
                    <Route path="/process" component={ProcessRouter}/>
                    <Route path="/admin" component={AdministrationRouter}/>
                </Switch>
            </main>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>

            <Snackbar open={!!state.alert && state.alert.display}
                      autoHideDuration={state.alert ? state.alert.autoHideDuration : 2000}
                      onClose={(e, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }

                          dispatch({
                              type: "HIDE_ALERT"
                          })
                      }}

                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                {state.alert && <Alert severity={state.alert ? state.alert.severity : ""}>
                    <Grid container>
                        <Grid item xs={state.alert.autoHideDuration === null ? 11 : 12}>
                            {state.alert ?
                                (Array.isArray(state.alert.message) ?
                                    <ul>
                                        {state.alert.message.map(txt => <li key={txt}>{txt}</li>)}
                                    </ul>
                                    : state.alert.message)
                                : ""}
                        </Grid>
                        {state.alert.autoHideDuration === null &&
                        <Grid item xs={1}>

                            <IconButton size="small"
                                        onClick={() => dispatch({
                                            type: "HIDE_ALERT"
                                        })}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>

                        </Grid>
                        }
                    </Grid>

                </Alert>}
            </Snackbar>

            <FailureModeDialog
                creationOnly={true}
                open={newFailureTypDialogOpen}
                onClose={() => setNewFailureTypDialogOpen(false)}
                onSave={() => {
                    // setRefreshModesTable(true)
                    setNewFailureTypDialogOpen(false)
                    setSnackOpen(true)
                }}
            />
        </div>
    )
}


export default AppMainContent;