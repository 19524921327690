import React from 'react';
import Grid from "@material-ui/core/Grid";


export default function WizardStepElement({children, ...props}) {


    return (
        <Grid item xs={12} {...props}>
            {children}
        </Grid>
    );
}
