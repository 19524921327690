export const API = "/api"

// methodo per generalizzare una richiesta https
const performCall = (path, headers, method, body = null, propertyCheck) => {

    if (body) {
        body = JSON.stringify(body)
    }

    // ritorno una promise
    return new Promise((resolve, reject) => {
        let server = window._env_.API_URL
        if(!server)
            server = window.location.hostname + ":8080"

        fetch(server + path, {
            headers: headers,
            body: body,
            method: method
        })
            .then(response => {
                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400)
                        // converto il risultato in oggetto js (json)
                        response.json().then(json => {
                            if (!propertyCheck || json.hasOwnProperty(propertyCheck)) {
                                resolve(json)
                            } else
                                reject("Missing prop " + propertyCheck)
                        })
                    // altrimenti passo da qui e ritorno errore
                    else
                        response.json()
                            .then(json => {
                                console.log(json)
                                reject(json)
                            })
                            .catch(() => reject({message: "error unknown"}))
                }
            )
    })
}

export const authenticate = (email, password) => {
    return performCall(
        `/authenticate`,
        {
            'Content-Type': 'application/json'
        },
        "POST",
        {
            email: email,
            password: password
        }
    )
}

export const fetchUserDetail = (jwt) => {
    return performCall(
        API + `/user`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchUserIdDetail = (jwt, userId) => {
    return performCall(
        API + `/user/${userId}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchDistributionDetail = (jwt, id) => {
    return performCall(
        API + `/distribution/${id}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchSystemDetail = (jwt, id) => {
    return performCall(
        API + `/system/${id}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchProcessDetail = (jwt, id) => {
    return performCall(
        API + `/process/${id}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchSimulationDetail = (jwt, id) => {
    return performCall(
        API + `/system/simulation/${id}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchSimulationComponentParams = (jwt, simId,componentId) => {
    return performCall(
        `/data/simulation/${simId}/${componentId}/params.json`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchComponentDetail = (jwt, id) => {
    return performCall(
        API + `/component/${id}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchFailureDetail = (jwt, id) => {
    return performCall(
        API + `/failure/${id}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchFailureModeDetail = (jwt, id) => {
    return performCall(
        API + `/failureMode/${id}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchLastCreatedSystem = (jwt) => {
    return performCall(
        API + `/system/last`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchUserSystemsPage = ({jwt, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/system/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchUserProcessesPage = ({jwt, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/process/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchUserFailuresPage = ({jwt, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/failure/user/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllUserFailures = (jwt) =>{

    return performCall(
        API + `/failure/user/list`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchSystemRBDHistoryPage = ({systemId,jwt, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/system/${systemId}/simulations?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchSystemComponentsPage = ({jwt,systemId, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/system/${systemId}/components?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchUserSpecificSystemsPage = ({jwt, userId, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/user/${userId}/systems?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchComponentFailureModePage = ({jwt, componentId, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/failureMode/page/component/${componentId}?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchProcessFailureModePage = ({jwt, processId,  page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/failureMode/page/process/${processId}?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchProcessFailureEligiblePage = ({jwt, componentId,  page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/failureMode/page/eligible/${componentId}?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchFailureEventPage = ({jwt, componentId, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/failure/page/${componentId}?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchSimulationComponents = ({jwt, simId, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/system/simulation/${simId}/components?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchProcessSystemsPage = ({jwt, processId, page, size, sortDir, sortField}) =>{

    return performCall(
        API + `/process/${processId}/systems/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchUsersPage = ({jwt, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/user/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchDistributionsPage = ({jwt, page, size, sortDir, sortField, search = ""}) =>{

    return performCall(
        API + `/distribution/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllSystemComponents = (jwt, systemId) =>{

    return performCall(
        API + `/system/${systemId}/components/list`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllUserProcesses = (jwt) =>{

    return performCall(
        API + `/process/list`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllUserSystems = (jwt) =>{

    return performCall(
        API + `/system/list`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllComponentProcess = (jwt, componentId) =>{

    return performCall(
        API + `/component/${componentId}/processes`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllDistributions = (jwt) => {
    return performCall(
        API + `/distribution/list`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchAllDistributionParams = (jwt, distId) => {
    return performCall(
        API + `/distribution/${distId}/params`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchSuggestedDistribution = (jwt, componentId) => {
    return performCall(
        API + `/component/${componentId}/suggestDistribution`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "get"
    )
}

export const fetchAllSystemDiagramNodes = (jwt, systemId) =>{

    return performCall(
        API + `/system/${systemId}/diagram`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllComponentFailureModes = (jwt, componentId) =>{

    return performCall(
        API + `/failureMode/all/${componentId}`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllProcessFailureModes = (jwt, processId) =>{

    return performCall(
        API + `/process/${processId}/modes`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const fetchAllFailureModes = (jwt) =>{

    return performCall(
        API + `/failureMode/all`,
        {
            'Authorization': 'Bearer ' + jwt.token
        },
        "get"
    )
}

export const putNewSystem = (jwt, data) =>{

    return performCall(
        API + `/system`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data
    )
}

export const postSystemUpdate = (jwt, data) =>{

    return performCall(
        API + `/system`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const postPasswordReset = (data) =>{

    return performCall(
        API + `/user/reset`,
        {
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const putNewComponent = (jwt, data) =>{

    return performCall(
        API + `/component`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data
    )
}

export const putSystemToProcess = (jwt, processId, systemId) =>{

    return performCall(
        API + `/process/${processId}/system?systemId=${systemId}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT"
    )
}

export const putNewProcess = (jwt, data) =>{

    return performCall(
        API + `/process`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data
    )
}

export const putNewDistribution = (jwt, data) =>{

    return performCall(
        API + `/distribution`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data
    )
}

export const putNewUser = (data) =>{

    return performCall(
        `/register`,
        {
            'Content-Type': 'application/json'
        },
        "PUT",
        data
    )
}

export const putNewFailureMode = (jwt, data) =>{

    return performCall(
        API + `/failureMode`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data
    )
}

export const putNewFailure = (jwt, data) =>{

    return performCall(
        API + `/failure`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data
    )
}

export const putComponentToDiagram = (jwt, componentId) => {
    return performCall(
        API + `/component/${componentId}/diagram`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "PUT"
    )
}

export const postComponentUpdate = (jwt, data) =>{

    return performCall(
        API + `/component`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const postDistributionUpdate = (jwt, data) =>{

    return performCall(
        API + `/distribution`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const postFailureUpdate = (jwt, data) =>{

    return performCall(
        API + `/failure`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const postProcessUpdate = (jwt, data) =>{

    return performCall(
        API + `/process`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const postFailureModeUpdate = (jwt, data) =>{

    return performCall(
        API + `/failureMode`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const postUserUpdate = (jwt, data) =>{

    return performCall(
        API + `/user`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const updateAllSystemDiagramNodes = (jwt, systemId, data) =>{

    return performCall(
        API + `/system/${systemId}/diagram`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "POST",
        data
    )
}

export const performSimulation = (jwt, systemId,missionDuration, image, customNow) => {
    return performCall(
        API + `/system/${systemId}/simulate?now=${customNow}`,
        {
            'Authorization': 'Bearer ' + jwt.token,
            'Content-Type': 'application/json'
        },
        "post",
        {image:image, missionDuration:missionDuration}
    )
}


export const addDummy = (jwt, systemId) => {
    return performCall(
        API + `/system/${systemId}/diagram/addDummy`,
        {
            'Authorization': 'Bearer ' + jwt.token,
        },
        "get"
    )
}

export const fetchBlob = (jwt, subUrl) => {

    // ritorno una promise
    return new Promise((resolve, reject) => {
        fetch(window._env_.API_URL + subUrl, {
            headers: {
                'Authorization': 'Bearer ' + jwt.token,
            },
            method: 'GET'
        })
            .then(response => {

                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400)
                        // converto il risultato in testo
                        response.blob().then(blob => {
                            resolve(URL.createObjectURL(blob))
                        })
                    // altrimenti passo da qui e ritorno errore
                    else
                        reject(response.status)
                }
            )
    })
}






