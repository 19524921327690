import React, {useCallback, useEffect, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import {debounce} from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";


export default function AdvancedTable({
                                          jwt,
                                          filterName,
                                          fetchingPageFunction,
                                          onSelected,
                                          fields,
                                          requestArgs,
                                          mustRefresh,
                                          onRefreshed,
                                          debug = false,
                                          tableProps = {},
                                          ...props
                                      }) {

    // stato che contiene la pagina
    const [listData, setListData] = useState({
        content: [],
        size: 5,
        totalElements: 0,
        totalPages: 0,
        number: 0,
        sort: {
            direction: "DESC",
            property: "id"
        },
        loaded: false
    });

    const skeletons = ['20%', '10%', '18%', '30%', '5%']

    // se canbia una propietà nel sorting
    const handleTableSortingChange = (field) => {
        // allora, controllo se il campo è quello che attualmente sta ordinando la lista
        if (field === listData.sort.property) {
            // in questo caso devo invertire l'ordine di ordinamento
            let direction = listData.sort.ascending ? "DESC" : "ASC"
            handleTableChange(jwt, listData.number, listData.size, filterName, direction, field)
        }

        // altrimenti cambio il field
        else {
            handleTableChange(jwt, listData.number, listData.size, filterName, "ASC", field)
        }
    }


    // cambiamento pagina
    const handleTableChange = useCallback(debounce((token,
                                                    page,
                                                    size,
                                                    filter = "",
                                                    sortDir = listData.sort.direction,
                                                    sortField = listData.sort.property
    ) => {

        const args = {
            jwt: token,
            page: page,
            size: size,
            sortDir: sortDir,
            sortField: sortField,
            search: filter,
            ...requestArgs
        }

        fetchingPageFunction(args)
            .then(result => setListData(result))
            .catch(result => alert(result))
    }, 250), [])


    // // primo caricamento
    // useEffect(() => {
    //     handleTableChange(jwt, 0, 5)
    //
    // }, [jwt, handleTableChange])
    //
    useEffect(() => {

        handleTableChange(jwt, 0, 5, filterName)

    }, [jwt, filterName, handleTableChange])

    // caricamento su richiesta
    useEffect(() => {
        if (mustRefresh) {
            handleTableChange(jwt, 0, 5)
            onRefreshed();
        }

    }, [jwt, handleTableChange, mustRefresh, onRefreshed])

    if (debug)
        console.log(listData)

    return (

        <div {...props}>
            <TableContainer>
                <Table {...tableProps}>
                    <TableHead>
                        <TableRow>
                            {fields.map(field =>
                                <TableCell style={{maxWidth: field.maxWidth}} key={field.key}>
                                    <TableSortLabel
                                        active={listData.sort.property === field.key || listData.sort.property === field.sort}
                                        direction={listData.sort.ascending ? "asc" : "desc"}
                                        onClick={() => handleTableSortingChange(field.sort ? field.sort : field.key)}
                                    >
                                        {field.name}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listData.loaded === false ?
                            // se la propietà loaded è false allora mostro gli skeleton
                            skeletons.map(width => (
                                <TableRow key={width}>
                                    {fields.map(field =>
                                        <TableCell key={"fi_" + field.key}>
                                            <Skeleton style={{width: Math.floor(Math.random() * 90) + 10}}
                                                      variant="text"/>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))

                            :

                            // renderizzo le cose normali ricevute dal server
                            listData.content.map(row => (
                                <TableRow hover key={row.id} style={{cursor: "pointer"}}
                                          onClick={() => onSelected(row)}>
                                    {fields.map(field =>
                                        <TableCell
                                            style={{maxWidth: field.maxWidth,
                                            // textAlign: typeof row[field.key] == 'number'? "right" : "left"
                                            }}
                                            key={"fi_" + field.key}>
                                            {field.boolean ? row[field.key] ? "Yes" : "No" : row[field.key]}
                                        </TableCell>
                                    )}

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={listData.totalElements}
                rowsPerPage={listData.size}
                page={listData.number}
                onChangePage={(event, page) => handleTableChange(jwt, page, listData.size, filterName)}
                onChangeRowsPerPage={(event) => handleTableChange(jwt, listData.number, parseInt(event.target.value), filterName)}
            />
        </div>

    );
}
