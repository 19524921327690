import React, {useContext, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {fetchDistributionsPage, putNewDistribution} from "../../../utils/apiCaller";
import {AppContext} from "../../../App";
import SearchIcon from '@material-ui/icons/Search';
import AdvancedTable from "../../../components/AdvancedTable";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useHistory} from "react-router-dom";
import Box from "@material-ui/core/Box";
import AddButton from "../../../components/AddButton";
import DistributionCreationDialog from "../../../dialogs/distribution/DistributionCreationDialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";


export default function DistributionsScreen() {

    const history = useHistory();
    const [state] = useContext(AppContext);
    const [searchFilter, setSearchFilter] = useState("");
    const [searchFilterValue, setSearchFilterValue] = useState("");

    const [showCreationDialog, setShowCreationDialog] = useState(false);

    const [refreshTable, setRefreshTable] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const handleDistributionSave = (payload) => {

        putNewDistribution(state.user.jwt, payload)
            .then(() => {
                setShowCreationDialog(false)
                setSnackOpen(true)
                setRefreshTable(true)
            })

    }

    const usersTableFields = [
        {key: "name", name: "Name"},
        {key: "serviceUrl", name: "Service URL"},
        {key: "disabled", name: "Disabled", boolean: true},
    ]

    const handleKeyPress = (event) => {
        if (event.keyCode === 13)
            setSearchFilter(searchFilterValue)
    }

    return (

        <Grid container spacing={3}

              alignItems="flex-end"
              justify="flex-end"
              style={{
                  margin: 0,
                  width: '100%',
              }}>
            <Grid item xs={6} style={{textAlign: "left"}}>
                <Box ml={3} mb={1}>
                    <AddButton onClick={() => setShowCreationDialog(true)}>
                        Distribution
                    </AddButton>
                    <DistributionCreationDialog
                        onSave={handleDistributionSave} open={showCreationDialog}
                        onClose={() => setShowCreationDialog(false)}/>
                </Box>
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}}>
                <FormControl variant={"outlined"}>
                    <OutlinedInput
                        placeholder={"Search"}
                        onKeyUp={handleKeyPress}
                        onChange={(event => setSearchFilterValue(event.target.value))}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton edge="end"
                                            onClick={() => setSearchFilter(searchFilterValue)}>
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <AdvancedTable
                    mustRefresh={refreshTable} onRefreshed={() => setRefreshTable(false)}
                    jwt={state.user.jwt}
                    fields={usersTableFields}
                    filterName={searchFilter}
                    fetchingPageFunction={fetchDistributionsPage}
                    onSelected={(d) => history.push("/admin/distribs/" + d.id)}
                />

            </Grid>

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => setSnackOpen(false)}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                <Alert severity="success"> Saved successfully </Alert>
            </Snackbar>
        </Grid>

    );
}
