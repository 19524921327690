import React, {useContext} from 'react';
import {Route, Switch} from "react-router-dom";
import {AppContext} from "../../App";
import UserAdminScreen from "./users/UsersAdminScreen";
import UserDetailScreen from "./users/detail/UserDetailScreen";
import DistributionsScreen from "./distributions/DistributionsScreen";
import DistributionDetailScreen from "./distributions/detail/DistributionDetailScreen";


export default function AdministrationRouter() {

    const [state] = useContext(AppContext);

    if (!state.user.isAdmin)
        return <></>

    return (
        <Switch>
            <Route exact path="/admin" component={UserAdminScreen}/>
            <Route exact path="/admin/u/:userId" component={UserDetailScreen}/>
            <Route exact path="/admin/distribs" component={DistributionsScreen}/>
            <Route exact path="/admin/distribs/:distId" component={DistributionDetailScreen}/>
            {/*<AdminLayoutRoute exact path="/administration/dist" component={SystemSettingsScreen}/>*/}
        </Switch>


    );
}
